<template>
  <div>
    <img
      :src="setClassTypeTransport(data)"
      class="typeTransport-icon"
    >
  </div>
</template>

<script>
import aerial from '../../../static/svg/fi-rr-plane.svg';
import land from '../../../static/svg/fi-rr-truck-container.svg';
import maritime from '../../../static/svg/fi-rr-ship.svg';

export default {
  name: 'TypeTransportCell',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      land,
      aerial,
      maritime,
    };
  },
  methods: {
    setClassTypeTransport(datos) {
      let typeTransport;
      if (datos.data.typeTransport === `${this.$t('launchTrip.typeTransport.land')}`) { // Terrestre
        typeTransport = land;
      } else if (datos.data.typeTransport === `${this.$t('launchTrip.typeTransport.maritime')}`) { // Marítimo
        typeTransport = maritime;
      } else if (datos.data.typeTransport === `${this.$t('launchTrip.typeTransport.aerial')}`) { // Aéreo
        typeTransport = aerial;
      }

      return typeTransport;
    },
  },
};
</script>
