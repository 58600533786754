<template>
  <DxDataGrid
    :data-source="carriers"
    v-bind="commonProps.dataGrid"
    :focused-row-enabled="true"
    key-expr="id"
    :columns="carriersColumns"
  >
    <template #alert-cell="{data}">
      <div class="content-alert">
        <img
          v-for="svg in parseAlerts(data)"
          :key="svg"
          :src="svg"
          class="alert-icon"
        >
      </div>
    </template>
  </DxDataGrid>
</template>

<script>
import { DxDataGrid } from 'devextreme-vue/data-grid';
import carriersColumns from './carriersTabColumnsData';
import { commonProps } from '../../tripsColumnData';

export default {
  name: 'CarriersTab',
  components: {
    DxDataGrid,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      commonProps,
      carriersColumns,
      carriers: this.data.data.carriers,
    };
  },
};
</script>

<style>

</style>
