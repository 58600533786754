<template>
  <DxDataGrid
    v-bind="commonProps.dataGrid"
    :data-source="locations"
    :columns="columns"
    :on-cell-prepared="onCellPrepared"
  >
    <template #coordinates-cell="{data}">
      <div>
        <span>Latitude: {{ data.data.latitude }}</span> <br>
        <span>Longitude: {{ data.data.longitude }}</span>
      </div>
    </template>
  </DxDataGrid>
</template>

<script>
import {
  DxDataGrid,
} from 'devextreme-vue/data-grid';
import { mapActions, mapGetters } from 'vuex';
import { commonProps } from '../trips/tripsColumnData';
import columns, { colMethods } from './loadingPointsTableColumnData';

export default {
  name: 'LoadingPointsTable',
  components: {
    DxDataGrid,
  },
  data() {
    return {
      commonProps,
      columns,
    };
  },

  async beforeMount() {
    await this.getLocations();
  },
  computed: {
    ...mapGetters('Trips', ['locations']),
  },
  methods: {
    onCellPrepared(row) {
      if (row.column.type === 'buttons') this.addButtonFuncs(row.column.buttons);
    },
    ...colMethods,
    ...mapActions('Trips', ['getLocations', 'removeLocation']),
  },

};
</script>

<style>

</style>
