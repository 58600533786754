<template>
  <DxDataGrid
    v-bind="commonProps.dataGrid"
    :data-source="dataSource"
    :columns="columns"
    :on-cell-prepared="onCellPrepared"
  >
    <template #coordinates-cell="{data}">
      <div>
        <span>{{ $t('common.track.latitude') }} {{ data.value.position.lat }}</span> <br>
        <span>{{ $t('common.track.longitude') }} {{ data.value.position.lng }}</span>
      </div>
    </template>
  </DxDataGrid>
</template>

<script>
import {
  DxDataGrid,
} from 'devextreme-vue/data-grid';
import { commonProps } from '../trips/tripsColumnData';
import columns, { colMethods } from './carriersTableColumnData';
import api from '../../services/Api';

export default {
  name: 'CarriersTable',
  components: {
    DxDataGrid,
  },
  data() {
    return {
      commonProps,
      columns,
      dataSource: [],
    };
  },

  async beforeMount() {
    this.dataSource = await api.getCarriers();
  },

  methods: {
    onCellPrepared(row) {
      if (row.column.type === 'buttons') this.addButtonFuncs(row.column.buttons);
    },
    ...colMethods,
  },

};
</script>

