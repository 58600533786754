var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-graph"},[_c('f7-block',{staticClass:"padding-half"},[_c('DxChart',{ref:"graph",attrs:{"id":"chart","data-source":_vm.dataSource,"on-done":_vm.onDone},on:{"pointClick":_vm.onPointClick,"legend-click":_vm.onSeriesClick},scopedSlots:_vm._u([{key:"tooltipTemplate",fn:function({data}){return [_c('div',{staticClass:"content-graph-tooltip"},[(data.point.data.eventCity)?_c('div',[_c('b',[_vm._v(_vm._s(_vm.config.title)+" "+_vm._s(_vm.$t('common.temperature.in')))]),_vm._v(" "),_c('em',[_vm._v(_vm._s(data.point.data.eventCity))])]):_c('div',[_vm._v("\n            "+_vm._s(_vm.config.title)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"margin-vertical-half margin-bottom"},[_c('b',[_vm._v(_vm._s(data.value)+" "+_vm._s(_vm.config.measurement)+" ")]),_vm._v(" "),_c('br'),_c('br'),_c('b',[_vm._v(_vm._s(_vm.formatDate(data.point.data.dateTimeAcquiredUtc)))])])])]}}])},[_c('DxSeries',{attrs:{"argument-field":_vm.config.xAxis.key,"pane":"top","color":_vm.config.lineColor,"value-field":_vm.config.yAxis.key,"name":`${_vm.config.title} ${_vm.config.measurement}`}}),_vm._v(" "),_c('DxPane',{attrs:{"name":"top"}}),_vm._v(" "),_c('DxArgumentAxis',{attrs:{"discrete-axis-division-mode":"crossLabels","argument-type":"datetime","value-margins-enabled":false}},[_c('DxLabel',{attrs:{"display-mode":"stagger","format":"d MMM - hh:mm"}}),_vm._v(" "),_c('DxConstantLine',{attrs:{"show-in-legend":true,"color":"#000000","label":{
            text: 'ETA'
          },"dash-style":"long","value":_vm.eta}}),_vm._v(" "),_c('DxConstantLine',{attrs:{"show-in-legend":true,"color":"#000000","label":{
            text: 'ETD'
          },"dash-style":"long","value":_vm.etd}})],1),_vm._v(" "),_c('DxAdaptiveLayout',{attrs:{"width":100,"keep-labels":true}}),_vm._v(" "),_c('DxValueAxis',{attrs:{"pane":"top","value-type":"numeric","visual-range":_vm.visualRange}},[(_vm.showRange)?_c('DxConstantLine',{attrs:{"show-in-legend":true,"color":_vm.blue,"label":{
            text: _vm.range.textmin
          },"dash-style":"longDash","value":_vm.range.min}}):_vm._e(),_vm._v(" "),(_vm.showRange)?_c('DxConstantLine',{attrs:{"dash-style":"longDash","color":_vm.red,"label":{
            text: _vm.range.textmax
          },"value":_vm.range.max}}):_vm._e(),_vm._v(" "),_c('DxGrid',{attrs:{"visible":true}}),_vm._v(" "),_c('DxTitle',{attrs:{"text":`${_vm.config.title}, ${_vm.config.measurement}`}})],1),_vm._v(" "),_c('DxExport',{attrs:{"enabled":true}}),_vm._v(" "),_c('DxTitle',{attrs:{"text":`${_vm.config.title}`}},[_c('DxFont',{attrs:{"color":"var(--hi-theme-primary)","size":"16px","family":"Roboto, Segoe UI, Helvetica Neue, sans-serif"}})],1),_vm._v(" "),_c('DxScrollBar',{attrs:{"visible":false}}),_vm._v(" "),_c('DxZoomAndPan',{attrs:{"argument-axis":"both"}}),_vm._v(" "),_c('DxTooltip',{attrs:{"enabled":true,"z-index":999999,"content-template":"tooltipTemplate"}}),_vm._v(" "),_c('DxLegend',{attrs:{"vertical-alignment":"bottom","horizontal-alignment":"center"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }