import eventBus from '../../js/event-bus';
import $ from '../../js/i18n.config';

const buttonCol = {
  type: 'buttons',
  buttons: [
    {
      name: 'editComplaint',
      icon: 'edit',
      hint: $.t('common.toolTips.edit'),
    },
    {
      name: 'deleteComplaint',
      icon: 'trash',
      hint: $.t('common.toolTips.delete'),
    },
  ],
};

export const colMethods = {
  openComplaintsPopup(e) {
    // eventBus.$emit('showComplaintPopup', e.row.data);
    eventBus.$emit('openComplaintsPopup', e.row.data);
  },
  async removeCompaint(e) {
    this.removeComplaint(e.row.data.id);
  },
  addButtonFuncs(buttons) {
    buttons.forEach((button) => {
      switch (button.name) {
        case 'editComplaint':
          button.onClick = this.openComplaintsPopup;
          break;
        case 'deleteComplaint':
          button.onClick = this.removeCompaint;
          break;
        default:
          break;
      }
    });
  },
};
export default [
  {
    dataField: 'shipmentReference',
    caption: $.t('complaints.shipmentReference'),
    dataType: 'string',
  },
  {
    dataField: 'name',
    caption: $.t('common.track.name'),
    dataType: 'string',
  },
  {
    dataField: 'description',
    caption: $.t('common.track.description'),
    dataType: 'string',
  },
  {
    dataField: 'amount',
    caption: $.t('complaints.amount'),
    dataType: 'number',
  },
  {
    dataField: 'isAccepted',
    caption: $.t('complaints.accepted'),
    dataType: 'boolean',
  },
  {
    dataField: 'creatorUser',
    caption: $.t('complaints.creatorUser'),
    dataType: 'string',
  },
  buttonCol,
];
