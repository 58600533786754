<template>
  <f7-card
    :title="$t('common.blocks.devices')"
    class="card-block"
  >
    <f7-block>
      <f7-row>
        <DxDataGrid
          class="margin-bottom"
          v-bind="commonProps.dataGrid"
          :data-source="devices"
          :columns="config"
          :on-toolbar-preparing="onToolbarPreparing"
          :on-editor-preparing="onEditorPreparing"
          :editing="{
            allowAdding: true,
            allowDeleting: true,
            allowUpdating: true,
            mode: 'cell',
            editColumnName: '',
            useIcons: true,
          }"
        >
          <template #statusTemplate="{data}">
            <div
              :class="`statusCell ${data.value}`"
            >
              <span
                :class="
                  data.value"
              >
                {{ format(data.value) }}</span>
            </div>
          </template>
          <template #deviceItem="{data}">
            <f7-block>
              <f7-row>
                <f7-col>
                  {{ data.id }}
                </f7-col>
                <f7-col :width="20" />
                <f7-col :class="data.status">
                  {{ data.status }}
                </f7-col>
              </f7-row>
            </f7-block>
          </template>
          <template #editTooltip>
            <p><i>{{ $t('trips.messageEditCelds') }}</i></p>
          </template>
        </DxDataGrid>
      </f7-row>
    </f7-block>
  </f7-card>
</template>

<script>
import { DxDataGrid } from 'devextreme-vue/data-grid';
import { mapActions, mapGetters } from 'vuex';
import { commonProps } from '../../../components/trips/tripsColumnData';

export default {
  name: 'Devices',
  components: {
    DxDataGrid,
  },
  data() {
    return {
      commonProps,
    };
  },
  computed: {
    config() {
      return [
        {
          dataField: 'status',
          dataType: 'string',
          caption: '',
          allowEditing: false,
          cellTemplate: 'statusTemplate',
        },
        {
          dataField: 'device',
          caption: this.$t('common.track.devices.number'),
          dataType: 'string',
          editorType: 'dxSelectBox',
          editorOptions: {
            itemTemplate: 'deviceItem',
            items: this.minimalDevices,
            displayExpr: 'id',
            valueExpr: 'id',
          },
          setCellValue: this.setCellValue,
        },
        {
          dataField: 'dateActivation',
          dataType: 'date',
          caption: this.$t('common.track.devices.dateActivation'),
          format: 'dd/MM/yyyy',
          editorOptions: {
            displayFormat: 'dd/MM/yyyy',
          },
        },
        {
          dataField: 'dateDeactivation',
          dataType: 'date',
          caption: this.$t('common.track.devices.dateDeactivation'),
          format: 'dd/MM/yyyy',
          editorOptions: {
            displayFormat: 'dd/MM/yyyy',
          },
        },
        {
          dataField: 'highTemp',
          dataType: 'number',
          caption: this.$t('common.temperature.highRange'),
          format: "#'°C'",
        },
        {
          dataField: 'lowTemp',
          dataType: 'number',
          caption: this.$t('common.temperature.lowRange'),
          format: "#'°C'",
        },
      ];
    },
    ...mapGetters('Trips', ['devices', 'minimalDevices']),

  },
  async beforeMount() {
    try {
      this.$f7.preloader.show();
      this.getMinimalDevices();
    } catch (error) {
      console.error(error);
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    onEditorPreparing(e) {
      if (e.dataField === 'dateActivation' && e.parentType === 'dataRow') {
        e.editorOptions.max = e.row.data.dateDeactivation;
      }
      if (e.dataField === 'dateDeactivation' && e.parentType === 'dataRow') {
        e.editorOptions.min = e.row.data.dateActivation;
      }
      if (e.dataField === 'lowTemp' && e.parentType === 'dataRow') {
        e.editorOptions.max = e.row.data.highTemp;
      }
      if (e.dataField === 'highTemp' && e.parentType === 'dataRow') {
        e.editorOptions.min = e.row.data.lowTemp;
      }
    },
    formatDate(value) {
      return value;
    },
    format(value) {
      if ([null, undefined, ''].includes(value)) return '';
      return value.toUpperCase();
    },
    setCellValue(newData, value) {
      newData.status = value.status;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        template: 'editTooltip',
      });
    },
    ...mapActions('Trips', ['getMinimalDevices']),
  },

};
</script>

<style lang='scss'>
::v-deep .dx-datagrid-revert-tooltip {
  display: none;
}
  .statusCell{
    width: 100%;
    text-align: center;

    &.active{
      color: rgb(35, 128, 32);
      border: solid 1px rgb(35, 128, 32);
      border-radius: 4px;
      background-color: rgb(123, 235, 123);
    }

    &.inactive{
      color: rgb(255, 255, 255);
      border: solid 1px rgb(114, 114, 114) ;
      border-radius: 4px;
      background-color: rgb(184, 184, 184);
    }

    .active{
      border: none;
      background-color: transparent;
    }
    .inactive{
      border: none;
      background-color: transparent;
    }
  }
  .active{
    text-align: center;
    color: rgb(35, 128, 32);
    border: solid 1px rgb(35, 128, 32);
    border-radius: 4px;
    background-color: rgb(123, 235, 123);
  }
  .inactive{
    text-align: center;
    color: rgb(255, 255, 255);
    border: solid 1px rgb(114, 114, 114) ;
    border-radius: 4px;
    background-color: rgb(184, 184, 184);
  }
</style>
