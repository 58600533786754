var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-card',{staticClass:"card-block",attrs:{"title":_vm.$t('common.blocks.devices')}},[_c('f7-block',[_c('f7-row',[_c('DxDataGrid',_vm._b({staticClass:"margin-bottom",attrs:{"data-source":_vm.devices,"columns":_vm.config,"on-toolbar-preparing":_vm.onToolbarPreparing,"on-editor-preparing":_vm.onEditorPreparing,"editing":{
          allowAdding: true,
          allowDeleting: true,
          allowUpdating: true,
          mode: 'cell',
          editColumnName: '',
          useIcons: true,
        }},scopedSlots:_vm._u([{key:"statusTemplate",fn:function({data}){return [_c('div',{class:`statusCell ${data.value}`},[_c('span',{class:data.value},[_vm._v("\n              "+_vm._s(_vm.format(data.value)))])])]}},{key:"deviceItem",fn:function({data}){return [_c('f7-block',[_c('f7-row',[_c('f7-col',[_vm._v("\n                "+_vm._s(data.id)+"\n              ")]),_vm._v(" "),_c('f7-col',{attrs:{"width":20}}),_vm._v(" "),_c('f7-col',{class:data.status},[_vm._v("\n                "+_vm._s(data.status)+"\n              ")])],1)],1)]}},{key:"editTooltip",fn:function(){return [_c('p',[_c('i',[_vm._v(_vm._s(_vm.$t('trips.messageEditCelds')))])])]},proxy:true}])},'DxDataGrid',_vm.commonProps.dataGrid,false))],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }