// - General
import logo from '../static/img/customers/hispatec-track-white.svg';
import markerGreen from '../static/icons/marker_green.svg';
import markerRed from '../static/icons/marker_red.svg';
import markerBlue from '../static/icons/marker_blue.svg';

import circleBlue from '../static/icons/ic_point_blue.svg';
import circleGreen from '../static/icons/ic_point_green.svg';
import circleRed from '../static/icons/ic_point_red.svg';
import circleYellow from '../static/icons/ic_point_orange.svg';

import markerBlackFlag from '../static/icons/marker_black_flag.png';

// Icono de alertas
import tempHighIcon from '../static/icons/ic_high_level.svg';
import lightIcon from '../static/icons/ic_light_rute.svg';

export const ENV = process.env.NODE_ENV || 'development';
export const { TARGET } = process.env;
export const APP_VERSION = 'v1.27.8r2';
export const APPLICATION_NAME = 'CONTROL TOWER';
export const DATABASE_NAME = 'dbControlTower';

// - Sync
export const MILISECONDS_TO_REFRESH_AUTOMATICALLY = 300 * 1000; // 5 minutes
export const MILISECONDS_TO_REFRESH_NOTIFICATIONS = 1800 * 1000; // 30 minutes

// - Color Theme
export const DEFAULT_PRIMARY_COLOR = '#5243AA';
export const DEFAULT_SECONDARY_COLOR = '#342878';
export const DEFAULT_TERTIARY_COLOR = '#6762E0';

// API
// export const baseUrlAPI = 'https://dev.traxx.hispatectrack.com/api'; // DESARROLLO
// export const baseUrlAPI = 'https://pre.traxx.hispatectrack.com/api'; // PREPRODUCCION
export const baseUrlAPI = 'https://traxx.hispatectrack.com/api'; // PRODUCCION

// Routes
export const APP_LOGO = logo;

// Map
const colorPalette = {
  COLOR_POINT: 'rgb(243,114,114)',
  COLOR_POINT_FILL: 'rgb(255,255,255)',
  COLOR_LANDSCAPE: '#FFAD00',
  COLOR_BORDERS: 'rgb(195,230,255)',
  COLOR_LINE: 'rgb(255,255,255)',
  COLOR_SELECTED_POINT: 'rgb(0,184,83)',

  COLOR_BROWN: 'rgb(139,69,19)',
  COLOR_BLACK: 'rgb(0,0,0)',
  COLOR_BLUE: 'rgb(77,109,155)',
  COLOR_LIGHT_BLUE: 'rgb(124,156,201)',
  COLOR_WHITE: 'rgb(255,255,255)',
  COLOR_WHITEY: 'rgb(240,240,240)',
  COLOR_GREEN: 'rgb(0,155,77)',
  COLOR_TOMATO: 'rgb(243,114,114)',
};

const COLORS = {
  BORDERS: colorPalette.COLOR_BORDERS,
  LANDSCAPE: colorPalette.COLOR_LANDSCAPE,
  LINE: colorPalette.COLOR_LINE,
  POINT: colorPalette.COLOR_SELECTED_POINT,
  POINT_FILL: colorPalette.COLOR_POINT_FILL,
  WATER: colorPalette.COLOR_WATER,
};

const POINT_MARKER_ICON_CONFIG = {
  path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0',
  strokeOpacity: 0.7,
  strokeWeight: 4,
  strokeColor: COLORS.POINT,
  fillColor: COLORS.POINT_FILL,
  fillOpacity: 0.7,
  scale: 1,
};

const LINE_SYMBOL_CONFIG = {
  path: 'M 0,-2 0,2',
  strokeOpacity: 1,
  strokeWeight: 2,
  strokeColor: '#F4F5F7',
  scale: 1,
};

const LINE_PATH_CONFIG = {
  clickable: false,
  geodesic: false,
  strokeOpacity: 0,
  strokeColor: COLORS.LINE,
  icons: [
    {
      icon: LINE_SYMBOL_CONFIG,
      repeat: '1px',
    },
  ],
};

const mapSettings = {
  clickableIcons: false,
  streetViewControl: false,
  panControlOptions: false,
  gestureHandling: 'cooperative',
  mapTypeControl: true,
  mapTypeId: 'satellite',
  zoomControlOptions: {
    style: 'SMALL',
  },
  zoom: 4,
  minZoom: 2,
};

export const POINT_MARKER_ICON_CONFIG_GREEN = markerGreen;
export const POINT_MARKER_ICON_CONFIG_RED = markerRed;
export const POINT_MARKER_ICON_CONFIG_BLUE = markerBlue;

export const POINT_MARKER_CIRCLE_GREEN = circleGreen;
export const POINT_MARKER_CIRCLE_RED = circleRed;
export const POINT_MARKER_CIRCLE_BLUE = circleBlue;
export const POINT_MARKER_CIRCLE_YELLOW = circleYellow;

export const POINT_MARKER_BLACK_FLAG = markerBlackFlag;

export {
  mapSettings, LINE_PATH_CONFIG, POINT_MARKER_ICON_CONFIG, LINE_SYMBOL_CONFIG,
};

// Paneles Power Bi
export const URL_PANEL_POWER_BI_INICIAL = 'https://dataanalytics.anserlog.com/inicial';
export const URL_PANEL_POWER_BI_GENERAL = 'https://dataanalytics.anserlog.com/general';
export const URL_PANEL_POWER_BI_WEEKLY_ANEC = 'https://dataanalytics.anserlog.com/ANEC-TEST';
export const URL_PANEL_POWER_BI_GENERAL_DAIF = 'https://dataanalytics.anserlog.com/DaifGeneral';
export const URL_PANEL_POWER_BI_GENERAL_LIVENTUS = 'https://dataanalytics.anserlog.com/Liventus';
export const URL_PANEL_POWER_BI_GENERAL_GENERAL_CTPRO = 'https://dataanalytics.anserlog.com/General_CTPRO';
// COLOR
export const COLOR_GREEN = '#36D100';
export const COLOR_RED = '#FC4E51';
export const COLOR_BLUE = '#00a1d1';
export const COLOR_YELLOW = '#FFB640';
export const COLOR_PURPLE = '#2c2484';
export const MAP_LINE_COLOR = '#6762E0'; // gris

// EXPORT ICON ALERT
export const ICON_ALERT_TEMPERATURE_HIGH = tempHighIcon;
export const ICON_ALERT_LIGHT = lightIcon;

// ALERT CODE
export const ALERT_CODE_TEMPERATURE = '201';
export const ALERT_CODE_LIGHT = '906';
