<template>
  <DxPopup
    id="attach-file-popup"
    v-bind="commonProps.popup"
    :title="$t('navbar.popup.claim.title')"
    :visible.sync="show"
    :on-hidden="onHiddenClaim"
    height="auto"
    :width="620"
  >
    <f7-block class="no-margin">
      <div class="item-title item-label">
        <p class="margin-bottom-half text-align-center">
          {{ $t('navbar.popup.claim.description.line1') }}
        </p>
      </div>
      <div class="padding-vertical-half text-align-center">
        <h4 class="color-his-primary">
          {{ $t('navbar.popup.claim.description.typeClaim') }}
        </h4>
        <DxSelectBox
          :items="listClaimType"
          :value="listClaimType[0].Identifier"
          display-expr="Name"
          value-expr="Identifier"
          @value-changed="onValueChangedClaimType"
        />
      </div>
      <div class="padding-vertical-half text-align-center">
        <h4 class="color-his-primary">
          {{ $t('navbar.popup.claim.description.line3') }}
        </h4>
      </div>
    </f7-block>
    <f7-block>
      <div class="no-margin-top margin-left margin-right display-flex content-left">
        <DxButton
          fill
          type="success"
          styling-mode="contained"
          name="submit"
          @click="submitClaimPopup"
        >
          {{ $t('common.options.submitClaim') }}
        </DxButton>
        <div class="no-margin-top margin-left display-flex justify-content-right">
          <DxButton
            fill
            type="danger"
            styling-mode="contained"
            name="cancel"
            @click="cancelClaimPopup"
          >
            {{ $t('common.options.cancelClaim') }}
          </DxButton>
        </div>
      </div>
    </f7-block>
  </DxPopup>
</template>

<script>
import DxSelectBox from 'devextreme-vue/select-box';
import { mapActions, mapState } from 'vuex';
import { DxPopup } from 'devextreme-vue/popup';
import DxButton from 'devextreme-vue/button';
import eventBus from '../../../js/event-bus';
import { commonProps } from '../../trips/tripsColumnData';

export default {
  name: 'ClaimPopup',
  components: {
    DxSelectBox,
    DxPopup,
    DxButton,
  },
  props: {
  },
  data() {
    return {
      commonProps,
      show: false,
      deviceId: '',
      listClaimType: [
        { Name: '', Identifier: '' },
        { Name: 'Calidad', Identifier: 'Calidad' },
        { Name: 'Comercial', Identifier: 'Comercial' },
        { Name: 'Logistica', Identifier: 'Logistica' }],
    };
  },
  computed: {
    /* listClaimType() {
      const unicos = [{ name: 'Calidad', Identifier: 'Calidad' },
        { name: 'Comercial', Identifier: 'Comercial' },
        { name: 'Logistica', Identifier: 'Logistica' }];
      // const unicos = ['Calidad', 'Comercial', 'Logistica'];
      return unicos;
    }, */
  },
  ...mapState('Shipments', ['claimTypeName']),
  mounted() {
    eventBus.$on('openClaimPopup', (deviceId) => {
      this.deviceId = deviceId;
      this.show = true;
    });
  },
  methods: {
    async submitClaimPopup(e) {
      this.$f7.preloader.show();
      try {
        await this.claimDevice(this.deviceId);
        this.$f7.dialog.alert(this.$t('navbar.popup.claim.notification'));
        this.$f7.views.main.router.navigate('/', { reloadCurrent: true });
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
        this.$f7.views.main.router.navigate('/', { reloadCurrent: true });
      }
    },
    async cancelClaimPopup() {
      this.$f7.preloader.show();
      try {
        await this.cancelClaimDevice(this.deviceId);
        this.$f7.dialog.alert(this.$t('navbar.popup.claim.cancelClaimNotificacion'));
        this.$f7.views.main.router.navigate('/', { reloadCurrent: true });
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
        this.$f7.views.main.router.navigate('/', { reloadCurrent: true });
      }
    },
    onHiddenClaim() {
      this.show = false;
      this.deviceId = '';
    },
    consultClaimType(identifier) {
      return this.listClaimType.find((o) => o.Identifier === identifier);
    },
    onValueChangedClaimType(e) {
      this.setClaimTypeName(e.value);
      const claimType = this.consultClaimType(e.value);
      this.setClaimType(claimType);
    },
    ...mapActions('Shipments', ['claimDevice', 'cancelClaimDevice', 'setClaimType', 'setClaimTypeName']),
  },
};
</script>
