<template>
  <div>
    <div class="content-info-map">
      <div
        v-for="field in fields[0]"
        :key="field.name"
      >
        <span><b>{{ field.name }}:</b></span> {{ field.value }}
      </div>
      <div
        v-for="field in fields[1]"
        v-if="moreInfoButton"
        :key="field.name"
      >
        <span><b>{{ field.name }}:</b></span> {{ field.value }}
      </div>
    </div>
    <div
      v-if="moreInfoButton"
      class="more-info-map"
    >
      <DxButton
        class="dx-btn-success margin-top-half"
        raised
        type="default"
        styling-mode="contained"
        :text="$t('home.map.infoWindow.moreInfo')"
        @click="goToShip"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import DxButton from 'devextreme-vue/button';
import moment from 'moment';

export default {
  name: 'InfoWindowTemplate',
  components: {
    DxButton,
  },
  props: {
    moreInfoButton: {
      type: Boolean,
      default: false,
    },
    infoWindowTemplateReport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },

  computed: {
    fields() {
      const fields = [];
      const names = this.getGenericAttributesNames;

      let list = [];

      if (this.infoWindowTemplateReport) {
        list = [
          {
            name: this.$t('common.blocks.device'),
            value: this.findReport('deviceID'),
          },
          {
            name: this.$t('common.date.date'),
            value: this.formatDate(this.findReport('dateTimeAcquiredUtc')),
          },
          {
            name: this.$t('common.time.hourName'),
            value: this.formatTime(this.findReport('dateTimeAcquiredUtc')),
          },
          {
            name: this.$t('common.track.temperature'),
            value: this.findReport('deviceTemp'),
          },
          {
            name: this.$t('common.track.humidity'),
            value: this.findReport('humidity'),
          }];

        names.forEach((name) => {
          fields.push({ name, value: this.findReport(name) });
        });
      } else {
        const listNames = [
          'reference',
          'lastReport',
          'temperature',
          'cargo',
          'origin',
          'destination',
          'carrier',
        ];
        list = this.createList(listNames);

        names.forEach((name) => {
          fields.push({ name, value: this.findDevice(name) });
        });
      }

      return [list, fields];
    },
    ...mapGetters('authentication', ['getGenericAttributesNames']),
    ...mapState('Shipments', ['currentShip', 'reports', 'report']),
  },
  methods: {
    createList(names) {
      const list = [];
      names.forEach((name) => {
        list.push({
          name: this.$t(`common.track.${name}`),
          value: this.findDevice(name),
        });
      });

      return list;
    },
    formatDate(d) {
      return moment(d, 'YYYY/MM/DDTHH:mm:ss').format('DD/MM/YYYY');
    },
    formatTime(d) {
      return moment(d, 'YYYY/MM/DDTHH:mm:ss').format('HH:mm:ss');
    },
    findDevice(name) {
      return this.currentShip[name] === '' ? ' ' : this.currentShip[name];
    },
    findReport(name) {
      return (this.report[name] === null) ? ' -- ' : this.report[name];
    },
    goToShip() {
      window.open(`/#!/shipInformation/deviceInfo/${this.currentShip.device}`, '_blank');
    },
  },

};
</script>
