var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DxAccordion',{attrs:{"multiple":true,"collapsible":true,"data-source":_vm.data.data.orders},scopedSlots:_vm._u([{key:"title",fn:function({data: order}){return [_c('div',[_vm._v(" "+_vm._s(_vm.$t('common.track.reference'))+": "+_vm._s(order.reference))])]}},{key:"item",fn:function({data: order}){return [_c('DxTabPanel',[_c('DxItem',{attrs:{"title":_vm.$t('common.blocks.loadingPoints')},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxDataGrid',_vm._b({attrs:{"data-source":order.loadPlaces,"columns":_vm.loadColumns,"on-cell-prepared":_vm.onCellPrepared},scopedSlots:_vm._u([{key:"master-template",fn:function({data}){return [(![null, undefined, ''].includes(data.row.data.latitude))?_c('TravelMap',{staticClass:"route-map",attrs:{"locations":[{id: data.row.data.id,
                                position: {
                                  lat: data.row.data.latitude,
                                  lng: data.row.data.longitude,
                                }}]}}):_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('trips.ordersTab.noCoordinates')))])])]}},{key:"showMapButton",fn:function({data}){return [_c('a',{on:{"click":function($event){return _vm.showMap(data)}}},[_vm._v(_vm._s(_vm.$t('trips.ordersTab.showMap')))])]}}],null,true)},'DxDataGrid',_vm.commonProps.dataGrid,false),[_c('DxMasterDetail',{attrs:{"enabled":true,"template":"master-template"}})],1)]},proxy:true}],null,true)}),_vm._v(" "),_c('DxItem',{attrs:{"title":_vm.$t('common.blocks.dischargePoints')},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxDataGrid',_vm._b({attrs:{"data-source":order.deliveryPlaces,"columns":_vm.unloadColumns,"on-cell-prepared":_vm.onCellPrepared},scopedSlots:_vm._u([{key:"master-template",fn:function({data}){return [(![null, undefined, ''].includes(data.row.data.latitude))?_c('TravelMap',{staticClass:"route-map",attrs:{"locations":[{id: data.row.data.id,
                                position: {
                                  lat: data.row.data.latitude,
                                  lng: data.row.data.longitude,
                                }}]}}):_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('trips.ordersTab.noCoordinates')))])])]}},{key:"showMapButton",fn:function({data}){return [_c('a',{on:{"click":function($event){return _vm.showMap(data)}}},[_vm._v(_vm._s(_vm.$t('trips.ordersTab.showMap')))])]}}],null,true)},'DxDataGrid',_vm.commonProps.dataGrid,false),[_c('DxMasterDetail',{attrs:{"enabled":true,"template":"master-template"}})],1)]},proxy:true}],null,true)}),_vm._v(" "),_c('DxItem',{attrs:{"title":_vm.$t('common.blocks.cargos')},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxDataGrid',_vm._b({attrs:{"data-source":order.merchandises,"columns":_vm.cargosColumns}},'DxDataGrid',_vm.commonProps.dataGrid,false))]},proxy:true}],null,true)})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }