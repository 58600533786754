<template>
  <DxFileUploader
    ref="fileUploader"
    name="file-uploader"
    :select-button-text="$t(tradRoot + '.attachFile')"
    :label-text="$t(tradRoot + '.attachFileDrop')"
    :invalid-file-extension-message="$t(tradRoot + '.message.invalidExtension')"
    :invalid-max-file-size-message="$t(tradRoot + '.message.invalidMaxSize')"
    :ready-to-upload-message="$t(tradRoot + '.message.readyToUpload')"
    :allowed-file-extensions="extensions"
    upload-mode="useButtons"
    :show-file-list="true"
    :max-file-size="52428800"
    :on-value-changed="handleFileSelect"
  />
</template>

<script>
import DxFileUploader from 'devextreme-vue/file-uploader';
import { mapActions } from 'vuex';
import eventBus from '../../../../js/event-bus';

export default {
  name: 'FileUploader',
  components: {
    DxFileUploader,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tradRoot: 'deviceInfo.documents.fileAttachPopup',
      extensions: ['.pdf', '.doc', '.docx', '.png', '.jpg', '.jpeg', '.mp4', '.mov', '.txt', '.xlsx', '.xlsm', '.xlsb', '.xltx'],
    };
  },
  computed: {
    fileUploader() {
      return this.$refs.fileUploader.instance;
    },
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.fileUploader._$element[0].childNodes[0].style.paddingLeft = '0';
    this.fileUploader._$element[0].childNodes[0].style.paddingRight = '0';
    this.fileUploader._$filesContainer[0].style.paddingTop = '0';
    eventBus.$on('clearFileUploader', () => this.fileUploader.reset());
  },
  methods: {
    handleFileSelect(data) {
      if (!data.value[0]) return;
      // eslint-disable-next-line no-underscore-dangle
      const isValid = this.fileUploader._files[0].isValid();
      // eslint-disable-next-line no-underscore-dangle
      const uploadButton = this.fileUploader._uploadButton._$element[0];
      uploadButton.remove();

      if (isValid) {
      // eslint-disable-next-line no-underscore-dangle
        const miniUploadButton = this.fileUploader._$filesContainer[0].childNodes[0].children[1];
        miniUploadButton.remove();
      }

      const file = data.value[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const { name } = file;
        const fileData = e.target.result;
        const attachment = fileData.split(',')[1];
        const extension = fileData.split(':')[1].split(';')[0];
        const { id } = this;
        this.setFile({
          attachment, extension, name, id,
        });
      };
      reader.onerror = (error) => {
        this.$f7.dialog.alert(`${this.$t('UploadFile_error')} ${error}`);
      };
      reader.readAsDataURL(file);
    },
    ...mapActions('Shipments', ['setFile']),
  },
};
</script>

<style>

</style>
