var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DxPopup',{staticClass:"content-complaints-popup",attrs:{"visible":_vm.show,"on-hidden":_vm.onHidden,"title":_vm.$t('complaints.popup.titlePopup'),"close-on-outside-click":false,"drag-enabled":true,"position":"center"},on:{"update:visible":function($event){_vm.show=$event}}},[_c('DxScrollView',[_c('DxForm',{ref:"form"},[_c('DxGroupItem',{attrs:{"col-count":1}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('complaints.popup.name'),"caption":_vm.$t('complaints.popup.name'),"editor-type":"dxTextBox","editor-options":{
            onValueChanged: _vm.onChangedName,
            value: _vm.name,
            placeholder: _vm.$t('complaints.popup.name'),
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('complaints.popup.nameRequired')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('complaints.popup.description'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.description,
            onValueChanged: _vm.onChangedDescription,
            placeholder: _vm.$t('complaints.popup.description'),
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('complaints.popup.descriptionRequired')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('complaints.popup.amount'),"editor-type":"dxNumberBox","editor-options":{
            value: _vm.amount,
            onValueChanged: _vm.onChangedAmount,
            placeholder: _vm.$t('complaints.popup.amount'),
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('complaints.popup.amountRequired')}})],1)],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-count":3}})],1),_vm._v(" "),_c('div',{staticClass:"content-buttons margin-top"},[_c('DxButton',{staticClass:"dx-btn-cancel margin-right padding-half",attrs:{"type":"normal","styling-mode":"contained"},on:{"click":_vm.cancelForm}},[_vm._v("\n        "+_vm._s(_vm.$t("common.options.cancel"))+"\n      ")]),_vm._v(" "),_c('DxButton',{staticClass:"dx-btn-success padding-half",attrs:{"type":"success","styling-mode":"contained"},on:{"click":_vm.validateForm}},[_vm._v("\n        "+_vm._s(_vm.$t("common.options.save"))+"\n      ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }