<template>
  <f7-page class="main-complaints">
    <ComplaintsPopup />
    <Navbar />
    <div class="wrapper">
      <div class="margin-vertical">
        <f7-row class="justify-content-flex-end">
          <DxButton
            class="margin-left-half"
            :disabled="false"
            icon="plus"
            :on-click="showPopup"
          />
        </f7-row>
      </div>
      <f7-block>
        <ComplaintsTable />
      </f7-block>
    </div>
  </f7-page>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import Navbar from '../../components/NavBar.vue';
import ComplaintsTable from '../../components/complaints/index.vue';
import ComplaintsPopup from '../../components/complaints/complaintsPopup/index.vue';
import eventBus from '../../js/event-bus';

export default {
  name: 'LoadingPointsPage',
  components: {
    Navbar,
    ComplaintsTable,
    ComplaintsPopup,
    DxButton,
  },
  methods: {
    showPopup() {
      eventBus.$emit('openComplaintsPopup');
    },
    // showConfigPopup() {
    //   eventBus.$emit('showLoadingPointsConfigPopup');
    // },
  },
};
</script>

<style lang="scss">
.main-complaints{
  .dx-icon{
    display: flex!important;
    align-items: center;
    justify-content: center;
    width: 40px!important;
    height: 40px!important;
  }
}
</style>
