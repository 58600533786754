<template>
  <f7-page
    class="main-notifications"
  >
    <Navbar
      :elements="elements"
    />
    <div class="wrapper">
      <f7-card class="card-block">
        <div class="card-header no-margin-bottom">
          {{ $t("notifications.title") }}
        </div>
        <div class="card-body">
          <f7-block class="no-margin no-padding">
            <f7-row>
              <DxDataGrid
                id="notifications-data-grid"
                :data-source="getNotifications"
                :remote-operations="true"
                :column-min-width="70"
                :column-auto-width="true"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :show-borders="false"
                :show-column-lines="true"
                :show-row-lines="true"
                :row-alternation-enabled="true"
              >
                <DxHeaderFilter :visible="true" />
                <DxSelection
                  mode="single"
                />
                <DxStateStoring
                  :enabled="true"
                  type="localStorage"
                  storage-key="DocumentsData"
                />
                <DxPaging :page-size="50" />
                <DxPager
                  :show-page-size-selector="true"
                  :allowed-page-sizes="[20, 50, 100]"
                  :show-info="true"
                />
                <DxColumn
                  data-field="User"
                  :caption="$t('notifications.table.user')"
                  alignment="left"
                  :allow-header-filtering="false"
                />
                <DxColumn
                  data-field="Company"
                  :caption="$t('notifications.table.company')"
                  alignment="left"
                  :allow-header-filtering="false"
                />
                <DxColumn
                  data-field="Resources[0].Name"
                  :caption="$t('notifications.table.type.name')"
                  alignment="left"
                  :allow-header-filtering="false"
                />
                <DxColumn
                  data-field="InsertedDate"
                  :caption="$t('notifications.table.insertDate')"
                  :allow-sorting="true"
                  data-type="datetime"
                  alignment="left"
                  :allow-header-filtering="false"
                />
                <DxColumn
                  data-field="Resources"
                  :caption="$t('notifications.table.referenceObject')"
                  alignment="left"
                  :allow-header-filtering="false"
                  cell-template="referenceTemplate"
                />
                <DxColumn
                  data-field="Read"
                  :caption="$t('notifications.table.read')"
                  alignment="center"
                  :width="100"
                  :allow-header-filtering="false"
                  cell-template="readTemplate"
                />
                <template #referenceTemplate="{data}">
                  {{ data.value[0].Id === 1 ? data.value[1].ReferenceObject : data.value[0].ReferenceObject }}
                </template>
                <template #readTemplate="{data}">
                  <div> <i :class="getClassRead(data)" /> </div>
                </template>
              </DxDatagrid>
            </f7-row>
          </f7-block>
        </div>
      </f7-card>
    </div>
  </f7-page>
</template>

<script>
import {
  mapGetters, mapActions,
} from 'vuex';
import 'devextreme-vue/button';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxHeaderFilter,
} from 'devextreme-vue/data-grid';
import Navbar from '../../components/NavBar.vue';

export default {
  name: 'NotificationsPage',
  components: {
    Navbar,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxHeaderFilter,
  },
  props: {
    type: { type: String, default: '' },
    fullName: { type: String, default: '' },
    date: { type: Number, default: Number },
  },
  data() {
    return {
      Navbar,
      elements: [
        {
          iconEnable: false,
          textEnable: false,
          bagdeEnable: false,
          info: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('Notifications', ['getNotifications']),
  },
  async beforeMount() {
    await this.fetchNotifications(true);
  },
  methods: {
    ...mapActions('Notifications', ['fetchNotifications']),
    getReferenceObject(datos) {
      if (datos[0] === 1) return datos[1].ReferenceObject;
      return datos[1].ReferenceObject;
    },
    getClassRead(datos) {
      return (datos.value === false) ? 'badge blob rfid-off' : 'badge blob rfid-on';
    },
  },
};
</script>
<style scoped>
</style>
