<template>
  <span
    ref="cell"
    :class="`arrival-${getArrivalClass(data)}`"
  >{{ formatDate(date) }}</span>
</template>

<script>
import moment from 'moment';
import eventBus from '../../../js/event-bus';

export default {
  name: 'ArrivalCell',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    date() {
      return this.data.data.dateArrived;
    },
    cell() {
      return this.$refs.cell;
    },
  },
  mounted() {
    eventBus.$on(`dateArrived-${this.data.rowIndex}`, (row) => {
      this.handleDeliveryDate(row);
    });
  },
  methods: {
    updateDate(e) {
      if (moment(e, 'DD/MM/YYYY', true).isValid()) return moment(e, 'DD/MM/YYYY').toDate();
      if (moment(e, 'DD-MM-YYYY', true).isValid()) return moment(e, 'DD-MM-YYYY').toDate();
      return null;
    },
    formatDate(date) {
      if (date) return moment(date).format('DD/MM/YYYY');
      return '—';
    },
    getArrivalClass(data) {
      const deliveredDate = moment(this.updateDate(data.data.dateArrived));
      let diff;
      const currentDate = moment(new Date());

      if (data.data.state === 37) {
        if (currentDate.isValid() && deliveredDate.isValid() && typeof this.cell !== 'undefined') {
          diff = currentDate.diff(deliveredDate, 'days');
          if (diff > 0) {
            return 'red';
          } if (diff === 0) {
            return 'black';
          }
        } else if (!deliveredDate.isValid() && typeof this.cell !== 'undefined') {
          return 'black';
        }
      } else if (data.data.state === 39 && data.data.dateDeactivate !== null) {
        const dateDeactivation = moment(this.updateDate(data.data.dateDeactivate));

        if (dateDeactivation.isValid() && deliveredDate.isValid()) {
          diff = dateDeactivation.diff(deliveredDate, 'days');
          if (diff > 0) {
            return 'red';
          } if (diff === 0) {
            return 'black';
          }
        } else if (!deliveredDate.isValid() && typeof this.cell !== 'undefined') {
          return 'black';
        }
      }
      return 'undefined';
    },
    handleDeliveryDate(data) {
      const estimatedDelivery = moment(data.value);
      const deliveredDate = moment(this.updateDate(data.data.dateArrived));
      let diff;
      const currentDate = moment(new Date());
      const self = this;
      if (data.data.state === 37) {
        if (currentDate.isValid() && deliveredDate.isValid() && typeof this.cell !== 'undefined') {
          diff = currentDate.diff(deliveredDate, 'days');
          if (diff > 0) {
            self.cell.title = this.$t('shipping.deliverDate.late', { count: Math.abs(diff), date: deliveredDate.format('DD/MM/YYYY') });
          }
        }
      } else if (data.data.state === 39 && data.data.dateDeactivate !== null) {
        const dateDeactivation = moment(this.updateDate(data.data.dateDeactivate));

        if (dateDeactivation.isValid() && deliveredDate.isValid()) {
          diff = dateDeactivation.diff(deliveredDate, 'days');

          if (diff > 0) {
            self.cell.title = this.$t('shipping.deliverDate.late', { count: Math.abs(diff), date: dateDeactivation.format('DD/MM/YYYY') });
          }
        }
      }
    },
  },
};
</script>
