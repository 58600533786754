import moment from 'moment';
import Api from '../../services/Api';
import i18n from '../../js/i18n.config';
import eventBus from '../../js/event-bus';
import Helpers from '../../js/helpers';
// import Devices from '../../js/models/Devices';

export default {
  namespaced: true,
  state: {
    query: {},
    queryTitle: '',
    queryDescription: '',
    booking: '',
    container: '',
    consultation: '',
    sensor: '',
    listQuery: [{
      text: i18n.t('customerService.queries.ICantSeeAContainer'),
      value: i18n.t('customerService.queries.ICantSeeAContainer'),
      description: i18n.t('customerService.queries.ICantSeeAContainerDescription'),
    }, {
      text: i18n.t('customerService.queries.CorrectInformation'),
      value: i18n.t('customerService.queries.CorrectInformation'),
      description: i18n.t('customerService.queries.CorrectInformationDescription'),
    }, {
      text: i18n.t('customerService.queries.OtherQueries'),
      value: i18n.t('customerService.queries.OtherQueries'),
      description: i18n.t('customerService.queries.OtherQueriesDescription'),
    }, {
      text: i18n.t('customerService.queries.QuerySensor'),
      value: i18n.t('customerService.queries.QuerySensor'),
      description: i18n.t('customerService.queries.QuerySensorDescription'),
    }],
  },
  computed: {
  },
  methods: {
  },
  getters: {
    getListQuery(state) {
      return state?.listQuery;
    },
  },
  mutations: {
    INIT_STORE(state, value) { // TODO: ¿No habría que guardar esto en el currentShipment?
      state.queryTitle = '';
      state.queryDescription = '';
      state.booking = '';
      state.container = '';
      state.consultation = '';
      state.sensor = '';
    },
    SET_QUERY(state, value) {
      if (value !== null && value !== undefined) {
        state.query = value;
        state.queryTitle = value === null ? '' : value.text;
        state.queryDescription = value === null ? '' : value.description;
      }
    },
    SET_QUERYTITLE(state, value) {
      state.queryTitle = value;
    },
    SET_QUERYDESCRIPTION(state, value) {
      state.queryDescription = value;
    },
    SET_BOOKING(state, value) {
      state.booking = value;
    },
    SET_CONTAINER(state, value) {
      state.container = value;
    },
    SET_CONSULTATION(state, value) {
      state.consultation = value;
    },
    SET_SENSOR(state, value) {
      state.sensor = value;
    },
  },
  actions: {
    clearFilters({ commit }) {
      commit('CLEAR_FILTERS');
    },
    setQuery(context, value) {
      context.commit('SET_QUERY', value);
    },
    setQueryTitle(context, value) {
      context.commit('SET_QUERYTITLE', value);
    },
    setQueryDescription(context, value) {
      context.commit('SET_QUERYDESCRIPTION', value);
    },
    setBooking(context, value) {
      context.commit('SET_BOOKING', value);
    },
    setContainer(context, value) {
      context.commit('SET_CONTAINER', value);
    },
    setConsultation(context, value) {
      context.commit('SET_CONSULTATION', value);
    },
    setSensor(context, value) {
      context.commit('SET_SENSOR', value);
    },
    initStore({ commit }) {
      commit('SET_QUERYTITLE', '');
      commit('SET_QUERYDESCRIPTION', '');
      commit('SET_BOOKING', '');
      commit('SET_CONTAINER', '');
      commit('SET_CONSULTATION', '');
      commit('SET_SENSOR', '');
    },
    async sendConsult({ state }) {
      try {
        await Api.customerService.sendConsult(
          {
            queryTitle: state.queryTitle,
            queryDescription: state.queryDescription,
            booking: state.booking,
            container: state.container,
            sensor: state.sensor,
            consultation: state.consultation,
          },
        );
        return Promise.resolve();
      } catch (e) {
        const message = Helpers.getFilteredErrorMessage(e);
        if (message.includes('403')) {
          return Promise.reject(Helpers.getError(e));
        }
        return Promise.reject(Helpers.getError(e));
      }
    },
  },
};
