<template>
  <f7-page class="main-loading-points">
    <ConfigPopup />
    <LoadingPointsPopup />
    <Navbar />
    <div class="wrapper">
      <div class="margin-vertical">
        <f7-row class="justify-content-flex-end">
          <DxButton
            :disabled="false"
            icon="preferences"
            @click="showConfigPopup"
          />
          <DxButton
            class="margin-left-half"
            :disabled="false"
            icon="plus"
            @click="showPopup"
          />
        </f7-row>
      </div>
      <div>
        <LoadingPointsTable />
      </div>
    </div>
  </f7-page>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import Navbar from '../../components/NavBar.vue';
import LoadingPointsTable from '../../components/loadingPointsTable/index.vue';
import LoadingPointsPopup from '../../components/loadingPointsTable/loadingPointsPoupup/index.vue';
import ConfigPopup from '../../components/loadingPointsTable/configPopup/index.vue';
import eventBus from '../../js/event-bus';

export default {
  name: 'LoadingPointsPage',
  components: {
    Navbar,
    LoadingPointsTable,
    LoadingPointsPopup,
    ConfigPopup,
    DxButton,
  },
  methods: {
    showPopup() {
      eventBus.$emit('showLoadingPointsPopup');
    },
    showConfigPopup() {
      eventBus.$emit('showLoadingPointsConfigPopup');
    },
  },
};
</script>

<style lang="scss">
.main-loading-points{
  .dx-icon{
    display: flex!important;
    align-items: center;
    justify-content: center;
    width: 40px!important;
    height: 40px!important;
  }
}

</style>
