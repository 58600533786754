var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DxPopup',_vm._b({attrs:{"title":_vm.title,"visible":_vm.show,"on-hidden":_vm.onHidden,"height":"auto","width":620},on:{"update:visible":function($event){_vm.show=$event}}},'DxPopup',_vm.commonProps.popup,false),[_c('DxForm',{ref:"form",attrs:{"show-colon-after-label":false}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.blocks.carrier'),"editor-type":"dxTextBox","is-required":true,"label":{
        location: 'top'
      },"editor-options":{
        value: _vm.carrierName,
        onValueChanged: _vm.onChangeCarrierName,
        placeholder: `${_vm.$t('common.blocks.carrier')}...`,
      }}}),_vm._v(" "),_c('DxGroupItem',{attrs:{"caption":"Location","col-count":2}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.track.latitude'),"editor-type":"dxTextBox","label":{
          location: 'top'
        },"editor-options":{
          disabled: true,
          value: _vm.lat,
          onValueChanged: _vm.onChangeLat,
          placeholder: `${_vm.$t('common.track.latitude')}...`,
        }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.track.longitude'),"editor-type":"dxTextBox","label":{
          location: 'top'
        },"editor-options":{
          disabled: true,
          value: _vm.lng,
          onValueChanged: _vm.onChangeLng,
          placeholder: `${_vm.$t('common.track.longitude')}...`,
        }}})],1)],1),_vm._v(" "),_c('TravelMap',{staticClass:"travel-map",style:({height: '350px'}),attrs:{"get-position":true}}),_vm._v(" "),_c('div',[_c('f7-icon',{attrs:{"icon":"info_circle_fill","size":15}}),_vm._v(" "),_c('p',[_c('i',[_vm._v("\n        "+_vm._s(_vm.$t('administration.carrier.popup.tooltip'))+"\n      ")])])],1),_vm._v(" "),_c('div',{staticClass:"content-buttons margin-top"},[_c('DxButton',{staticClass:"dx-btn-cancel margin-right padding-half",attrs:{"type":"normal","styling-mode":"contained","on-click":_vm.hidePopup}},[_vm._v("\n      "+_vm._s(_vm.$t("common.options.cancel"))+"\n    ")]),_vm._v(" "),_c('DxButton',{staticClass:"dx-btn-success padding-half",attrs:{"type":"success","styling-mode":"contained","on-click":_vm.addCarrier}},[_vm._v("\n      "+_vm._s(_vm.$t("common.options.save"))+"\n    ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }