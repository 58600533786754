import { render, staticRenderFns } from "./general.vue?vue&type=template&id=553cbd84&scoped=true&"
import script from "./general.vue?vue&type=script&lang=js&"
export * from "./general.vue?vue&type=script&lang=js&"
import style0 from "./general.vue?vue&type=style&index=0&id=553cbd84&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "553cbd84",
  null
  
)

export default component.exports