<template>
  <div>
    <i :class="setClassStatus(data)" />
  </div>
</template>

<script>
export default {
  name: 'StatusCell',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    setClassStatus(datos) {
      let colorBadge = '';
      if (datos.data.status === 39) { // Desactivado
        colorBadge = 'badge blob';
      } else if (datos.data.status === 37 && datos.data.idDeviceReport <= 0) { // Pendiente
        colorBadge = 'badge blob rfid-off';
      } else if (datos.data.status === 37 && datos.data.idDeviceReport > 0) { // Activado
        colorBadge = 'badge blob rfid-on';
      }

      return colorBadge;
    },
  },
};
</script>
