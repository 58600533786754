import moment from 'moment';
import $ from '../../js/i18n.config';
// BOTONES
const buttonCol = {
  type: 'buttons',
  buttons: [
    {
      name: 'editTrip',
      icon: 'edit',
      hint: $.t('common.toolTips.edit'),
    },
    {
      icon: 'clear',
      hint: $.t('common.toolTips.clear'),
    },
  ],
};
const format = {
  formatter(value) {
    return moment(value).format('DD/MM/YYYY');
  },
};
export const colMethods = {
  updateDate(e) {
    if (moment(e, 'DD/MM/YYYY', true).isValid()) return moment(e, 'DD/MM/YYYY').toDate();
    if (moment(e, 'DD-MM-YYYY', true).isValid()) return moment(e, 'DD-MM-YYYY').toDate();
    return null;
  },
  handleDeliveryDate(data) {
    const cell = data.cellElement;
    const estimatedDelivery = moment(data.data.estimatedDateDelivery);
    const deliveredDate = moment(this.updateDate(data.value));
    let diff;
    if (estimatedDelivery.isValid() && deliveredDate.isValid()) {
      diff = estimatedDelivery.diff(deliveredDate, 'days');
      if (diff <= -1) {
        cell.title = this.$t('shipping.deliverDate.late', { count: Math.abs(diff), date: deliveredDate.format('DD/MM/YYYY') });
        cell.style.color = '#be0027';
      }
    } else if (estimatedDelivery.isValid() && !deliveredDate.isValid()) {
      diff = estimatedDelivery.diff(moment(new Date()), 'days');
      if (diff <= -1) {
        cell.title = this.$t('shipping.deliverDate.lateBy', { count: Math.abs(diff) });
        cell.style.color = '#be0027';
      }
    } else if (!deliveredDate.isValid()) {
      cell.style.color = '#ececec';
    }
  },
  setClassStatus(data) {
    const { state } = data.data;
    if (state === 'Finished') return 'badge blob';
    if (state === 'Pending') return 'badge blob';
    return 'badge blob rfid-on';
  },
  addButtonFuncs(buttons) {
    buttons.forEach((button) => {
      switch (button.name) {
        case 'editTrip':
          button.onClick = this.goToEditTrip;
          break;
        default:
          break;
      }
    });
  },
};
export const commonProps = {
  popup: {
    closeOnOutsideClick: true,
    showCloseButton: true,
    height: 'auto',
  },
  dataGrid: {
    columnHidingEnabled: true,
    columnMinWidth: 70,
    columnAutoWidth: true,
    allowColumnReordering: true,
    allowColumnResizing: true,
    showBorders: true,
    showColumnLines: true,
    showRowLines: true,
    rowAlternationEnabled: true,
  },
};

export default [{
  dataField: 'state',
  caption: $.t('trips.columns.status'),
  dataType: 'string',
  alignment: 'center',
  width: 70,
  allowFiltering: false,
  allowHeaderFiltering: false,
  allowSorting: true,
  cellTemplate: 'statusCell',
},
{
  dataField: 'reference',
  caption: $.t('trips.columns.reference'),
  dataType: 'string',
},
{
  dataField: 'clientReference',
  caption: $.t('trips.columns.clientReference'),
  dataType: 'string',
},
{
  dataField: 'dateLoading',
  caption: $.t('trips.columns.departure'),
  dataType: 'date',
  format,
},
{
  dataField: 'dateEstimatedLoading',
  caption: $.t('trips.columns.estimatedOutput'),
  dataType: 'date',
  format,
},
{
  dataField: 'dateDelivery',
  caption: $.t('trips.columns.delivered'),
  dataType: 'date',
  format,
},
{
  dataField: 'dateEstimateDelivery',
  caption: $.t('trips.columns.estimatedDelivery'),
  dataType: 'date',
  format,
},
{
  dataField: 'dateArrived',
  caption: $.t('trips.columns.arrival'),
  dataType: 'date',
  format,
},
{
  dataField: 'dateEstimatedArrived',
  caption: $.t('trips.columns.estimatedArrival'),
  dataType: 'date',
  format,
},
{
  caption: $.t('trips.columns.cargo'),
  dataType: 'string',
  calculateCellValue(row) {
    let merchandise = [];
    row.orders.forEach((order) => {
      order.merchandises.forEach((cargo) => {
        if (!merchandise.includes(cargo)) merchandise.push(cargo);
      });
    });
    merchandise = merchandise.map((item) => item.product);
    return merchandise.join('/');
  },
},
{
  dataField: 'carriers',
  caption: $.t('trips.columns.carrier'),
  calculateCellValue(row) {
    return row.carriers.map((carrier) => carrier.name).join(' / ');
  },
  dataType: 'string',
},
{
  dataField: 'origin',
  caption: $.t('trips.columns.origin'),
  dataType: 'object',
  calculateDisplayValue: (e) => e.origin.name,
  calculateCellValue: (e) => e.origin,
},
{
  dataField: 'destination',
  caption: $.t('trips.columns.destination'),
  dataType: 'object',
  calculateDisplayValue: (e) => e.destination.name,
  calculateCellValue: (e) => e.destination,
},
buttonCol,
];
