import Vue from 'vue';
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
import { i18nextPlugin } from 'translation-check';
import en from '../json/translations/en-US.json';
import es from '../json/translations/es-ES.json';
import fr from '../json/translations/fr-FR.json';
import ar from '../json/translations/ar-AR.json';
import it from '../json/translations/it-IT.json';
import de from '../json/translations/de-DE.json';
import pt from '../json/translations/pt-PT.json';

Vue.use(VueI18Next);
const dictionaries = {
  en: { translation: en },
  es: { translation: es },
  fr: { translation: fr },
  ar: { translation: ar },
  it: { translation: it },
  de: { translation: de },
  pt: { translation: pt },
};

i18next.use(i18nextPlugin);
i18next.init({
  lng: 'es',
  fallbackLng: 'en',
  whitelist: ['en', 'es', 'fr', 'ar', 'it', 'de', 'pt'],
  resources: dictionaries,
});
export default new VueI18Next(i18next);
