var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DxPopup',_vm._b({staticClass:"content-launch-trip-popup",attrs:{"visible":_vm.show,"on-hidden":_vm.onHidden,"title":_vm.$t('launchTrip.title'),"close-on-outside-click":false,"drag-enabled":true,"position":"center"},on:{"update:visible":function($event){_vm.show=$event}}},'DxPopup',_vm.commonProps.popup,false),[_c('DxScrollView',[_c('DxForm',{ref:"form"},[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('launchTrip.numeroDC'),"caption":_vm.$t('launchTrip.numeroDC'),"editor-type":"dxTextBox","editor-options":{
            onValueChanged: _vm.onChangedDeviceId,
            value: _vm.deviceId,
            placeholder: _vm.$t('launchTrip.numeroDC'),
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('launchTrip.numeroDCRequired')}})],1),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'frutaria')?_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.track.reference'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.getPreReferenciaFrutaria,
              value: _vm.codPreReferenciaFrutaria,
              onValueChanged: _vm.onValueChangedPreReferenciaFrutaria,
            }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"editor-type":"dxTextBox","editor-options":{
              value: _vm.customerRef,
              onValueChanged: _vm.onChangedCustomerRef,
              placeholder: _vm.$t('common.track.reference'),
              maxLength: 49,
            }}})],1):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'planasa')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.track.truckNumber'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.customerRef,
            readOnly: _vm.readonlyReference,
            onValueChanged: _vm.onChangedCustomerRef,
            placeholder: _vm.$t('common.track.truckNumber'),
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice !== 'frutaria'
            && _vm.getThemeData.modalEnableDevice !== 'planasa')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.track.reference'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.customerRef,
            readOnly: _vm.readonlyReference,
            onValueChanged: _vm.onChangedCustomerRef,
            placeholder: _vm.$t('common.track.reference'),
            maxLength: 49,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'ayura')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('launchTrip.pais'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.getCountriesAyura,
            value: _vm.genericAttribute01,
            placeholder: _vm.$t('launchTrip.pais'),
            onValueChanged: _vm.onValueChangedGenericAttribute01,
          }}}):_vm._e(),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.date.dateActivation'),"editor-type":"dxDateBox","required":"","min":_vm.minDateActivation,"editor-options":{
            displayFormat: 'dd/MM/yyyy',
            value: (_vm.dateActivation === '01/01/1970' ? null : _vm.dateActivation),
            onValueChanged: _vm.onChangedDateActivation,
            placeholder: _vm.$t('common.date.dateActivation'),
            invalidDateMessage: _vm.$t('common.date.dateInvalid'),
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('launchTrip.departureDateRequired')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.date.dateDeliver'),"editor-type":"dxDateBox","editor-options":{
            displayFormat: 'dd/MM/yyyy',
            value: (_vm.dateDeliver === '01/01/1970' ? null : _vm.dateDeliver),
            onValueChanged: _vm.onChangedDateDeliver,
            placeholder: _vm.$t('common.date.dateDeliver'),
            invalidDateMessage: _vm.$t('common.date.dateInvalid'),
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('launchTrip.arrivalDateRequired')}})],1),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'sanLucarTunez')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.track.origin'),"editor-type":"dxSelectBox","editor-options":{
            value: _vm.activationPlace,
            onValueChanged: _vm.onChangedActivationPlace,
            placeholder: _vm.$t('common.track.origin'),
            items: _vm.getOriginsSanLucarTunez,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice !== 'sanLucarTunez')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.track.origin'),"editor-type":_vm.typeOfOrigin,"editor-options":{
            value: _vm.activationPlace,
            onValueChanged: _vm.onChangedActivationPlace,
            placeholder: _vm.$t('common.track.origin'),
            items: _vm.listOrigins,
            maxLength: 99,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice !== 'planasa'
            && _vm.getThemeData.modalEnableDevice !== 'sanLucarTunez'
            && _vm.getThemeData.modalEnableDevice !== 'planasaMoroco')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.track.destination'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.deliveryPlace,
            onValueChanged: _vm.onChangedDeliveryPlace,
            placeholder: _vm.$t('common.track.destination'),
            items: _vm.listDestinations,
            maxLength: 99,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'planasa')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.track.destination'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.deliveryPlace,
            onValueChanged: _vm.onChangedDeliveryPlace,
            placeholder: _vm.$t('common.track.destination'),
            items: _vm.listDestinations,
            maxLength: 99,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('launchTrip.destinationRequired')}})],1):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'planasaMoroco')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.track.destination'),"editor-type":"dxSelectBox","editor-options":{
            value: _vm.deliveryPlace,
            onValueChanged: _vm.onChangedDeliveryPlace,
            placeholder: _vm.$t('common.track.destination'),
            items: _vm.getDestinationsPlanasaMoroco,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('launchTrip.destinationRequired')}})],1):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'sanLucarTunez')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.track.destination'),"editor-type":"dxSelectBox","editor-options":{
            value: _vm.deliveryPlace,
            onValueChanged: _vm.onChangedDeliveryPlace,
            placeholder: _vm.$t('common.track.destination'),
            items: _vm.getDestinationsSanLucarTunez,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'planasaMoroco')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('launchTrip.client'),"editor-type":"dxSelectBox","editor-options":{
            value: _vm.customerOfOurCustomer,
            onValueChanged: _vm.onChangedCustomer,
            placeholder: _vm.$t('launchTrip.client'),
            items: _vm.getClientsPlanasaMoroco,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'ayura')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('launchTrip.client'),"editor-type":"dxSelectBox","editor-options":{
            value: _vm.customerOfOurCustomer,
            onValueChanged: _vm.onChangedCustomer,
            placeholder: _vm.$t('launchTrip.client'),
            items: _vm.getClientsAyura,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice !== 'planasaMoroco'
            && _vm.getThemeData.modalEnableDevice !== 'ayura')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('launchTrip.client'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.customerOfOurCustomer,
            onValueChanged: _vm.onChangedCustomer,
            placeholder: _vm.$t('launchTrip.client'),
            maxLength: 49,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'ayura')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('launchTrip.ruta'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.genericAttribute02,
            placeholder: _vm.$t('launchTrip.ruta'),
            onValueChanged: _vm.onValueChangedGenericAttribute02,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'sanLucarTunez')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.blocks.carrier'),"editor-type":"dxSelectBox","editor-options":{
            value: _vm.carrier,
            onValueChanged: _vm.onChangedCarrier,
            placeholder: _vm.$t('common.blocks.carrier'),
            items: _vm.getCarriersSanLucarTunez,
            maxLength: 49,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'planasaMoroco')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.blocks.carrier'),"editor-type":"dxSelectBox","editor-options":{
            value: _vm.carrier,
            onValueChanged: _vm.onChangedCarrier,
            placeholder: _vm.$t('common.blocks.carrier'),
            items: _vm.getCarriersPlanasaMoroco,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice !== 'sanLucarTunez'
            && _vm.getThemeData.modalEnableDevice !== 'planasaMoroco')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.blocks.carrier'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.carrier,
            onValueChanged: _vm.onChangedCarrier,
            placeholder: _vm.$t('common.blocks.carrier'),
            maxLength: 49,
          }}}):_vm._e(),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('launchTrip.transportNum'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.transportNum,
            onValueChanged: _vm.onChangedTransportNum,
            placeholder: _vm.$t('launchTrip.transportNum'),
            maxLength: 49,
          }}}),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'sanLucarTunez')?_c('DxSimpleItem'):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice !== 'frutaria'
            && _vm.getThemeData.modalEnableDevice !== 'planasa'
            && _vm.getThemeData.modalEnableDevice !== 'planasaMoroco'
            && _vm.getThemeData.modalEnableDevice !== 'sanLucarTunez')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.blocks.cargo'),"editor-type":_vm.typeOfCargo,"editor-options":{
            value: _vm.latestCargo,
            onValueChanged: _vm.onChangedCargo,
            placeholder: _vm.$t('common.blocks.cargo'),
            items: _vm.listTypeOfCargo,
            maxLength: 190,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'planasaMoroco')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.blocks.product'),"editor-type":"dxSelectBox","editor-options":{
            value: _vm.latestCargo,
            onValueChanged: _vm.onChangedCargo,
            placeholder: _vm.$t('common.blocks.product'),
            items: _vm.getProductsPlanasaMoroco,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'planasa')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.blocks.product'),"editor-type":_vm.typeOfCargo,"editor-options":{
            value: _vm.latestCargo,
            onValueChanged: _vm.onChangedCargo,
            placeholder: _vm.$t('common.blocks.product'),
            items: _vm.listTypeOfCargo,
            maxLength: 190,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'frutaria')?_c('DxGroupItem',{attrs:{"col-count":7}},_vm._l((_vm.getCargos),function(item,index){return (_vm.getThemeData.modalEnableDevice === 'frutaria')?_c('DxSimpleItem',{key:index,attrs:{"css-class":"checkbox-items","editor-type":"dxCheckBox","editor-options":{
              items: _vm.listTypeOfCargo,
              text: item.Name,
              onValueChanged: _vm.onChangedCargoFrutaria(item),
            }}}):_vm._e()}),1):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'sanLucarTunez')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('launchTrip.netWeight'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.genericAttribute03,
            placeholder: _vm.$t('launchTrip.netWeight'),
            onValueChanged: _vm.onValueChangedGenericAttribute03,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'sanLucarTunez')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('launchTrip.grossWeight'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.genericAttribute04,
            placeholder: _vm.$t('launchTrip.grossWeight'),
            onValueChanged: _vm.onValueChangedGenericAttribute04,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'casi')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('launchTrip.comercial'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.getComercialCasi,
            value: _vm.genericAttribute01,
            placeholder: _vm.$t('launchTrip.comercial'),
            onValueChanged: _vm.onValueChangedGenericAttribute01,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'royal')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('launchTrip.numeroPaletRoyal'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.numeroPaletRoyal,
            onValueChanged: _vm.onChangedNumeroPaletRoyal,
            placeholder: _vm.$t('launchTrip.numeroPaletRoyal'),
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'royal')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('launchTrip.numeroPedidoRoyal'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.numeroPedidoRoyal,
            placeholder: _vm.$t('launchTrip.numeroPedidoRoyal'),
            onValueChanged: _vm.onChangedNumeroPedidoRoyal,
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'fallcreek')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.temperature.lowerTemperature'),"editor-type":"dxNumberBox","editor-options":{
            readOnly: _vm.readonlyTemperatures,
            value: 0,
            onValueChanged: _vm.onChangedLowerTemperature,
            placeholder: _vm.$t('common.temperature.lowerTemperature'),
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice !== 'fallcreek')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.temperature.lowerTemperature'),"editor-type":"dxNumberBox","editor-options":{
            readOnly: _vm.readonlyTemperatures,
            value: _vm.lowerTemperature,
            onValueChanged: _vm.onChangedLowerTemperature,
            placeholder: _vm.$t('common.temperature.lowerTemperature'),
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'fallcreek')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.temperature.higherTemperature'),"editor-type":"dxNumberBox","editor-options":{
            readOnly: _vm.readonlyTemperatures,
            value: 15,
            onValueChanged: _vm.onChangedHigherTemperature,
            placeholder: _vm.$t('common.temperature.higherTemperature'),
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice !== 'fallcreek')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('common.temperature.higherTemperature'),"editor-type":"dxNumberBox","editor-options":{
            readOnly: _vm.readonlyTemperatures,
            value: _vm.higherTemperature,
            onValueChanged: _vm.onChangedHigherTemperature,
            placeholder: _vm.$t('common.temperature.higherTemperature'),
          }}}):_vm._e(),_vm._v(" "),(_vm.getThemeData.modalEnableDevice === 'trops')?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('launchTrip.observations'),"editor-type":"dxTextArea","editor-options":{
            value: _vm.genericAttribute01,
            placeholder: _vm.$t('launchTrip.observations'),
            onValueChanged: _vm.onValueChangedGenericAttribute01,
          }}}):_vm._e(),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-count":2}},_vm._l((_vm.getCargos),function(item,index){return (_vm.getThemeData.modalEnableDevice === 'sanLucarTunez')?_c('DxSimpleItem',{key:index,attrs:{"css-class":"checkbox-items","editor-type":"dxCheckBox","editor-options":{
              items: _vm.listTypeOfCargo,
              text: item.Name,
              onValueChanged: _vm.onChangedCargoSanLucarTunez(item),
            }}}):_vm._e()}),1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"editor-options":{
            value: _vm.typeTransport,
            onValueChanged: _vm.onChangedTypeTransport,
            items: _vm.typeOfTransport,
            displayExpr: 'text',
            valueExpr: 'value',
            layout: 'horizontal',
            invalidDateMessage: _vm.$t('launchTrip.typeOfTransport')},"data-field":_vm.$t('launchTrip.typeOfTransport'),"editor-type":"dxRadioGroup"}}),_vm._v(" "),(_vm.showVisibilityThridParty === true)?_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('launchTrip.visibilityToThirdParties'),"editor-type":"dxSelectBox","editor-options":{
            value: _vm.recipientInfoName,
            items: _vm.listRecipientInfo,
            displayExpr: 'Name',
            valueExpr: 'Identifier',
            placeholder: _vm.$t('launchTrip.visibilityToThirdParties'),
            onValueChanged: _vm.onValueChangedRecipientInfo,
          }}}):_vm._e()],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"visible":_vm.showMaritime,"data-field":_vm.$t('common.track.originPort'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.originPort,
            onValueChanged: _vm.onChangedOriginPort,
            placeholder: _vm.$t('common.track.originPort'),
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"visible":_vm.showMaritime,"data-field":_vm.$t('common.track.destinationPort'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.destinationPort,
            onValueChanged: _vm.onChangedDestinationPort,
            placeholder: _vm.$t('common.track.destinationPort'),
          }}})],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"visible":_vm.showMaritime,"data-field":_vm.$t('common.date.etd'),"editor-type":"dxDateBox","min":_vm.minDateActivation,"editor-options":{
            displayFormat: 'dd/MM/yyyy',
            value: (_vm.etd === '01/01/1970' ? _vm.currentDate : _vm.etd),
            onValueChanged: _vm.onChangedETD,
            placeholder: _vm.$t('common.date.etd'),
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"visible":_vm.showMaritime,"data-field":_vm.$t('common.date.eta'),"editor-type":"dxDateBox","min":_vm.minDateActivation,"editor-options":{
            displayFormat: 'dd/MM/yyyy',
            value: (_vm.eta === '01/01/1970' ? _vm.currentDate : _vm.eta),
            onValueChanged: _vm.onChangedETA,
            placeholder: _vm.$t('common.date.eta'),
          }}})],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"visible":_vm.showMaritime,"data-field":_vm.$t('common.track.shipName'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.shipName,
            onValueChanged: _vm.onChangedShipName,
            placeholder: _vm.$t('common.track.shipName'),
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"visible":_vm.showMaritime,"data-field":_vm.$t('common.track.containerNumber'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.numberContainer,
            onValueChanged: _vm.onChangedContainerNumber,
            placeholder: _vm.$t('common.track.containerNumber'),
          }}})],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"visible":_vm.showMaritime,"data-field":_vm.$t('common.track.consignee'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.consignee,
            onValueChanged: _vm.onChangedConsignee,
            placeholder: _vm.$t('common.track.consignee'),
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"visible":_vm.showMaritime,"data-field":_vm.$t('common.track.mbl'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.mbl,
            onValueChanged: _vm.onChangedMBL,
            placeholder: _vm.$t('common.track.mbl'),
          }}})],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-count":3}})],1),_vm._v(" "),_c('div',{staticClass:"content-buttons margin-top"},[_c('DxButton',{staticClass:"dx-btn-cancel margin-right padding-half",attrs:{"type":"normal","styling-mode":"contained"},on:{"click":_vm.cancelTrip}},[_vm._v("\n        "+_vm._s(_vm.$t("common.options.cancel"))+"\n      ")]),_vm._v(" "),_c('DxButton',{staticClass:"dx-btn-success padding-half",attrs:{"type":"success","styling-mode":"contained"},on:{"click":_vm.validateForm}},[_vm._v("\n        "+_vm._s(_vm.$t("common.options.save"))+"\n      ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }