import Api from '../../services/Api';
import Recipients from '../../js/models/Recipients';
import Cargos from '../../js/models/Cargos';
import Origins from '../../js/models/Origins';
import Destinations from '../../js/models/Destinations';
import helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    cargos: [],
    origins: [],
    destinations: [],
    recipientsInfo: [],
    isOriginBlock: false,
    isDestinationBlock: false,
    userData: null,
    comercialCasi: [
      'Arturo Gisbert Gallego',
      'Jose Carlos Escobar Martinez',
      'Francisco Fuentes Cespedes',
      'Virginie Kinh',
      'Manuel Segura Sánchez',
      'Antonio Zaragoza',
    ],
    preReferenciaFrutaria: [
      '02/',
      '04/',
      '05/',
      '06/',
    ],
    codPreferenciaFrutaria: '',
    originsSanLucarTunez: [
      'LA CINQUIEME SAISON',
      'FLOR´ALIA',
      'LES PERLES DU DESERT',
    ],
    productsSanLucarTunez: [
      'TOMATOES',
      'RASPBERRYS',
      'STRAWBERRIES',
      'BLUEBERRIES',
    ],
    carriersSanLucarTunez: [
      'VECTORYS',
      'SINDBAD',
      'GERMANETTI',
      'EK',
      'QR',
      'SV',
      'TK',
      'OTHERS',
    ],
    destinationsSanLucarTunez: [
      'ETTLINGEN',
      'DUBAI',
      'BAHRAIN',
      'POLOGNE',
      'VIENNA',
      'RUSSIE',
      'RIYADH',
      'QATAR',
      'ALDI HOFER',
      'KUWAIT',
      'HILLFRESH',
      'DOHA',
      'OMAN',
      'SAUDI ARABIA',
      'MAGNIT',
      'MUSCAT',
      'NETHERLANDS',
      'UK',
      'DAMMAM',
      'ITALIE',
      'LEHMANN',
      'SANLUCAR',
      'ALDIHOFER',
      'JEDDAH',
      'KSA',
      'KWI',
      'DMM',
      'LETTONIE',
      'ADEL SEDIK',
      'CHARBATLI',
      'FRANKFURT',
      'GENE',
    ],
    carriersPlanasaMoroco: [
      'SAOUIR',
      'GTO',
      'JCARRION',
      'BOLIPESK',
      'TRANSMORO',
      'CMA',
    ],
    productsPlanasaMoroco: [
      'RASPBERRY ROOTBALL PLANT',
      'STRAWBERRY ROOTBALL PLANT',
      'BLUEBERRY PLANT',
      'STRAWBERRY RUNNERS',
      'BLACKBERRIES',
      'AVOCADO',
      'RASPBERRY FRUIT',
    ],
    destinationsPlanasaMoroco: [
      'SPAIN',
      'FRANCE',
      'NETHERLANDS',
      'BELGIUM',
      'GERMANY',
      'MOROCCO',
    ],
    clientsPlanasaMoroco: [
      'ANJOU PLANTS FRANCE',
      'PLANASA VIVEROS',
      'IVO SCHOUBS',
      'PLANASA FRANCE',
      'BIOSEM',
      'BOURBON',
      'CAPDROT',
      'CERDYS MELLORAY',
      'CERDYS NONNAINS',
      'EARL DES PUITS',
      'GUYENNE',
      'KAACK',
      'LES FERRIERES',
      'MARTAILLAC',
      'MATIKA BV',
      'PEPINIERES DE SOLOGNE',
      'PEPINIERES DROT',
      'PROVENCE PLANTS',
      'RAZIMET',
      'SAS LARCHE',
      'TECNOSEM',
      'THOMAS PLANTS',
      'VERPAALEN',
      'FRUITS ROUGES CO',
      'NATURES PRIDE',
      'SPECIAL FRUIT',
    ],
    clientsAyura: [
      '3D FLOWERS KZ',
      '4 SEASONS GROWERS',
      'A.PERRI FARMS',
      'A-WORLD CO LTD',
      'ACCESS',
      'AGORAFLOR CB',
      'ALDERS WHOLESALE FLORIST, INC.',
      'ALETALO. CO.',
      'ALIA IMPORT EXPORT S.I.',
      'ALMANC FLOWERS SAS',
      'AN CORPORATION',
      'ANATOL TRADING INC',
      'ANDAS ENERGY DMCC',
      'ANTISANA FIORI S.L.',
      'APF MIAMI',
      'ARES 3 CO LTD',
      'AVP',
      'BELFLORA PTY LTD',
      'BELLA BLOSSOM',
      'BIT FLOWER',
      'BLOOM FLOWER 2 TRADE SAS',
      'BLOOMEX',
      'BLOOMSPAL, LLC',
      'BLUETENPRACHT FRISCHBLUMEN',
      'BONNETT WHOLESALE',
      'BOTANICA WHOLESALE',
      'BOULEVARD FLORIST DBA FLORAL',
      'C.I. ANDEAN FIELDS S.A.S',
      'C.I. DANIELA FLOWERS LTDA',
      'C.I. FILLCO FLOWERS S.A.S.',
      'C.I. FLORES DE LA VEGA S.A.S',
      'C.I. MAXIFLORES S.A.S',
      'CAMBRIAN MONTREAL',
      'CAMBRIAN VANCOUVER',
      'CAN-MEX WHOLESALE',
      'CEAN GLOBAL EXPORT LLC',
      'COLOUR RUSH BV',
      'COMPANY LIMITED',
      'COMPANY',
      'CORP',
      'COWARD & GLISSON',
      'CP&F DBA FLORAL',
      'CP&F ELYRIA – SUZANNE',
      'CREATE CO. LTD',
      'CREATIVE FLORAL SOLUTIONS',
      'CROSS FARMS',
      'DAEDONG GARDENING',
      'DAPHNE FLOWER IMPORTS',
      'DAUTEC KOREA',
      'DEL TROPICO FARMS NYC',
      'DONGKWANG FLOWER',
      'DRIM FORCE FLOWER SRL',
      'DV FLORA DELAWARE',
      'DV/NSI FLORIDA',
      'ECUALAND LLC',
      'EDEN FLOWERS LLC',
      'EL CAFETAL CO., LTD.',
      'EMPRESARIAL',
      'EXFLOFLORAL PRODUCTS LLC',
      'FAGUA FLOWERS INT LLC',
      'FANTASY FARMS',
      'FARM TO CANADA INC',
      'FASHION GARDEN S.A.',
      'FLORA - KINGDOM FLOWERS',
      'FLORA FRESH INC.',
      'FLORAL SPECIAL BV',
      'FLORALAND KFT',
      'FLORALINK',
      'FLOREARD CO.LTD',
      'FLORES AURORA S.A.S',
      'FLORES DE SAN ALEJO SAS',
      'FLORES DE SERREZUELA S.A',
      'FLORES EL CALÉ. S.L.',
      'FLORES IPANEMA S.A.S',
      'FLORES RIONEGRO S.A.',
      'FLORES SAN MIGUEL LLC',
      'FLORES SAN MIGUEL S.A.S.',
      'FLOREXIM FLOWERS B.V.',
      'FLORIA INTERNATIONAL',
      'FLORIMEX FREIGHT FORWARD',
      'FLORIMEX TORONTO CORP',
      'FLORIST DISTRIBUTING',
      'FLORISTS SUPPLY LTD',
      'FLOWARD ALDOHA TRADING',
      'FLOWER AUCTION JAPAN INC',
      'FLOWER CENTRAL',
      'FLOWER FARM',
      'FLOWER MASTER',
      'FLOWER TOGO',
      'FLOWER TRADE ZWIRS B.V. (HOEK)',
      'FLOWERS & FLOWERS TRADING',
      'FMI FARMS LLC',
      'FRESCA FARMS',
      'GARIN INTERNATIONAL SAS BIC',
      'GLOBALROSE.COM LLC',
      'GMBH',
      'GOEN FLOWER',
      'GROWER2BUYER COM INC - BW',
      'GROWER2BUYER COM INC - CARLSTEDTS LLC',
      'GROWER2BUYER COM INC - COASTAL WHOLESALE FLORIST',
      'GROWER2BUYER COM INC - D KAY',
      'GROWER2BUYER COM INC - HFS',
      'GROWER2BUYER COM INC - RIVER',
      'GUIRNALDAS/INNOVAFLORA S.A.S',
      'HARUTA FLOWER CO LTD',
      'HILVERDA GLOBAL SOURCING',
      'HOEK FLOWERS BOGOTÁ S.A.S.',
      'HOEK GROOTHANDEL B.V.',
      'HOLEX USA, INC',
      'HONEST FLOWER. CO. LTD',
      'HOSA SA EN REORGANIZACION',
      'ICHIKAWA FLOWER AUCTION',
      'IFT BV',
      'IMPEX FLOWERS',
      'IMPEXOR KFT',
      'IMROSS EXPRESS NL B.V.',
      'INC',
      'INTERNACIONAL SAS CI',
      'J. VAN VLIET BLOEMENEXPO,RT',
      'J. VAN VLIET NEW YORK',
      'J. VAN VLIET S.A.',
      'J. VAN VLIET USA INC.',
      'J AND E WHOLESALE FLORAL',
      'JANAL WHOLESALE',
      'JAPAN',
      'JARDIN FLORES EXOTICAS DEL',
      'JET FRESH FLOWERS DISTRIBUTORS',
      'JIREH TRADE CO LTD',
      'KANG NAM',
      'KAUAI ISLE FlOWERS, LLC',
      'KAWASAKI FLORA AUCTION',
      'KENNICOTT BROTHERS',
      'KOT - KINGDOM FLOWERS',
      'KUKKA',
      'LARSENS LEAVES WHOLESALE',
      'LATIN CORPORATION',
      'LIHMIL WHOLESALE',
      'LOGISTIC LLC',
      'LUNA - KINGDOM FLOWERS',
      'LYNCH GROUP AUSTRALIA PTY LTD',
      'MAGNAFLOR CORP',
      'MAGNAFLOR',
      'MALU-ANDA CORP',
      'MARCUEFLOR S.L.',
      'MARKET CO.LTD.',
      'MASAKITO CO., LTD',
      'MAYESH WHOLESALE FLORISTS',
      'MEGA WISE LP',
      'MELODY FARMS',
      'MULTIFLORA COMERCIALIZADORA',
      'MULTIFLORA CORPORATION',
      'MUNDIFLORAL & CIA S.A.S',
      'NATURAL FLOWERS',
      'NEW YORK GARDEN FLOWERS',
      'NORDLIE INC',
      'OOO LILIYA',
      'OPTIMUS FLOWERS LTD',
      'ORBE ULLOA SRL',
      'OSSIMO FLOWERS SAS CI',
      'OSTROV - KINGDOM FLOWERS',
      'POTOMAC FLORAL',
      'PREMIER FLORAL DISTRIBUTORS',
      'PREMIUM FLOWERS',
      'PS FLOWERS SPOLKA Z.O.O.',
      'PUENTESPINA ORCHIDS',
      'RIVIERA FLOWERS INC',
      'ROSEN FLOWERS',
      'ROYAL FLOWERS',
      'SAIHOKU',
      'SECORE FLORAL EXP. LLC',
      'SERENDIPITY FLORAL',
      'SHANGHAI XIAOSHENG',
      'SHARON TONGSANG',
      'SHIRIN FLOWERS FZCO',
      'SIERRA FLOWER',
      'SNOWFOX BRANDING COMPANY',
      'SOLE FARMS',
      'SOS FLORAL',
      'SOUTHBAY AGROBROKERS INC',
      'SPLENDOR PROTEAS LLC',
      'SPRINGFIELD FLORISTS SUPPLY INC.',
      'STAALDUINEN FLORAL LIMITED',
      'STELLAR FARMS LLC',
      'SUBFRESH FLOWER',
      'SUNBURST FARMS',
      'TBGROUP S.C.',
      'TBK BLUMEN GMBH',
      'TEUCALI FLOWERS S.A',
      'THE CLEVELAND PLANT & FLOWER',
      'THE ELITE FLOWER SAS C.I.',
      'THE FARM DIRECT COMPANY',
      'THE FLOR CO. LTD',
      'THE FLOWER MARKET VN',
      'TOO FLORANROSE',
      'TRADEWINDS INTL LLC',
      'TRADEXPERT B.V.',
      'TRANSFLOWERS LLP',
      'TREAT INC',
      'UNITED FLORAL INC',
      'VALLEY FLORAL',
      'VAN DUYVENVOORDE IMPORT B.V.',
      'VARD - KINGDOM FLOWERS',
      'VENTA FLOWER CO. LTD',
      'VERALEZA SL',
      'VERDNATURA LEVANTE SL',
      'VERED FLOWERS IMPORT LIMITED',
      'VIMASS FLORA S.L.',
      'VINFLORA PTY LTD',
      'VIRAG MARKET KFT',
      'VIVEROS JUANJO DE LAS VEGAS',
      'WAFEX PTY LTD',
      'WASHINGTON FLORAL',
      'WELCOME STORE',
      'WHOLESALE FLORIST',
      'WHOLESALE FLORIST',
      'WHOSALE INC',
      'WORLD FLORA CO. LTD.',
      'WORLD FLORAL SALES LLC',
      'WORLD TRADING',
      'YMS CO',
      'ZARINA FLOWERS',
      'ZERRY',
    ],
    countriesAyura: [
      'Alemania',
      'Australia',
      'Canada',
      'China',
      'Colombia',
      'Dubai',
      'España',
      'Estados Unidos',
      'Filipinas',
      'Holanda',
      'Hungria',
      'Inglaterra',
      'Japon',
      'Kazakstán',
      'Korea',
      'Panama',
      'Polonia',
      'Qatar',
      'Republica Dominicana',
      'Rumania',
      'Rusia',
      'U. Emirato de Árabes',
      'Varios',
      'Vietnam',
    ],
  },
  getters: {
    getCargos(state) {
      return state?.cargos;
    },
    getOrigins(state) {
      return state?.origins;
    },
    getDestinations(state) {
      return state?.destinations;
    },
    getRecipientInfo(state) {
      return state?.recipientsInfo;
    },
    getComercialCasi(state) {
      return state?.comercialCasi;
    },
    getPreReferenciaFrutaria(state) {
      return state?.preReferenciaFrutaria;
    },
    getCodPreReferenciaFrutaria(state) {
      return state?.codPreferenciaFrutaria;
    },
    isOriginBlock(state) {
      return state?.isOriginBlock;
    },
    isDestinationBlock(state) {
      return state?.isDestinationBlock;
    },
    getCarriers(state) {
      return state?.carriers;
    },
    getOriginsSanLucarTunez(state) {
      return state?.originsSanLucarTunez;
    },
    getProductsSanLucarTunez(state) {
      return state?.productsSanLucarTunez;
    },
    getCarriersSanLucarTunez(state) {
      return state?.carriersSanLucarTunez;
    },
    getDestinationsSanLucarTunez(state) {
      return state?.destinationsSanLucarTunez;
    },
    getCarriersPlanasaMoroco(state) {
      return state?.carriersPlanasaMoroco;
    },
    getProductsPlanasaMoroco(state) {
      return state?.productsPlanasaMoroco;
    },
    getDestinationsPlanasaMoroco(state) {
      return state?.destinationsPlanasaMoroco;
    },
    getClientsPlanasaMoroco(state) {
      return state?.clientsPlanasaMoroco;
    },
    getClientsAyura(state) {
      return state?.clientsAyura;
    },
    getCountriesAyura(state) {
      return state?.countriesAyura;
    },
  },
  mutations: {
    UPDATE_CARGOS_LIST(state, payload) {
      state.cargos = payload;
    },
    UPDATE_ORIGINS_LIST(state, payload) {
      state.origins = payload;
    },
    UPDATE_DESTINATIONS_LIST(state, payload) {
      state.destinations = payload;
    },
    UPDATE_USERDATA_LIST(state, payload) {
      state.userData = payload;
    },
    UPDATE_RECIPIENTINFO_LIST(state, payload) {
      state.recipientsInfo = payload;
    },
    UPDATE_CARRIERS(state, payload) {
      state.carriers = payload;
    },
    UPDATE_ORIGINSSANLUCARTUNEZ(state, payload) {
      state.originsSanLucarTunez = payload;
    },
    UPDATE_PRODUCTSSANLUCARTUNEZ(state, payload) {
      state.productsSanLucarTunez = payload;
    },
    UPDATE_CARRIERSSANLUCARTUNEZ(state, payload) {
      state.carriersSanLucarTunez = payload;
    },
    UPDATE_DESTINATIONSSANLUCARTUNEZ(state, payload) {
      state.destinationsSanLucarTunez = payload;
    },
    UPDATE_CARRIERSPLANASAMOROCO(state, payload) {
      state.carriersPlanasaMoroco = payload;
    },
    UPDATE_PRODUCTSPLANASAMOROCO(state, payload) {
      state.productsPlanasaMoroco = payload;
    },
    UPDATE_DESTINATIONSPLANASAMOROCO(state, payload) {
      state.destinationsPlanasaMoroco = payload;
    },
    UPDATE_CLIENTSPLANASAMOROCO(state, payload) {
      state.clientsPlanasaMoroco = payload;
    },
    UPDATE_CLIENTSAYURA(state, payload) {
      state.clientsAyura = payload;
    },
    UPDATE_COUNTRIESAYURA(state, payload) {
      state.countriesAyura = payload;
    },
  },
  actions: {
    async fetchCargosList({ commit }) {
      try {
        const data = await Api.cargos.getAll();
        // const data = await Api.merchandise.getAll();
        if (data.status !== 404) {
          commit('UPDATE_CARGOS_LIST', JSON.parse(data.response));
        }
      } catch (error) {
        commit('UPDATE_CARGOS_LIST', []);

        const msg = helpers.getFilteredErrorMessage(error);
        return Promise.reject(msg);
      }
      return Promise.resolve();
    },
    async fetchOriginsList({ commit }) {
      try {
        const data = await Api.origins.getAll();
        commit('UPDATE_ORIGINS_LIST', JSON.parse(data.response));
      } catch (error) {
        commit('UPDATE_ORIGINS_LIST', []);

        const msg = helpers.getFilteredErrorMessage(error);
        return Promise.reject(msg);
      }
      return Promise.resolve();
    },
    async fetchDestinationsList({ commit }) {
      const data = await Destinations.getAll();
      commit('UPDATE_DESTINATIONS_LIST', data);
    },
    async fetchRecipientInfo({ commit }) {
      // const data = await Recipients.getAll();
      const data = await Api.recipientInfo.getAll();
      commit('UPDATE_RECIPIENTINFO_LIST', JSON.parse(data.response));
    },
    async fetchCarriers({ commit }) {
      const data = await Api.carriers.getAll();
      commit('UPDATE_CARRIERS', JSON.parse(data.response));
    },
  },
};
