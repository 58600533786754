<template>
  <div class="main-extraElement">
    <f7-link
      v-if="textEnable"
      :href="info.urlDestiny"
    >
      {{ info.text }}
    </f7-link>
    <f7-link
      v-if="iconEnable"
      :href="info.urlDestiny"
    >
      <f7-icon
        :class="`icon-menu ${info.urlImage}`"
      >
        <!-- <f7-badge
          v-if="bagdeEnable"
        /components/login>
          {{ info.notificationsNumber }}
        </f7-badge> -->
      </f7-icon>
    </f7-link>
  </div>
</template>

<script>

export default {
  name: 'ExtraElement',
  props: {
    textEnable: { type: Boolean, default: false },
    iconEnable: { type: Boolean, default: false },
    bagdeEnable: { type: Boolean, default: false },
    info: { type: Object, default: null },
  },
};
</script>

<style lang="scss" scoped>
.icon-menu{
  width: 30px;
  height: 30px;
}
.icon-export {
  background-image: url('../static/icons/ic_export.svg');
}
</style>
