<template>
  <f7-block>
    <f7-row>
      <f7-col>
        <div class="label-text-data">
          <span>{{ $t('common.track.state') }}</span>
        </div>
        <DxButtonGroup
          :items="state"
          :on-selection-changed="onSelectionChanged"
          selection-mode="multiple"
          key-expr="filter"
        />
      </f7-col>

      <f7-row>
        <f7-col>
          <div class="label-text-data">
            <span>{{ $t('common.track.load') }}</span>
          </div>
          <DxButtonGroup
            :items="load"
            :on-selection-changed="onSelectionChanged"
            selection-mode="multiple"
            key-expr="filter"
          />
        </f7-col>
        <f7-col>
          <div class="label-text-data">
            <span>{{ $t('common.track.unload') }}</span>
          </div>
          <DxButtonGroup
            :items="unload"
            :on-selection-changed="onSelectionChanged"
            selection-mode="multiple"
            key-expr="filter"
          />
        </f7-col>
      </f7-row>

      <f7-col>
        <CalendarInitEndDate />
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import DxButtonGroup from 'devextreme-vue/button-group';
import { mapActions, mapGetters, mapState } from 'vuex';
import states from './states';
import CalendarInitEndDate from './calendarInitEndDate/index.vue';

export default {
  name: 'Filters',
  components: {
    DxButtonGroup,
    CalendarInitEndDate,
  },
  data() {
    return {
      load: states.load,
      unload: states.unload,
      state: states.state,
    };
  },
  computed: {
    ...mapState('Trips', ['tripsFilters']),
    ...mapGetters('Trips', ['filteredTrips']),
  },
  methods: {
    onSelectionChanged(e) {
      e.addedItems.forEach((item) => this.updateTripsFilters({ type: 'add', filter: item }));
      e.removedItems.forEach((item) => {
        this.updateTripsFilters({ type: 'remove', filter: item });
      });
    },
    ...mapActions('Trips', ['updateTripsFilters', 'setAllFilter']),
  },
};
</script>

<style>

</style>
