import moment from 'moment';
import $ from '../../../js/i18n.config';

export default {
  state: [
    {
      filter: 'statusActive',
      text: $.t('common.state.active'),
      filterExpr(row) {
        if (row.state === 'Started') return true;
        return false;
      },
    },
    {
      filter: 'statusPending',
      text: $.t('common.state.pending'),
      filterExpr(row) {
        if (row.state === 'Pending') return true;
        return false;
      },
    },
    {
      filter: 'statusFinished',
      text: $.t('common.state.finished'),
      filterExpr(row) {
        if (row.state === 'Finished') return true;
        return false;
      },
    },
  ],
  load: [
    {
      filter: 'loadToday',
      text: $.t('common.date.today'),
      filterExpr(row) {
        const today = moment();
        return row.orders.some((order) => order.loadPlaces.some((place) => moment(place.date).isSame(today, 'day')));
      },
    }, {
      filter: 'loadTomorrow',
      text: $.t('common.date.tomorrow'),
      filterExpr(row) {
        const tomorrow = moment().add(1, 'day');
        return row.orders.some((order) => order.loadPlaces.some((place) => moment(place.date).isSame(tomorrow, 'day')));
      },
    }],
  unload: [{
    filter: 'unloadToday',
    text: $.t('common.date.today'),
    filterExpr(row) {
      const today = moment();
      return row.orders.some((order) => order.deliveryPlaces.some((place) => moment(place.date).isSame(today, 'day')));
    },
  }, {
    filter: 'unloadTomorrow',
    text: $.t('common.date.tomorrow'),
    filterExpr(row) {
      const tomorrow = moment().add(1, 'day');
      return row.orders.some((order) => order.deliveryPlaces.some((place) => moment(place.date).isSame(tomorrow, 'day')));
    },
  }],
};
