<template>
  <div class="main-logout" />
</template>

<script>
import store from '../../store/index';

export default {
  name: 'Logout',
  store,
  mounted() {
    this.$f7.panel.close();
    store.dispatch('authentication/logout');
  },

};
</script>
