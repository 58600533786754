<template>
  <DxPopup
    :visible.sync="visible"
    v-bind="commonProps.popup"
    :on-hidden="hide"
    :title="$t('trips.deleteConfirmation')"
    :width="500"
  >
    <div>
      <p>{{ $t('trips.registerTrip.deleteConfirmation') }} <b>{{ order.reference }}</b> ?</p>
    </div>
    <div class="content-buttons margin-top">
      <DxButton
        class="dx-btn-cancel margin-right padding-half"
        type="normal"
        styling-mode="contained"
        :on-click="hide"
      >
        {{ $t('common.options.cancel') }}
      </DxButton>
      <DxButton
        class="dx-btn-success padding-half"
        type="success"
        styling-mode="contained"
        :on-click="handleRemoveOrder"
      >
        {{ $t('trips.deleteConfirmation') }}
      </DxButton>
    </div>
  </DxPopup>
</template>

<script>
import { DxPopup } from 'devextreme-vue/popup';
import DxButton from 'devextreme-vue/button';
import { mapActions } from 'vuex';
import eventBus from '../../../js/event-bus';
import { commonProps } from '../../../components/trips/tripsColumnData';

export default {
  name: 'RemoveOrderPopup',
  components: {
    DxPopup,
    DxButton,
  },
  data() {
    return {
      commonProps,
      order: {
        reference: '',
      },
      visible: false,
    };
  },
  mounted() {
    eventBus.$on('showRemoveOrderPopup', (order) => {
      this.order = order;
      this.visible = true;
    });
  },

  methods: {

    handleRemoveOrder() {
      this.removeOrder(this.order);
      this.hide();
    },

    hide() {
      this.visible = false;
    },
    ...mapActions('Trips', ['removeOrder']),
  },
};
</script>
