var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DxPopup',_vm._b({staticClass:"content-launch-trip-popup",attrs:{"visible":_vm.show,"on-hidden":_vm.onHidden,"title":_vm.$t('customerService.titlePopup'),"close-on-outside-click":false,"drag-enabled":true,"position":"center"},on:{"update:visible":function($event){_vm.show=$event}}},'DxPopup',_vm.commonProps.popup,false),[_c('DxScrollView',[_c('DxForm',{ref:"form"},[_c('DxGroupItem',{attrs:{"col-count":1}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('customerService.selectQuery'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.getListQuery,
            value: _vm.queryTitle,
            onValueChanged: _vm.onChangedSelectQuery,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"editor-type":"dxTextBox","editor-options":{
            readOnly: true,
            value: _vm.queryTitle,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"editor-type":"dxTextArea","editor-options":{
            readOnly: true,
            height: '100',
            value: _vm.queryDescription,
            placeholder: _vm.$t('customerService.queries.Description'),
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('customerService.booking'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.booking,
            onValueChanged: _vm.onChangedBooking,
            placeholder: _vm.$t('customerService.booking'),
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('customerService.container'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.container,
            onValueChanged: _vm.onChangedContainer,
            placeholder: _vm.$t('customerService.container'),
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('customerService.sensor'),"editor-type":"dxTextBox","editor-options":{
            value: _vm.sensor,
            onValueChanged: _vm.onChangedSensor,
            placeholder: _vm.$t('customerService.sensor'),
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('customerService.consultation'),"editor-type":"dxTextArea","editor-options":{
            value: _vm.consultation,
            height: 100,
            onValueChanged: _vm.onChangedConsultation,
            placeholder: _vm.$t('customerService.consultation'),
          }}})],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-count":2}}),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-count":3}})],1),_vm._v(" "),_c('div',{staticClass:"content-buttons margin-top"},[_c('DxButton',{staticClass:"dx-btn-cancel margin-right padding-half",attrs:{"type":"normal","styling-mode":"contained"},on:{"click":_vm.cancelTrip}},[_vm._v("\n        "+_vm._s(_vm.$t("common.options.cancel"))+"\n      ")]),_vm._v(" "),_c('DxButton',{staticClass:"dx-btn-success padding-half",attrs:{"type":"success","styling-mode":"contained"},on:{"click":_vm.validateForm}},[_vm._v("\n        "+_vm._s(_vm.$t("common.options.save"))+"\n      ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }