<template>
  <f7-page class="main-cargos">
    <ConfigPopup />
    <CargosPopup v-if="loaded" />
    <Navbar />
    <div class="wrapper">
      <div class="margin-vertical">
        <f7-row class="justify-content-flex-end">
          <DxButton
            :disabled="false"
            icon="preferences"
            @click="showConfigPopup"
          />
          <DxButton
            class="margin-left-half"
            :disabled="false"
            icon="plus"
            @click="showPopup"
          />
        </f7-row>
      </div>
      <div>
        <CargosTable />
      </div>
    </div>
  </f7-page>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import Navbar from '../../components/NavBar.vue';
import CargosTable from '../../components/cargosTable/index.vue';
import CargosPopup from '../../components/cargosTable/cargosPopup/index.vue';
import ConfigPopup from '../../components/cargosTable/configPopup/index.vue';
import eventBus from '../../js/event-bus';

export default {
  name: 'CargosPage',
  components: {
    Navbar,
    CargosTable,
    CargosPopup,
    ConfigPopup,
    DxButton,
  },
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    eventBus.$on('merchandiseLoaded', () => { this.loaded = true; });
  },
  methods: {
    showPopup() {
      eventBus.$emit('showCargosPopup');
    },
    showConfigPopup() {
      eventBus.$emit('showCargosConfigPopup');
    },
  },
};
</script>

<style lang="scss">
.main-cargos{
  .dx-icon{
    display: flex!important;
    align-items: center;
    justify-content: center;
    width: 40px!important;
    height: 40px!important;
  }
}

</style>
