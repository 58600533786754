<script>
import {
  COLOR_GREEN, COLOR_RED, COLOR_BLUE, COLOR_YELLOW, MAP_LINE_COLOR,
} from '../../../js/constants';

export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    path: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      newLine: {},
    };
  },
  mounted() {
    const { Polyline } = this.google.maps;
    this.newLine = new Polyline({
      path: this.path,
      map: this.map,
      strokeOpacity: 0.9,
      strokeWeight: 2,
      strokeColor: MAP_LINE_COLOR,
      scale: 2,
    });
  },
  methods: {
    getColorIcon(color) {
      switch (color) {
        case 'red':
          return COLOR_RED;
        case 'green':
          return COLOR_GREEN;
        case 'blue':
          return COLOR_BLUE;
        case 'yellow':
          return COLOR_YELLOW;
        default:
          return COLOR_GREEN;
      }
    },
  },

  render() {
    return this.newLine;
  },
};
</script>
