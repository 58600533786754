<template>
  <DxDataGrid
    v-bind="commonProps.dataGrid"
    :data-source="protocols"
    :columns="columns"
    :on-cell-prepared="onCellPrepared"
  />
</template>

<script>
import {
  DxDataGrid,
} from 'devextreme-vue/data-grid';
import { mapActions, mapState } from 'vuex';
import { commonProps } from '../trips/tripsColumnData';
import columns, { colMethods } from './protocolsTableColumnData';

export default {
  name: 'ProtocolsTable',
  components: {
    DxDataGrid,
  },
  data() {
    return {
      commonProps,
      columns,
    };
  },
  computed: {
    ...mapState('Protocols', ['protocols']),
  },
  async beforeMount() {
    await this.fetchProtocols();
  },
  methods: {
    onCellPrepared(row) {
      if (row.column.type === 'buttons') this.addButtonFuncs(row.column.buttons);
    },
    ...colMethods,
    ...mapActions('Protocols', ['fetchProtocols', 'removeProtocols']),
  },

};
</script>

<style>

</style>
