/* eslint-disable no-prototype-builtins */
import Api from '../../services/Api';
import helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    complaints: [],
    complaintsFilters: [],
    selectedComplaint: null,
    name: '',
    description: '',
    id: '',
    amount: null,
    isAccepted: false,
    creatorUser: '',
    documentation: [],
    idShipment: '',
    idCompany: '',
    shipmentReference: '',
  },
  getters: {
    filteredComplaints(state) {
      return state.complaints.filter((complaint) => {
        if (state.complaintsFilters.length === 0) return true;
        return state.complaintsFilters.some((filter) => filter.filterExpr(complaint));
      });
    },
    complaints(state) {
      return state.complaints;
    },
    complaintsFilters(state) {
      return state.complaintsFilters;
    },
    selectedComplaint(state) {
      return state.selectedComplaint;
    },
  },
  mutations: {
    SET_COMPLAINTS(state, payload) {
      state.complaints = payload;
    },
    INITIALIZE_COMPLAINTS_PAGE(state) {
      state.selectedComplaint = null;
    },
    FILL_FIELDS_WITH_DATA(state, payload) {
      state.selectedComplaint = payload;
    },
    SET_ID(state, value) {
      state.id = value;
    },
    SET_NAME(state, value) {
      state.name = value;
    },
    SET_DESCRIPTION(state, value) {
      state.description = value;
    },
    SET_AMOUNT(state, value) {
      state.amount = value;
    },
    SET_ISACCEPTED(state, value) {
      state.isAccepted = value;
    },
    SET_CREATORUSER(state, value) {
      state.creatorUser = value;
    },
    SET_DOCUMENTATION(state, value) {
      state.documentation = value;
    },
    SET_IDSHIPMENT(state, value) {
      state.idShipment = value;
    },
    SET_IDCOMPANY(state, value) {
      state.idCompany = value;
    },
    SET_SHIPMENTREFERENCE(state, value) {
      state.shipmentReference = value;
    },
  },
  actions: {
    initializeComplainsPage({ commit }) {
      commit('INITIALIZE_COMPLAINTS_PAGE');
    },
    async fetchComplaints({ commit }) {
      const data = await Api.complaints.getAll();
      const parsedData = JSON.parse(data.response);
      commit('SET_COMPLAINTS', parsedData);
    },
    async postComplaint({ state, dispatch }) {
      /* try {
        await Api.merchandise.post(complaint);
        await dispatch('fetchComplaints');
      } catch (error) {
        const msg = helpers.getFilteredErrorMessage(error);
        return Promise.reject(msg);
      }
      return Promise.resolve(); */

      try {
        await Api.complaints.post(
          {
            ID: state.id,
            name: state.name,
            description: state.description,
            amount: state.amount,
            isAccepted: state.isAccepted,
            creatorUser: state.creatorUser,
            documentation: state.documentation,
            idShipment: state.idShipment,
            idCompany: state.idCompany,
            shipmentReference: state.shipmentReference,
          },
        );
        await dispatch('fetchComplaints');
        return Promise.resolve();
      } catch (error) {
        const msg = helpers.getFilteredErrorMessage(error);
        return Promise.reject(msg);
      }
    },
    async removeComplaint({ dispatch }, id) {
      try {
        await Api.complaints.remove(id);
        await dispatch('fetchComplaints');
      } catch (error) {
        const msg = helpers.getFilteredErrorMessage(error);
        return Promise.reject(msg);
      }
      return Promise.resolve();
    },
    setId(context, value) {
      context.commit('SET_ID', value);
    },
    setName(context, value) {
      context.commit('SET_NAME', value);
    },
    setDescription(context, value) {
      context.commit('SET_DESCRIPTION', value);
    },
    setAmount(context, value) {
      context.commit('SET_AMOUNT', value);
    },
    setIsAccepted(context, value) {
      context.commit('SET_ISACCEPTED', value);
    },
    setCreatorUser(context, value) {
      context.commit('SET_CREATORUSER', value);
    },
    setDocumentation(context, value) {
      context.commit('SET_DOCUMENTATION', value);
    },
    setIdShipment(context, value) {
      context.commit('SET_IDSHIPMENT', value);
    },
    setIdCompany(context, value) {
      context.commit('SET_IDCOMPANY', value);
    },
    setShipmentReference(context, value) {
      context.commit('SET_SHIPMENTREFERENCE', value);
    },
    initStore({ commit }) {
      commit('SET_ID', '');
      commit('SET_NAME', '');
      commit('SET_DESCRIPTION', '');
      commit('SET_AMOUNT', null);
      commit('SET_ISACCEPTED', false);
      commit('SET_CREATORUSER', '');
      commit('SET_DOCUMENTATION', []);
      commit('SET_IDSHIPMENT', '');
      commit('SET_IDCOMPANY', '');
      commit('SET_SHIPMENTREFERENCE', '');
    },
  },
};
