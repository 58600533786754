<template>
  <f7-page
    class="main-devices"
  >
    <LaunchTripPopup />
    <Navbar :show-right-button="showRightButton" />
    <div v-if="isTablet || isMobile">
      <f7-panel
        id="panel-new-activity"
        right
        cover
      >
        <f7-link
          class="close-panel justify-content-flex-end"
          panel-close
        >
          <img :src="icClosePanel">
        </f7-link>
        <f7-block class="header-list-devices">
          <div class="action-buttons">
            <f7-button
              fill
              type="success"
              panel-close
              @click="launchTrip"
            >
              <img
                width="38"
                height="38"
                :src="icRfidDevice"
              >
              <span>{{ $t('home.launchTrip') }}</span>
            </f7-button>
            <f7-link
              type="default"
              panel-close
              @click="openMap"
            >
              <img
                width="46"
                height="46"
                :src="listIcons()[0]"
              >
            </f7-link>
          </div>
          <HomeFilters />
          <div>
            <AutomaticUpdate />
          </div>
        </f7-block>
      </f7-panel>
    </div>

    <div v-else>
      <f7-block
        class="header-list-devices"
      >
        <div class="action-buttons">
          <f7-button
            fill
            type="success"
            @click="launchTrip"
          >
            <img
              width="38"
              height="38"
              :src="icRfidDevice"
            >
            <span class="hidden-tablet-only">{{ $t('home.launchTrip') }}</span>
          </f7-button>
          <f7-link
            type="default"
            :class="{'close-map': mapShow === true}"
            class="icon-open-map"
            @click="openMap"
          >
            <img
              width="45"
              height="45"
              :src="listIcons()[0]"
            >
          </f7-link>
          <f7-link
            type="default"
            :class="{'hide': mapShow !== true}"
            class="icon-open-map"
            @click="openMap"
          >
            <img
              width="45"
              height="45"
              :src="icShowMap"
            >
          </f7-link>
        </div>
        <HomeFilters />
        <div>
          <AutomaticUpdate />
        </div>
      </f7-block>
    </div>

    <div
      v-if="mapShow"
      class="content-devx content-map-home"
    >
      <f7-row>
        <f7-col>
          <template>
            <div>
              <div>
                <TravelMap
                  class="travel-map"
                  :locations="locations"
                  :more-info-button="true"
                />
              </div>
            </div>
          </template>
        </f7-col>
      </f7-row>
    </div>
    <f7-row>
      <f7-col>
        <div v-if="isMobile">
          <Shippment />
        </div>
        <div v-else-if="isTablet">
          <Shippment />
        </div>
        <div v-else>
          <Shippment />
        </div>
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Navbar from '../../components/NavBar.vue';
import Shippment from '../../components/shippment/index.vue';
import AutomaticUpdate from '../../components/automaticUpdate/index.vue';
import TravelMap from '../../components/mapInfoDevice/map/index.vue';
import HomeFilters from '../../components/homeFilters/index.vue';
import icCloseMap from '../../static/icons/ic_close_map.svg';
import icClosePanel from '../../static/icons/ic_close_panel.svg';
import icOpenMap from '../../static/icons/ic_open_map.svg';
import icRfidDevice from '../../static/icons/ic_rfid_device_light.svg';
import LaunchTripPopup from '../../components/launchTripPopup/index.vue';
import eventBus from '../../js/event-bus';

export default {
  name: 'Devices',
  components: {
    LaunchTripPopup,
    HomeFilters,
    Navbar,
    Shippment,
    AutomaticUpdate,
    TravelMap,
  },
  props: {
    type: { type: String, default: '' },
    fullName: { type: String, default: '' },
    date: { type: Number, default: Number },
  },
  data() {
    return {
      Navbar,
      mapShow: false,
      locations: [],
      showRightButton: false,
      isMobile: false,
      isTablet: false,
    };
  },
  computed: {
    icRfidDevice: () => icRfidDevice,
    ...mapState('authentication', ['loggedUser']),
    ...mapState('Shipments', ['shipmentsList', 'shipmentsFilters']),
    icClosePanel: () => icClosePanel,
    icShowMap: () => icOpenMap,
  },
  beforeMount() {
    this.completeSyncDexie();
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
    eventBus.$on('hiddenMap', () => {
      this.mapShow = false;
    });
  },
  methods: {
    getIconColor(device) {
      const min = Number.parseFloat(device.lowerTemperature);
      const max = Number.parseFloat(device.higherTemperature);
      const temp = Number.parseFloat(device.lastTemperature);

      if (Number.isNaN(min) || Number.isNaN(max) || Number.isNaN(temp)) return 'green';

      if (temp < min) {
        return 'blue';
      } if (temp > max) {
        return 'red';
      }
      return 'green';
    },
    launchTrip() {
      eventBus.$emit('openLaunchTrip');
    },
    openMap() {
      if (this.shipmentsFilters.status === 0 || this.shipmentsFilters.status === -1) {
        this.mapShow = false;
      } else {
        const localLocations = [];
        const parsePosition = (position) => Number.parseFloat(position.replace(',', '.').replace('−', '-'));
        const isNumber = (position) => Number.isNaN(parsePosition(position));
        if (this.mapShow) { this.mapShow = false; } else { this.mapShow = true; }
        if (this.locations.length === 0) {
          this.shipmentsList.forEach((device) => {
            if (device.latitude !== null && device.longitude !== null) {
              if (!isNumber(device.latitude) && !isNumber(device.longitude)) {
                localLocations.push(
                  {
                    id: device.deviceId,
                    position: {
                      lat: Number.parseFloat(device.latitude),
                      lng: Number.parseFloat(device.longitude),
                    },
                    color: this.getIconColor(device),
                    deviceId: device.deviceId,
                  },
                );
              }
            }
          });
          this.locations = localLocations;
        }
      }
    },
    hiddenMap() {
      this.mapShow = false;
    },
    listIcons() {
      const iconsArray = [];
      if (this.mapShow === true) {
        iconsArray.push(icCloseMap);
      } else { iconsArray.push(icOpenMap); }

      return iconsArray;
    },
    onResize() {
      this.isTablet = window.innerWidth <= 1280 && window.innerWidth >= 768;
      this.showRightButton = window.innerWidth <= 1280;
      this.isMobile = window.innerWidth < 768;
    },
    ...mapActions('DatabaseSync', ['completeSyncDexie']),
  },
};
</script>

<style lang="scss">
.panel-new-activity{
  .panel-close {
    background: red;
    width: 100%;
    display: flex;
  }
}
.main-devices{
  .content-map-home {
    border-radius: 6px;
    padding: 0;
    overflow: hidden;
    border: 1px solid var(--color-primary-silver);
  }
  .travel-map {
    height: 40vh;
    min-height: 360px;
  }
  .action-buttons{
    .close-map{
      position: absolute;
      top: 100px;
      left: 15px;
    }
    a{
      span{
        color: var(--f7-button-fill-text-color);
      }
    }
  }
}
.icon-open-map {
  background: var(--f7-theme-color);
  border-radius: 6px;
}
.icon-open-map:hover {
  background: var(--f7-theme-color);
  filter: sepia(0.2);
  -webkit-filter: sepia(0.2);
}
</style>
