<template>
  <f7-navbar
    class="main-navBar"
    :sliding="false"
  >
    <CustomerService />
    <f7-nav-left sliding>
      <f7-link
        panel-toggle="left"
        class="ic-hamburguer text-color-white"
      />
      <button
        v-if="pathBack != ''"
        class="button-back"
        @click="backTo"
      >
        <img
          class="ic-back"
        >
      </button>
    </f7-nav-left>
    <f7-link
      class="title-head no-margin"
      @click="irInicio"
    >
      {{ textUpperCase }}
      <img
        class="logo-brand no-margin"
        :src="getLogo"
      >
    </f7-link>

    <!-- Change to DarkMode
    <div class="content-theme-switcher">
      <div
        id="theme-switcher"
        class="theme-switch"
      >
        <div
          class="theme-switch-button"
          @click="changeMode()"
        >
          <div class="light-indicator" />
          <div class="dark-indicator" />
        </div>
      </div>
    </div>-->

    <f7-nav-right sliding>
      <!--<f7-list
        simple-list
        class="margin-right dark-theme-check"
      >
        <f7-list-item>
          <span class="padding-right-half">Oscuro</span>
          <f7-toggle
            @change="changeMode()"
          />
        </f7-list-item>
      </f7-list>-->
      <!--<div>
        <select
          v-model="selected"
          class="dx-texteditor-input"
          @change="themeSwitch"
        >
          <option
            v-for="option in options"
            :key="option.value"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </select>
      </div>-->
      <!-- FIXME: OCULTO EN MODO PRODUCCION -->
      <!-- <Notificaciones /> -->
      <f7-link
        v-if="
          showRightButton"
        icon-ios="f7:menu"
        icon-aurora="f7:menu"
        icon-md="material:menu"
        panel-toggle="right"
        class="icon-hamburguer text-color-white"
      />
      <ExportExcel
        v-if="list!=null"
        :list="list"
        :type-export="typeExport"
      />
      <ExtraElement
        v-for="(item, index) in elements"
        :key="index"
        :icon-enable="item.iconEnable"
        :text-enable="item.textEnable"
        :bagde-enable="item.badgeEnable"
        :info="item.info"
      />

      <div>
        <DxPopup
          id="attach-file-popup"
          :title="$t('navbar.popup.deactivate.title')"
          :visible.sync="popupShow"
          :show-close-button="true"
          :on-hidden="onHidden"
          :close-on-outside-click="false"
          height="auto"
          :width="620"
        >
          <f7-block class="no-margin">
            <div class="item-title item-label">
              <p class="margin-bottom-half">
                {{ $t('navbar.popup.deactivate.description.line1') }}
                {{ $t('navbar.popup.deactivate.description.line2') }}
              </p>
            </div>
            <div class="padding-vertical-half text-align-center">
              <h4 class="color-his-primary">
                {{ $t('navbar.popup.deactivate.description.line3') }}
              </h4>
            </div>
          </f7-block>
          <f7-block-footer
            class="no-margin-top display-flex justify-content-center"
          >
            <DxButton
              class="padding-half"
              type="default"
              styling-mode="contained"
              name="Deactivate"
              @click="deactivatePopup"
            >
              {{ $t('common.options.confirm') }}
            </DxButton>
          </f7-block-footer>
        </DxPopup>
        <DxButton
          v-if="showToBtnDeactivate"
          class="margin-right"
          raised
          type="default"
          styling-mode="text"
          @click="openPopup"
        >
          <img
            width="42"
            height="42"
            class="ic-power"
          >
        </DxButton>

        <DxButton
          v-if="showToBtnCustomerService"
          class="margin-right"
          raised
          type="default"
          styling-mode="text"
          @click="showCustomerService"
        >
          <img
            width="42"
            height="42"
            class="ic-helpdesk"
            alt="customer service"
          >
        </DxButton>
      </div>
    </f7-nav-right>
  </f7-navbar>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import DxButton from 'devextreme-vue/button';
import { DxPopup } from 'devextreme-vue/popup';
import ExtraElement from './extraElement.vue';
import ExportExcel from './ExportExcel.vue';
import loginUsername from '../static/icons/ic_login_username_white.svg';
import logoTrack from '../static/icons/logo-hispatec-track-white.svg';
import Notificaciones from './notificaciones/index.vue';
import EventBus from '../js/event-bus';
import CustomerService from './customerService/index.vue';

export default {
  name: 'Navbar',
  components: {
    ExtraElement,
    ExportExcel,
    DxPopup,
    DxButton,
    CustomerService,
    Notificaciones,
  },
  props: {
    elements: { type: Array, default: () => [] },
    list: { type: Array, default: () => null },
    typeExport: { type: String, default: '' },
    pathBack: { type: String, default: '' },
    text: { type: String, default: '' },
    showToBtnDeactivate: { type: Boolean, default: false },
    showRightButton: { type: Boolean, default: false },
    showToBtnCustomerService: { type: Boolean, default: false },
  },
  data() {
    return {
      textUpperCase: '',
      popupShow: false,
      selected: 'light',
      darkMode: false,
      options: [
        { text: 'Light', value: 'root' },
        { text: 'Dark', value: 'dark' },
      ],
    };
  },
  computed: {
    ...mapState('Shipments', ['currentShip']),
    ...mapGetters('authentication', [
      'getGenericAttributesNames',
      'getTokenDateExpired',
      'user',
      'getLogo',
      'getUserThemename',
      'getThemeColor',
    ]),
    loginUsername: () => loginUsername,
    logoTrack: () => logoTrack,
  },

  async beforeMount() {
    // Comprobamos si ha caducado la sesión.
    const self = this;
    const now = new Date();
    const dateExpired = new Date(this.getTokenDateExpired);
    if (now.getTime() > dateExpired.getTime()) {
      this.$f7.dialog.alert(this.$t('login.sessionHasExpired'));
      self.logout();
    }
    this.textUpperCase = this.text.toUpperCase();

    const htmlElement = document.documentElement;

    // Select a theme depending of the user Theme color.
    localStorage.setItem('theme', this.getThemeColor);
    htmlElement.setAttribute('theme', this.getThemeColor);
  },
  methods: {
    themeSwitch() {
      const htmlElement = document.documentElement;
      localStorage.setItem('theme', this.selected);
      htmlElement.setAttribute('theme', this.selected);
    },
    // Method which change to Dark mode
    changeMode() {
      this.darkMode = !this.darkMode;
      const htmlElement = document.documentElement;
      if (this.darkMode === true) {
        localStorage.setItem('theme', 'dark');
        htmlElement.setAttribute('theme', 'dark');
      } else {
        localStorage.setItem('theme', this.selected);
        htmlElement.setAttribute('theme', this.selected);
      }
    },
    openPopup() {
      this.popupShow = true;
    },
    onHidden() {
      this.popupShow = false;
    },
    async deactivatePopup() {
      // TODO:
      this.$f7.preloader.show();
      try {
        this.$f7.dialog.alert(this.$t('navbar.popup.deactivate.notification'));
        this.$f7.views.main.router.navigate('/', { reloadCurrent: true });
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
        this.$f7.views.main.router.navigate('/', { reloadCurrent: true });
      }
    },
    backTo() {
      this.$f7.views.main.router.navigate(this.pathBack, {
        reloadCurrent: true,
      });
    },
    irInicio() {
      this.$f7.views.main.router.navigate('/', { reloadCurrent: true });
    },
    showCustomerService() {
      EventBus.$emit('openCustomerService');
    },
    ...mapActions('authentication', ['logout']),
  },
};
</script>
<style lang="scss">
.dark-theme-check {
  ul {
    background: transparent;
    &::before{
      height: 0;
    }
    &::after{
      height: 0;
    }
  }
}

/* Switcher styles*/
.content-theme-switcher{
  position: relative;
  top: 0;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  zoom: 0.8;
}
.theme-switch{
  background-repeat: no-repeat;
  float: left;
  overflow: hidden;
  height: 28px;
  width: 54px;
  margin-right: 10px;
  border-radius: 14px;
  .theme-switch-button{
    position: relative;
    &:hover{
      cursor: pointer;
    }
    .light-indicator, .dark-indicator{
      width: 54px;
      height: 28px;
      background-color: transparent;
      outline: none;
      border: 0;
      position: absolute;
      transition: left .1s linear,opacity .1s linear;
    }
    .dark-indicator{
      content: url("../static/icons/dark-mode.svg");
    }
    .light-indicator{
      content: url("../static/icons/light-mode.svg");
    }
  }
}
.light-indicator{
  opacity: 1;
  left: 0;
}
.dark-indicator{
  opacity: 0;
  left: 26px;
}
[theme='dark'] {
  .light-indicator{
    opacity: 0;
    left: -26px;
  }
  .dark-indicator{
    opacity: 1;
    left: 0;
  }
}

/* END Switcher styles*/

.ic-back {
  content: url("../static/icons/ic_back.svg");
}
.ic-power {
  content: url("../static/icons/ic_power.svg");
}
.ic-helpdesk {
  content: url("../static/icons/ic_helpdesk.svg");
}
.ic-hamburguer {
  content: url("../static/icons/ic_hamburguer.svg");
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.ic-power:hover,
.ic-hamburguer:hover {
  filter: saturate(0.7);
}

/* Añadido en css FIX? */
.fileuploader-container {
  border: 1px solid #d3d3d3;
}
.md .navbar a.icon-only {
  min-width: 76px;
}
</style>
