<template>
  <div class="content-alert">
    <img
      v-for="svg in parseAlerts(data)"
      :key="svg"
      :src="svg"
      class="alert-icon"
    >
  </div>
</template>

<script>
import tempHighIcon from '../../../static/icons/ic_high_level.svg';
import tempHighIconDefault from '../../../static/icons/ic_level.svg';
import lightIcon from '../../../static/icons/ic_high_rute.svg';
import lightIconDefault from '../../../static/icons/ic_light_off.svg';

export default {
  name: 'AlertCell',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    parseAlerts(data) {
      const alertsArray = data.value.split(' ').map((alert) => (alert === 'true'));
      const iconsArray = [];
      if (alertsArray[0]) {
        iconsArray.push(tempHighIcon);
      } else {
        iconsArray.push(tempHighIconDefault);
      }
      if (alertsArray[1]) {
        iconsArray.push(lightIcon);
      } else {
        iconsArray.push(lightIconDefault);
      }

      return iconsArray;
    },
  },
};
</script>
