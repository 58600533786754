<template>
  <DxTabPanel>
    <DxItem :title="$t('common.track.orders')">
      <template #default>
        <OrdersTab :data="templateData" />
      </template>
    </DxItem>
    <DxItem :title="$t('common.blocks.devices')">
      <template #default>
        <DevicesTab :data="templateData" />
      </template>
    </DxItem>
    <DxItem :title="$t('common.blocks.carriers')">
      <template #default>
        <CarriersTab :data="templateData" />
      </template>
    </DxItem>
    <DxItem :title="$t('common.blocks.documents')">
      <template #default>
        <DocumentsTab :data="templateData" />
      </template>
    </DxItem>
    <DxItem :title="$t('common.blocks.complaints')">
      <template #default>
        <ComplatinsTab :data="templateData" />
      </template>
    </DxItem>
  </DxTabPanel>
</template>

<script>
import DxTabPanel, { DxItem } from 'devextreme-vue/tab-panel';
import OrdersTab from './ordersTab/index.vue';
import DevicesTab from './devicesTab/index.vue';
import CarriersTab from './carriersTab/index.vue';
import DocumentsTab from './documentsTab/index.vue';
import ComplatinsTab from './complatinsTab/index.vue';

export default {

  name: 'DetailTemplate',
  components: {
    OrdersTab,
    DxTabPanel,
    DevicesTab,
    CarriersTab,
    DocumentsTab,
    ComplatinsTab,
    DxItem,
  },
  props: {
    templateData: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  mounted() {
  },

};
</script>
