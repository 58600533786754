<template>
  <div class="main-exportExcel">
    <DxButton
      class="buttonStyles"
      @click="downloadFile()"
    >
      <ExtraElement
        :icon-enable="botonExtraELement.iconEnable"
        :text-enable="botonExtraELement.textEnable"
        :bagde-enable="botonExtraELement.badgeEnable"
        :info="botonExtraELement.info"
      />
    </DxButton>
    <table
      id="loremTable"
      ref="table"
      class="ExportExcel"
      summary="lorem ipsum sit amet"
      rules="groups"
      frame="hsides"
      border="2"
    >
      <caption name="captionListaEmpleados">
        {{ $t("ExportExcel_captionListaEmpleados") }}
      </caption>
      <colgroup align="center" />
      <colgroup align="left" />
      <colgroup
        span="2"
        align="center"
      />
      <colgroup
        span="3"
        align="center"
      />
      <thead valign="top">
        <tr>
          <th>Id</th>
          <th name="thNombre">
            {{ $t("ExportExcel_thNombre") }}
          </th>
          <th name="thTipo">
            {{ $t("ExportExcel_thTipo") }}
          </th>
          <th name="thFecha">
            {{ $t("ExportExcel_thFecha") }}
          </th>
        </tr>
      </thead>
      <tbody
        v-for="(item, index) in list"
        :key="index"
      >
        <tr>
          <td align="center">
            {{ index }}
          </td>
          <td>{{ item.fullname }}</td>
          <td align="center">
            {{ item.event.type }}
          </td>
          <td>{{ item.event.date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment';
import DxButton from 'devextreme-vue/button';
import ExtraElement from './extraElement.vue';
import EventBus from '../js/event-bus';

export default {
  name: 'ExportExcel',
  components: {
    ExtraElement,
    DxButton,
  },
  props: { list: { type: Array, default: () => [] }, typeExport: { type: String, default: 'access' } },
  data() {
    return {
      uri: 'data:application/vnd.ms-excel;base64,',
      template: '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) { return window.btoa(unescape(encodeURIComponent(s))); },
      format(s, c) { return s.replace(/{(\w+)}/g, (m, p) => c[p]); },
      botonExtraELement: {
        iconEnable: true, textEnable: false, bagdeEnable: false, info: { urlImage: 'icon-export' },
      },
      filename: null,
    };
  },
  methods: {
    tableToExcel(table, name) {
      if (!table.nodeType) table = this.$refs.table;
      const ctx = { worksheet: name || 'Worksheet', table: table.innerHTML };
      window.location.href = this.uri + this.base64(this.format(this.template, ctx));
    },
    downloadFile(filenameDefault = 'workers', path = 'file:///storage/emulated/0/Download') {
      // NOMBRE CON EL QUE EL FICHERO SERÁ DESCARGADO
      let filename = filenameDefault;
      const today = new Date();
      filename += today.getDate().toString();
      filename += (today.getMonth() + 1);
      filename += today.getFullYear();
      filename += today.getHours();
      filename += today.getMinutes();
      filename += today.getSeconds();
      filename += '.csv';
      this.filename = filename;
      window.resolveLocalFileSystemURL(path, (directory) => {
        directory.getFile(filename, { create: true }, (fileEntry) => {
          if (this.typeExport === 'workersSelection') {
            let fileExit = 'Activity code;Activity name;Task code;Task name;Location;Badge ID;Name;Surname;Localname;Date;CheckIn;CheckOut;Working Time;Performance;Unit\r\n';
            for (const item in this.list) {
              if (item != null) {
                fileExit += `${this.list[item].activity_id};`;
                fileExit += `${this.list[item].activity_name};`;
                fileExit += `${this.list[item].task_id};`;
                fileExit += `${this.list[item].task_name};`;
                fileExit += `${this.list[item].location};`;
                fileExit += `${this.list[item].badge_id};`;
                fileExit += `${this.list[item].name};`;
                fileExit += `${this.list[item].surname};`;
                fileExit += `${this.list[item].localname};`;
                const checkinDate = moment(this.list[item].init_date);
                fileExit += `${checkinDate.format('DD/MM/YYYY')};`;
                fileExit += `${checkinDate.format('HH:mm:ss')};`;
                if (this.list[item].end_date !== null) {
                  const checkoutDate = moment(this.list[item].end_date);
                  fileExit += `${checkoutDate.format('HH:mm:ss')};`;
                  const duration = moment.utc((moment.duration(checkoutDate.diff(checkinDate)).asMilliseconds())).format('HH:mm');
                  fileExit += `${duration};`;
                  fileExit += `${this.list[item].performance};`;
                  fileExit += `${this.list[item].unit};`;
                }
                fileExit += '\r\n';
              }
            }
            const data = new Blob([`${fileExit}`], { type: 'text/csv;charset=utf-8;' });
            this.writeFile(fileEntry, data);
          } else {
            let fileExit = 'fullname;clockin_date;clockout_date\r\n';
            for (const item in this.list) {
              if (item != null) {
                fileExit += `${this.list[item].fullname};`;
                if (this.list[item].event.type === 'IN') {
                  fileExit += `${this.list[item].event.date};`;
                } else {
                  fileExit += ` ;${this.list[item].event.date}`;
                }
                fileExit += ';\r\n';
              }
            }
            const data = new Blob([`${fileExit}`], { type: 'text/csv;charset=utf-8;' });
            this.writeFile(fileEntry, data);
          }
        });
      }, this.onErrorLoadFileSystem);
    },
    onErrorLoadFileSystem(e) {
      console.debug('onErrorLoadFileSystem');
      console.debug(e);
    },
    onErrorCreateFile(e) {
      console.debug('onErrorCreateFile');
      console.debug(e);
    },
    writeFile(fileEntry, data) {
      // Create a FileWriter object for our FileEntry (log.txt).
      fileEntry.createWriter((fileWriter) => {
        fileWriter.onwriteend = () => {
          EventBus.$emit('exportCSV', { filename: this.filename, status: 'ok' });
        };

        fileWriter.onerror = function (e) {
          console.debug(`Failed file write: ${e.toString()}`);
          EventBus.$emit('exportCSV', { filename: this.filename, status: 'error' });
        };
        fileWriter.write(data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.ExportExcel{
  display: none;
}
.icon-menu {
  width: 30px;
  height: 30px;
}
.buttonStyles {
  background: none;
  border: none;
}
.button{
  display: contents;
}
</style>
