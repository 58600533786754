<template>
  <div class="main-reports">
    <f7-row no-gap>
      <f7-col>
        <f7-card class="card-block">
          <div class="card-header">
            {{ $t('deviceInfo.reports.trackingTrip') }}
          </div>
          <div class="card-body">
            <DxDataGrid
              :data-source="reports"
              :show-borders="false"
              :show-column-lines="true"
              :show-row-lines="true"
              :allow-column-resizing="true"
              :focused-row-enabled="true"
              key-expr="dateTimeAcquiredUtc"
              :row-alternation-enabled="rowAlternationEnabled"
              @cell-prepared="onCellPrepared"
            >
              <DxHeaderFilter :visible="true" />
              <DxFilterRow
                :visible="true"
              />
              <DxPager
                :allowed-page-sizes="[50, 100, 500, 1000]"
                :show-page-size-selector="true"
                :show-navigation-buttons="true"
              />
              <DxPaging :page-size="16" />
              <DxPager
                :show-page-size-selector="true"
                :allowed-page-sizes="pageSizes"
                :show-info="true"
              />
              <DxColumn
                data-field="dateTimeAcquiredUtc"
                data-type="date"
                :width="160"
                cell-template="date-template"
                :caption="$t('common.date.dateStart')"
              />
              <DxColumn
                data-field="deviceTemp"
                :caption="'ºC'"
                alignment="center"
                :width="90"
                data-type="number"
                cell-template="temp-cell"
                :calculate-filter-expression="getTempFilters"
                :allow-header-filtering="true"
                :allow-sorting="false"
              >
                <DxHeaderFilter :data-source="createFilters" />
              </DxColumn>
              <DxColumn
                data-field="eventCity"
                :caption="$t('common.address.location')"
                :allow-header-filtering="true"
                :allow-sorting="false"
              />
              <DxColumn
                data-field="eventZip"
                :caption="$t('common.address.postalCode')"
                :allow-header-filtering="true"
                alignment="left"
                :allow-sorting="false"
              />
              <DxColumn
                data-field="humidity"
                data-type="humidity"
                cell-template="humidity-template"
                :caption="$t('common.track.humidity')"
              />
              <DxColumn
                v-for="col in extraCols"
                :key="col.key"
                :data-field="col.key"
                :caption="`${$t(`shipping.${col.key}`)} ${col.unit}`"
                format="#'%'"
                alignment="center"
                data-type="number"
                :allow-header-filtering="false"
                :allow-sorting="false"
              />
              <DxColumn
                data-field="alertCode"
                :width="110"
                :caption="$t('common.track.alert')"
                cell-template="alert-template"
                alignment="center"
                :allow-sorting="false"
              >
                <DxHeaderFilter
                  :data-source="alertCodes"
                />
              </DxColumn>
              <template #date-template="{data}">
                {{ formatDate(data.value) }}
              </template>
              <template #humidity-template="{data}">
                {{ formatHumity(data.value) }}
              </template>
              <template #status-template="{data}">
                <div
                  class="status"
                  :class="getStatusClass(data)"
                />
              </template>
              <template
                #temp-cell="{data}"
              >
                <span :class="`temp-${getTempCellColor(data)}`">
                  {{ data.value !== null ? data.value.toFixed(2) +'º' : ' ' }}</span>
              </template>
              <template #alert-template="{data}">
                <div v-if="getAlertIcon(data)">
                  <img
                    :key="getAlertIcon(data)"
                    :src="getAlertIcon(data)"
                    width="24"
                    height="24"
                  >
                </div>
              </template>
            </DxDataGrid>
          </div>
        </f7-card>
      </f7-col>
    </f7-row>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxPager,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import moment from 'moment';
import lightIcon from '../../static/icons/ic_light.svg'; // '../../static/icons/ic_high_rute.svg';
import Table from '../Table/index.vue';
import eventBus from '../../js/event-bus';

export default {
  name: 'Reports',
  components: {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    Table,
    DxPager,
    DxPaging,
    DxFilterRow,
  },
  data() {
    return {
      shipmentInfo: {
        title: this.$t('deviceInfo.information.sideTable.shipmentInfo.title'),
        data: [],
      },
      shipmentSummary: {
        title: this.$t('deviceInfo.information.sideTable.shipmentSummary.title'),
        data: [],
      },
      alertCodes: [{
        value: '906',
        text: this.$t('common.track.lightAlert'),
      }, {
        value: '',
        text: this.$t('common.track.noAlert'),
      }],
      pageSizes: [15, 25, 50, 100],
      rowAlternationEnabled: true,
      extraCols: [],
    };
  },
  computed: {
    ...mapGetters('Shipments', ['getTableShipmentInfo', 'getTableShipmentSummary']),
    ...mapState('Shipments', [
      'currentShip', 'reports',
    ]),
  },
  async beforeMount() {
    try {
      this.shipmentInfo.data = this.getTableShipmentInfo;
      this.shipmentSummary.data = this.getTableShipmentSummary;
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
    }
  },
  mounted() {
    /* eventBus.$on('humidityIncluded', () => {
      this.extraCols.push({ key: 'humidity', unit: '%' });
    }); */
  },
  methods: {
    getTempFilters(filterValue) {
      const column = this;
      switch (filterValue) {
        case 'inRange':
          return [this.inRange, '=', true];
        case 'highRange':
          return [this.highRange, '=', true];
        case 'lowRange':
          return [this.lowRange, '=', true];
        default:
          break;
      }

      // eslint-disable-next-line prefer-spread
      return column.defaultCalculateFilterExpression.apply(column, arguments);
    },
    highRange(row) {
      if (row.deviceTemp > this.currentShip.higherTemperature) return true;
      return false;
    },
    lowRange(row) {
      if (row.deviceTemp < this.currentShip.lowerTemperature) return true;
      return false;
    },
    inRange(row) {
      if (!this.highRange(row) && !this.lowRange(row)) return true;
      return false;
    },
    createFilters(data) {
      data.dataSource.postProcess = () => {
        const filterTypes = [
          { key: 'inRange', value: 'inRange', text: this.$t('common.temperature.inRange') },
          { key: 'highRange', value: 'highRange', text: this.$t('common.temperature.highRange') },
          { key: 'lowRange', value: 'lowRange', text: this.$t('common.temperature.lowRange') },
        ];
        return filterTypes;
      };
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    formatHumity(data) {
      if (data !== null && data !== '') return `${data}%`;
      return '';
    },
    onCellPrepared(row) {
      if (row.column.dataField === 'deviceTemp' && row.data) this.getTempCellColor(row);
    },
    getStatusClass(row) {
      if (row.value === 39) return 'inactive';
      if (Number.parseInt(row.data.deviceID, 10)) return 'active';
      return 'pending';
    },
    getTempCellColor(row) {
      const range = this.parseRange(this.currentShip.range);
      const temp = Number.parseFloat(row.value);

      if (temp < range.min) return 'low';
      if (temp > range.max) return 'high';
    },
    parseRange(range) {
      const ranges = range.split(' ');

      return {
        min: Number.parseFloat(ranges[0]),
        max: Number.parseFloat(ranges[2]),
      };
    },
    getAlertIcon(data) {
      if (data.value === '906') {
        return lightIcon;
      }
      return false;
    },
    ...mapActions('Shipments', ['fetchReports']),
  },
};
</script>
<style lang="scss" scoped>
  @import url('Reports.styles.scss');
</style>
