<template>
  <div class="main-login">
    <f7-block>
      <f7-login-screen-title
        class="no-margin-bottom"
        name="txtWelcome"
      >
        <img
          class="login-logo"
          :src="APP_LOGO"
          :alt="APPLICATION_NAME"
        >
        <h4 class="text-color-white no-padding-bottom no-margin-bottom">
          Bienvenido 👋,
        </h4>
      </f7-login-screen-title>
      <p class="text-color-white text-align-center">
        Introduce tus datos para acceder <span class="hidden-mobile">al panel de control</span>.
      </p>
      <div class="card margin-bottom">
        <div class="card-body">
          <f7-list
            form
            no-hairlines-between
          >
            <f7-list-input
              name="inpUsername"
              outline
              item-label
              type="text"
              :placeholder="$t('login.inpUsername.placeholder')"
              clear-button
              label="Usuario"
              max="255"
              required
              validate
              :error-message="$t('login.inpUsername.validate')"
              autocomplete="username"
              @input="username = $event.target.value"
            />
            <f7-list-input
              name="inpPassword"
              outline
              item-label
              label="Contraseña"
              type="password"
              :placeholder="$t('login.inpPassword.placeholder')"
              clear-button
              max="255"
              required
              autocomplete="current-password"
              validate
              :error-message="$t('login.inpPassword.validate')"
              @input="password = $event.target.value"
              @keypress.native.enter="signIn"
            />
          </f7-list>
          <f7-list>
            <f7-list-item
              v-if="hasRememberMe"
              class="login-remember"
              checkbox
              value="chkRememberMe"
              name="chkRememberMe"
              :title="$t('login.chkRememberMe')"
              :checked="wantToRemember"
              @change="setWantToRemember"
            />
            <f7-button
              name="btnSignIn"
              preloader
              button
              button-large
              fill
              no-ripple
              :disabled="btnDisable"
              style="color:#FFFFFF"
              @click="signIn"
            >
              <!-- TODO FIX .dx-theme-generic-typography a -->
              {{ $t("login.btnSignIn") }}
            </f7-button>
          </f7-list>
        </div>
      </div>
      <f7-block-footer class="margin-top">
        <span class="text-color-white">{{ $t("login.txtLoginInfo") }}</span>
        <a
          class="link external text-color-yellow padding-vertical-half"
          target="_blank"
          href="https://www.hispatectrack.com/"
        ><u>{{ $t("login.txtRequestRegistration") }}</u></a>
      </f7-block-footer>
      <f7-block class="text-align-center content-login-footer">
        <span class="text-color-white"> &#xA9; HispatecTrack</span>
        <span class="text-color-white"><i class="fa fa-phone" /> <span class="small">+34</span>
          <a
            href="tel:+34662 92 67 32"
            class="link external text-color-white"
          >662 92 67 32</a>
        </span>
        <span>
          <a
            class="link external text-color-white small"
            target="_blank"
            href="https://www.hispatectrack.com/aviso-legal/"
          >{{ $t("login.txtLegalWarning") }}</a>
        </span>
        <span>
          <a
            class="link external text-color-white small"
            target="_blank"
            href="https://www.hispatectrack.com/politica-de-privacidad/"
          >{{ $t("login.txtPrivacy") }}</a>
        </span>
      </f7-block>
    </f7-block>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { APPLICATION_NAME, APP_LOGO } from '../../js/constants';

export default {
  name: 'Login',
  props: {
    hasRememberMe: { type: Boolean, default: false },
  },
  data() {
    return {
      username: '',
      password: '',
      APPLICATION_NAME,
      APP_LOGO,
    };
  },
  computed: {
    btnDisable() {
      return this.username && !this.password;
    },
    appLogo() {
      return localStorage.getItem('applogo') || this.APP_LOGO;
    },
    ...mapState('authentication', ['wantToRemember']),
  },
  beforeMount() {
    this.resetLastSync();
  },
  methods: {
    async signIn() {
      this.$f7.preloader.show();
      try {
        await this.login({
          username: this.username,
          password: this.password,
          remember: this.wantToRemember,
        });
      } catch (error) {
        console.log(error);
        this.$f7.dialog.alert(this.$t('login.errorLogin'));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    onEnter() {
      console.log('pulsado enter ');
    },
    ...mapActions('authentication', ['login', 'setWantToRemember', 'changePassword', 'wantToRecoveryPass']),
    ...mapActions('DatabaseSync', ['resetLastSync']),
  },
};
</script>

<style lang="scss" scoped>
@import "./Login.styles.scss";
</style>
