<template>
  <DxPopup
    v-bind="commonProps.popup"
    :visible.sync="show"
    :on-hidden="onHidden"
    :title="$t('customerService.titlePopup')"
    :close-on-outside-click="false"
    :drag-enabled="true"
    position="center"
    class="content-launch-trip-popup"
  >
    <DxScrollView>
      <DxForm ref="form">
        <DxGroupItem :col-count="1">
          <DxSimpleItem
            :data-field="$t('customerService.selectQuery')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: getListQuery,
              value: queryTitle,
              onValueChanged: onChangedSelectQuery,
            }"
          />
          <DxSimpleItem
            editor-type="dxTextBox"
            :editor-options="{
              readOnly: true,
              value: queryTitle,
            }"
          />
          <DxSimpleItem
            editor-type="dxTextArea"
            :editor-options="{
              readOnly: true,
              height: '100',
              value: queryDescription,
              placeholder: $t('customerService.queries.Description'),
            }"
          />
          <DxSimpleItem
            :data-field="$t('customerService.booking')"
            editor-type="dxTextBox"
            :editor-options="{
              value: booking,
              onValueChanged: onChangedBooking,
              placeholder: $t('customerService.booking'),
            }"
          />
          <DxSimpleItem
            :data-field="$t('customerService.container')"
            editor-type="dxTextBox"
            :editor-options="{
              value: container,
              onValueChanged: onChangedContainer,
              placeholder: $t('customerService.container'),
            }"
          />
          <DxSimpleItem
            :data-field="$t('customerService.sensor')"
            editor-type="dxTextBox"
            :editor-options="{
              value: sensor,
              onValueChanged: onChangedSensor,
              placeholder: $t('customerService.sensor'),
            }"
          />
          <DxSimpleItem
            :data-field="$t('customerService.consultation')"
            editor-type="dxTextArea"
            :editor-options="{
              value: consultation,
              height: 100,
              onValueChanged: onChangedConsultation,
              placeholder: $t('customerService.consultation'),
            }"
          />
        </DxGroupItem>
        <DxGroupItem :col-count="2" />
        <DxGroupItem :col-count="3" />
      </DxForm>
      <div class="content-buttons margin-top">
        <DxButton
          class="dx-btn-cancel margin-right padding-half"
          type="normal"
          styling-mode="contained"
          @click="cancelTrip"
        >
          {{ $t("common.options.cancel") }}
        </DxButton>
        <DxButton
          class="dx-btn-success padding-half"
          type="success"
          styling-mode="contained"
          @click="validateForm"
        >
          {{ $t("common.options.save") }}
        </DxButton>
      </div>
    </DxScrollView>
  </DxPopup>
</template>

  <style lang="scss">

  .content-launch-trip-popup{
    @media (min-width: 1024px) {
      .dx-overlay-content{
        height: auto!important;
        width: auto!important;
      }
    }
  }

  .main-launch-trip{
    .content-devx{
      width: 1080px;
      margin: 15px auto;
    }
  }
  .checkbox-items{
    .dx-field-item-label-content{
      width: auto!important;
      min-width: 35px;
    }
    &.dx-field-item:not(.dx-last-col) {
      padding-right: 0;
    }
    .dx-field-item-label-location-left{
      padding-right: 5px;
    }
    &.dx-last-col .dx-field-item-content{
      display: flex!important;
      align-items: flex-start;
    }
    &.dx-field-item:not(.dx-first-col) {
      padding-left: 5px;
    }
  }
  </style>

<script>

import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
} from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import DxButton from 'devextreme-vue/button';
import 'devextreme-vue/radio-group';
import 'devextreme/ui/date_box';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { mapActions, mapGetters, mapState } from 'vuex';
import eventBus from '../../js/event-bus';
import { commonProps } from '../trips/tripsColumnData';
import i18n from '../../js/i18n.config';

export default {
  name: 'CustomerService',
  components: {
    DxScrollView,
    DxPopup,
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxButton,
  },
  props: {},
  data() {
    return {
      commonProps,
      show: false,
      loaded: false,
    };
  },

  computed: {
    form() {
      return this.$refs.form.instance;
    },
    ...mapState('CustomerService', ['listQuery',
      'query',
      'queryTitle',
      'queryDescription',
      'booking',
      'container',
      'consultation',
      'sensor',
    ]),
    ...mapGetters('authentication', ['getThemeData', 'getPermissions']),
    ...mapGetters('CustomerService', ['getListQuery']),
  },
  async beforeMount() {
    this.loaded = false;

    this.initStore();

    this.$f7.preloader.show();
    this.loaded = true;
    this.$f7.preloader.hide();
  },
  async mounted() {
    eventBus.$on('openCustomerService', () => {
      this.show = true;
      this.initStore();
    });
  },
  methods: {
    onHidden() {
      this.show = false;
      this.form.resetValues();
      this.initStore();
    },
    validateForm() {
      this.sendMail();
    },
    async sendMail() {
      // TODO: Realizar proceso.
      try {
        await this.sendConsult();
        this.$f7.dialog.alert(this.$t('customerService.requestSent'));
        this.show = false;
        this.form.resetValues();
      } catch (error) {
        this.$f7.dialog.alert(this.$t('launchTrip.noWritePermissions'));
      }
    },
    async cancelTrip() {
      this.initStore();
      this.show = false;
      this.form.resetValues();
    },
    consultRecipientInfo(identifier) {
      return this.listQuery.find((o) => o.text === identifier);
    },
    onChangedSelectQuery(e) {
      if (e.value !== null && e.value !== undefined && typeof e.value === 'object') {
        this.setQuery(e.value);
      }
    },
    onChangedBooking(e) {
      this.setBooking(e.value);
    },
    onChangedContainer(e) {
      this.setContainer(e.value);
    },
    onChangedConsultation(e) {
      this.setConsultation(e.value);
    },
    onChangedSensor(e) {
      this.setSensor(e.value);
    },
    ...mapActions('CustomerService', ['initStore', 'sendConsult', 'setQuery',
      'setQueryTitle', 'setSensor', 'setConsultation', 'setContainer', 'setBooking', 'setQueryDescription']),
  },
};
</script>
