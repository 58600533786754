<template>
  <div main-administration-columns>
    {{ $t('administration.columns.devices.title') }}

    <DxScrollView>
      <DxForm
        ref="form"
        class="main-administration-columns"
      >
        <DxGroupItem :col-count="2">
          <DxGroupItem :col-count="4">
            <DxSimpleItem
              v-for="(item, index) in allColumns"
              :key="index"
              css-class="checkbox-items"
              editor-type="dxCheckBox"
              :editor-options="{
                items: listColumns,
                text: item.name,
                onValueChanged: onChangedColumns(item),
              }"
            />
          </DxGroupItem>
          <DxGroupItem :col-count="3" />
        </DxGroupItem>
      </DxForm>
      <div class="content-buttons margin-top">
        <DxButton
          class="dx-btn-cancel margin-right padding-half"
          type="normal"
          styling-mode="contained"
          @click="cancel"
        >
          {{ $t("common.options.cancel") }}
        </DxButton>
        <DxButton
          class="dx-btn-success padding-half"
          type="success"
          styling-mode="contained"
          @click="validateForm"
        >
          {{ $t("common.options.save") }}
        </DxButton>
      </div>
    </DxScrollView>
  </div>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
} from 'devextreme-vue/form';
import DxButton from 'devextreme-vue/button';
import 'devextreme-vue/radio-group';
import 'devextreme/ui/date_box';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { mapActions, mapGetters, mapState } from 'vuex';
import allColumns from '../allColumns';
// import eventBus from '../../js/event-bus';

export default {
  name: 'AdministrationColumnsDevicesTable',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxButton,
    DxScrollView,
  },
  data() {
    return {
      allColumns,
    };
  },
  computed: {
    listColumns() {
      return this.getColumnPreferences; // this.getColumnPreferences.map((content) => content.Name);
    },
    ...mapGetters('authentication', ['getGenericAttributesNames', 'getColumnPreferences']),

  },
  methods: {
    async cancel() {
      this.$f7.views.main.router.navigate('/', { reloadCurrent: true });
    },
    async validateForm() {
      // TODO: Realizar proceso.
      try {
        // await this.cambiarColumnas();
        this.$f7.dialog.alert(this.$t('administration.columns.devices.updateSetting'));
      } catch (error) {
        // this.$f7.dialog.alert(this.$t('launchTrip.noWritePermissions'));
      }
    },
    onChangedColumns(e) {
      // this.setPreferedColumns(e.value);
    },
    ...mapActions('authentication', ['setPreferedColumns']),
  },

};
</script>
<style lang="scss">
.main-administration-columns{
  .content-devx{
    width: 1080px;
    margin: 15px auto;
  }
}
.checkbox-items{
  .dx-field-item-label-content{
    width: auto!important;
    min-width: 35px;
  }
  &.dx-field-item:not(.dx-last-col) {
    padding-right: 0;
  }
  .dx-field-item-label-location-left{
    padding-right: 5px;
  }
  &.dx-last-col .dx-field-item-content{
    display: flex!important;
    align-items: flex-start;
  }
  &.dx-field-item:not(.dx-first-col) {
    padding-left: 5px;
  }
}
</style>
