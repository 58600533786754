<template>
  <f7-panel
    left
    cover
    class="panel panel-left not-animated content-menu"
    :opened="showToDefault"
  >
    <f7-page>
      <div
        class="content-left-panel-header padding-horizontal padding-vertical-half"
      >
        <span class="version-app"><strong>©HispatecTrack</strong> {{ version }}</span>
        <div class="content-left-panel-header-user">
          <div
            v-if="$helpers.havePermission(getPermissions.photo_login_show)"
           class="content-left-panel-header-user__img">
            <img
              :src="iconUser"
              class="img-imagenPrincipalTrabajador"
            >
          </div>
          <div class="margin-left-half">
            <div class="content-left-panel-header-user__title">
              {{ user.user.contact.name }} {{ user.user.contact.surname }}
            </div>
          </div>
        </div>
      </div>
      <f7-list
        accordion-list
        class="content-list-menu"
      >
        <ul>
          <li
            v-for="(item, index) in elements"
            :key="index"
            class="accordion-item"
          >
            <template
              v-if="
                isGranted(item) &&
                  $t(item.name) !== '' &&
                  $t(item.name) !== 'INFORMATION'
              "
            >
              <a
                :href="item.url"
                class="item-content item-link"
              >
                <div class="item-inner">
                  <div class="item-title">{{ $t(item.name) }}</div>
                </div>
              </a>
              <f7-accordion-content>
                <f7-list media-list>
                  <f7-list-item
                    v-for="(itemMenu, index2) in item.menus"
                    v-if="isGranted(itemMenu)"
                    :key="index2"
                    class="panel-close close"
                    :class="{
                      highlight: getActualMenuSelected == itemMenu.url,
                    }"
                    accordion-list
                    @click="navigateTo(itemMenu.url)"
                  >
                    <f7-icon
                      v-if="(typeof itemMenu.icon !== `undefined`)"
                      slot="media"
                      class="icon-menu"
                      :style="{backgroundImage:`url(../static/icons/${itemMenu.icon})`}"
                      :link="itemMenu.url"
                      width="32"
                      height="32"
                    />
                    <a v-if="(typeof itemMenu.icon !== 'undefined')">{{
                      $t(itemMenu.name)
                    }}</a>
                    <a v-else>{{ $t(itemMenu.name) }}</a>
                    <br>
                  </f7-list-item>
                </f7-list>
              </f7-accordion-content>
            </template>
            <template
              v-else-if="($t(item.name) === '' && getActualMenuSelected === undefined)"
            >
              <f7-list-item
                v-for="(itemMenu, index2) in item.menus"
                v-if="isGranted(itemMenu)"
                :key="index2"
                class="panel-close close highlight"
                :class="{ highlight: getActualMenuSelected == itemMenu.url }"
                accordion-list
                @click="navigateTo(itemMenu.url)"
              >
                <f7-icon
                  v-if="(typeof itemMenu.icon !== 'undefined')"
                  slot="media"
                  class="icon-menu"
                  :style="{backgroundImage:`url(../static/icons/${itemMenu.icon})`}"
                  width="32"
                  height="32"
                />
                <a v-if="(typeof itemMenu.icon !== 'undefined')">{{
                  $t(itemMenu.name)
                }}</a>
                <a v-else>{{ $t(itemMenu.name) }}</a>
                <br>
              </f7-list-item>
            </template>
            <template v-else>
              <f7-list-item
                v-for="(itemMenu, index2) in item.menus"
                v-if="isGranted(itemMenu)"
                :key="index2"
                class="panel-close close padding-left"
                :class="{ highlight: getActualMenuSelected == itemMenu.url }"
                accordion-list
                @click="navigateTo(itemMenu.url)"
              >
                <f7-icon
                  v-if="(typeof itemMenu.icon !== 'undefined')"
                  slot="media"
                  class="icon-menu"
                  :style="{backgroundImage:`url(../static/icons/${itemMenu.icon})`}"
                  :link="itemMenu.url"
                  width="32"
                  height="32"
                />
                <a v-if="(typeof itemMenu.icon !== 'undefined')">{{
                  $t(itemMenu.name)
                }}</a>
                <a v-else>{{ $t(itemMenu.name) }}</a>
                <br>
              </f7-list-item>
            </template>
          </li>
        </ul>
      </f7-list>
    </f7-page>
  </f7-panel>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { APPLICATION_NAME, APP_LOGO } from '../js/constants';
import store from '../store/index';
import optionButton from '../json/menuFloatingButton.json';
import icUser from '../static/icons/ic_user_light.svg';

export default {
  name: 'LeftPanel',
  store,
  props: {
    elements: { type: Array, default: () => [] },
    showToDefault: { type: Boolean, default: false },
    version: { type: String, default: '1.0.0' },
  },
  data() {
    return {
      APPLICATION_NAME,
      APP_LOGO,
      alwaysOpen: 0,
      optionButton,
    };
  },
  computed: {
    appName() {
      return this.APPLICATION_NAME;
    },
    appLogo() {
      return this.getLogo;
    },
    ...mapState('authentication', ['user']),
    ...mapGetters('authentication', [
      'getActualMenuSelected',
      'getPermissions',
      'getLogo',
    ]),
    iconUser() {
      return icUser;
    },
  },
  methods: {
    navigateTo(path) {
      if (path === '/accounts/loginPage/') {
        this.logoutPressed();
      } else {
        this.setActualMenuSelected(path);
        this.$f7.views.main.router.navigate(path, { reloadCurrent: true });
      }
    },
    logoutPressed() {
      const self = this;
      self.$f7.panel.close('left', false);
      self.logout();
    },
    isGranted(menu) {
      if (menu.permission === 'ALL') return true;
      return this.$helpers.havePermission(
        this.getPermissions[`${menu.permission}`],
      );
    },
    ...mapActions('authentication', ['logout', 'setActualMenuSelected']),
  },
};
</script>

<style lang="scss">
.content-left-panel-header{
    background: white;
  .content-left-panel-header-user{
    display: flex;
    align-items: center;
    &__img{
      img{
        width: 48px;
        height: 48px;
      }
    }
    &__title{
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: 600;
      line-height: 1.2;
      margin: 3px 0;
      color: var(--color-text);
    }
    &__subtitle{
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}
.content-menu {
  .content-list-menu {
    --f7-list-media-item-padding-vertical: 10px;
    --f7-list-item-title-font-size: 14px;
    --f7-list-item-min-height: 36px;
    --f7-list-item-padding-vertical: 0;
    --f7-list-margin-vertical: 0;
    ul {
      li {
        &.accordion-item a:hover {
          color: var(--color-primary-shade);
        }
        a {
          font-size: 14px;
          color: var(--his-harddarkgray);
          .item-inner {
            justify-content: flex-start !important;
            align-content: center;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            .item-title {
              color: var(--his-harddarkgray);
              padding-left: 20px;
              line-height: 1.3;
              font-weight: 600;
            }
            &::after {
              height: 0;
            }
            &::before {
              color: var(--f7-theme-color-rgb, 0.15) !important;
              opacity: 0.8;
              zoom: 0.75;
            }
          }
        }

        &.panel-close {
          &:hover {
            background: linear-gradient(
              270deg,
              var(--color-primary-snowlight),
              #ffffff
            );
            a {
              color: var(--f7-theme-color-shade) !important;
            }
          }
          &.highlight {
            background: linear-gradient(
              270deg,
              var(--color-primary-snowlight),
              #ffffff
            ) !important;
            a {
              font-weight: 600;
            }
          }
        }
      }
    }
    ul{
      > .accordion-item{
        .item-link, .highlight{
          padding-left: 16px;
        }
        .accordion-item-content{
          .highlight{
            padding-left: 0;
          }
          .item-inner{
            a{
              padding-left: 10px;
            }
          }
        }
      }
    }
  }

  li.accordion-item.accordion-item-opened {
    background: var(--his-gray);
  }

  .toolbar_panel {
    bottom: 0px !important;
    padding: 5px 10px;
  }
  .chip-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.4;
  }

  .content-menu-header {
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    padding: 2px 5px 2px 5px;
    border-bottom: 1px solid var(--color-primary-light);
  }

  .list-group-title {
    border-bottom: 1px solid var(--color-primary-snow);
  }

  .page-content {
    background: var(--color-primary-snowlight);
  }

  //TODO: Ver porqué se ha alinea a la derecha | Alinear nombre del menú a la izquierda
  .accordion-item{
    border-top: 1px solid var(--his-lightsilver);

    .item-media {
      min-width: auto!important;
    }
  }

  .icon-menu {
    width: 26px;
    height: 26px;
  }

  // .version-app{
  //     color: red;
  //     font-weight: bold;
  // }

}

</style>
