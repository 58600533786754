<template>
  <f7-app
    :style="appTheme"
    :params="f7params"
  >
    <leftPanel
      v-if="canAccessApp"
      :elements="opcionesMenu"
      :version="version"
    />
    <login v-if="loginNeeded && !requestNewPasssword" />
    <f7-view
      v-if="canAccessApp"
      main
      resizable
      :push-state="true"
      class="safe-areas"
    >
      <f7-popup
        class="demo-popup"
        :opened="showMessage"
        @popup:closed="closePopup"
      >
        <f7-page>
          <f7-navbar
            name="msgEmail"
            :title="$t('Login_msgEmail_title')"
          >
            <f7-nav-right>
              <f7-link
                popup-close
              >
                {{ $t("Login_msgEmail_btnClose") }}
              </f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-block>
            <p>{{ $t("Login_msgEmail_content") }}</p>
          </f7-block>
        </f7-page>
      </f7-popup>
    </f7-view>
  </f7-app>
</template>
<script>

import { Device } from 'framework7/framework7-lite.esm.bundle';
import { mapActions, mapState, mapGetters } from 'vuex';
import { loadMessages } from 'devextreme/localization';
import { APP_VERSION } from '../js/constants';
import cordovaApp from '../js/cordova-app';
import store from '../store/index';
import routes from '../js/routes';
import leftPanel from './LeftPanel.vue';
import opcionesMenu from '../json/menuList.json';
import login from '../pages/accounts/loginPage.vue';
import dictionary from '../js/dxDictionary';
import api from '../services/Api';

export default {
  name: 'App',
  components: {
    leftPanel, login,
  },
  store,
  data() {
    return {
      version: APP_VERSION,
      opcionesMenu,
      showToDefault: true,
      // Framework7 Parameters
      f7params: {
        id: 'es.controlTower', // App bundle ID
        name: 'Control Tower', // App name
        theme: 'auto', // Automatic theme detection

        // App routes
        routes,
        // Input settings
        input: {
          scrollIntoViewOnFocus: Device.cordova && !Device.electron,
          scrollIntoViewCentered: Device.cordova && !Device.electron,
        },

        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
          androidTextColor: 'white',
          androidBackgroundColor: '#5243AA',
          iosTextColor: 'white',
          iosBackgroundColor: '#5243AA',
        },
      },
      // Login screen data
      username: '',
      password: '',
    };
  },
  computed: {
    appTheme() {
      if (this.loggedUser !== null) {
        return {
          '--f7-theme-color': this.getThemePrimaryColor(),
          '--f7-navbar-bg-color': this.getThemePrimaryColor(),
          '--f7-theme-color-rgb': this.getThemePrimaryColor(),
          '--color-primary': this.getThemePrimaryColor(),
          '--color-primary-light': this.getThemeTertiaryColor(),
          '--color-primary-shade': this.getThemePrimaryColor(),
          '--color-primary-accent': this.getThemeSecondaryColor(),
          '--f7-theme-color-tint': this.getThemeTertiaryColor(),
          '--f7-theme-color-gray': this.getThemeSecondaryColor(),
          '--f7-theme-color-shade': this.getThemeSecondaryColor(),
          '--f7-button-fill-bg-color': this.getThemePrimaryColor(),
          '--hi-theme-primary': this.getThemePrimaryColor(),
          '--hi-theme-secondary': this.getThemeSecondaryColor(),
          '--hi-theme-tertiary': this.getThemeTertiaryColor(),
          '--hi-theme-tertiary-rgb': this.hexToRgb(this.getThemePrimaryColor()),
        };
      }
      return {
        '--f7-theme-color': 'var(--hi-color-primary)',
        '--color-primary': 'var(--hi-color-primary)',
        '--color-primary-shade': 'var(--hi-color-secondary)',
        '--f7-theme-color-shade': 'var(--hi-color-secondary)',
        '--color-primary-accent': 'var(--hi-color-secondary)',
      };
    },
    loginNeeded() {
      return this.loggedUser == null;
    },
    canAccessApp() {
      return this.loggedUser != null;
    },
    requestNewPasssword() {
      return (this.loggedUser == null && this.WANT_TO_RECOVERY_PASS === true);
    },
    showMessage() {
      return this.messageChangePassword;
    },
    singLoginOKNeeded() {
      return this.singLoginOK;
    },
    ...mapState('authentication', [
      'activationCode',
      'loggedUser',
      'appConfig',
      'wantToRemember',
      'WANT_TO_RECOVERY_PASS',
      'SET_PASSWORD',
      'messageChangePassword',
      'getPrimaryColor',
      'getSecondaryColor',
      'getTertiaryColor',
      'singLoginOK',
    ]),
  },
  created() {
    loadMessages(dictionary);
  },
  mounted() {
    this.$f7ready((f7) => {
      // Init cordova APIs (see cordova-app.js)
      if (Device.cordova) {
        cordovaApp.init(f7);
      }
    });
  },
  async beforeMount(context) {
    this.currentUrl = window.location.href;
    let name = 'phispatec';
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(this.currentUrl);
    results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));

    if (results !== null) {
      window.localStorage.clear();

      const credential = { username: results[1], remember: false };
      await api.loginPHispatec(credential);
    }
  },
  methods: {
    closePopup() {
      this.setMessageChangePassword();
    },
    ...mapActions('authentication', ['activateApp', 'deactivateApp', 'logout', 'setMessageChangePassword']),
    ...mapGetters('authentication', ['getThemePrimaryColor', 'getThemeSecondaryColor', 'getThemeTertiaryColor']),
    /* Función que convierte colores hexadecimales a rgb */
    hexToRgb(color) {
      if (color.length === 7) {
        const r = parseInt(color.substr(1, 2), 16);
        const g = parseInt(color.substr(3, 2), 16);
        const b = parseInt(color.substr(5, 2), 16);
        return `${r},${g},${b}`;
      }
    },
  },
};
</script>
