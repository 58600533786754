<template>
  <f7-row class="content-calendar">
    <f7-col :width="50">
      <div class="label-text-data">
        {{ $t('common.date.since') }}
      </div>
      <DxDateBox
        :value="start"
        :max="end"
        display-format="dd/MM/yyyy"
        picker-type="calendar"
        :date-out-of-range-message="$t('home.filters.datePicker.maxError')"
        :on-value-changed="startChanged"
      />
    </f7-col>
    <f7-col :width="50">
      <div class="label-text-data">
        {{ $t('common.date.until') }}
      </div>
      <DxDateBox
        :value="end"
        :min="start"
        display-format="dd/MM/yyyy"
        :date-out-of-range-message="$t('home.filters.datePicker.minError')"
        picker-type="calendar"
        :on-value-changed="endChanged"
      />
    </f7-col>
  </f7-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import DxDateBox from 'devextreme-vue/date-box';

export default {
  name: 'CalendarInitEndDate',
  components: {
    DxDateBox,
  },
  data() {
    return {
      start: moment().toDate(),
      end: moment().subtract(3, 'months').toDate(),
    };
  },
  computed: {
    ...mapState('Shipments', ['shipmentsFilters']),
  },
  beforeMount() {
    this.start = this.shipmentsFilters.dates.init.toDate();
    this.end = this.shipmentsFilters.dates.end.toDate();
  },
  mounted() {
    this.fetchTrips({
      init: moment(this.start),
      end: moment(this.end),
    });
  },
  methods: {
    startChanged(e) {
      this.start = e.value;
      this.fetchTrips({
        init: moment(this.start),
        end: moment(this.end),
      });
    },
    endChanged(e) {
      this.end = e.value;
      this.fetchTrips({
        init: moment(this.start),
        end: moment(this.end),
      });
    },
    ...mapActions('Trips', ['fetchTrips']),
  },
};
</script>

<style lang="scss">
@import url('CalendarInitEndDate.styles.scss');
</style>
