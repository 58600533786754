import {
  f7,
} from 'framework7-vue';
import Request from './Request';
import EventBus from '../js/event-bus';
import i18n from '../js/i18n.config';
import RequestGeneric from './RequestGeneric';

let email = '';
let token = '';
let headerAuth = {};
let headerAuthCT20 = {};
let culture = '';
let accessKey = '';
// const baseUrl = 'https://dev-traxx.hispatectrack.com/api'; // DESARROLLO
// const baseUrl = 'https://dev.traxx.hispatectrack.com/api'; //DESARROLLO
// const baseUrl = 'https://pre.traxx.hispatectrack.com/api'; // PREPRODUCCION
const baseUrl = 'https://traxx.hispatectrack.com/api'; // PRODUCCION

EventBus.$on('newLoggedUser', (payload) => {
  const data = JSON.parse(payload);
  email = data.user.email;
  token = data.access;
  culture = data.user.theme.culture.split('-')[0];
  accessKey = data.accessKey;
  i18n.i18next.changeLanguage(culture);

  headerAuth = `Bearer ${token}`;
});

EventBus.$on('authorizationCT20', (payload) => {
  const data = JSON.parse(payload);
  token = data.token;

  headerAuthCT20 = `Bearer ${token}`;
});
export default {

  errors: {
    default: {
      name: 'error',
      message: 'error',
      callback() {},
    },

    E002: {
      name: 'invalid_token',
      message: 'Su sesión ha expirado',

      callback() {
        if (!email) return;
        email = null;
        EventBus.$emit('invalidTokenDetected');
        f7.dialog.alert(this.message);
      },
    },
  },

  /**
   *
   * @returns
   */
  getToken() {
    return token;
  },
  /**
   *
   * @returns
   */
  getCulture() {
    return culture;
  },
  getAccessKey() {
    return accessKey;
  },
  /**
   *
   * @param {*} credentials
   * @returns
   */
  login(credentials) {
    const url = `${baseUrl}/v2/login`;
    const { username: user, password: pass, remember: Longtime } = credentials;
    const payload = {
      data: JSON.stringify({
        user,
        pass,
        Longtime,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json')
      .then(this.handleLoginResult);
  },
  loginPHispatec(credential) {
    const url = `${baseUrl}/v2/loginPHispatec`;
    const { username: PHispatec, remember: Longtime } = credential;
    const payload = {
      data: JSON.stringify({
        PHispatec,
        Longtime,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json')
      .then(this.handleLoginResult);
  },
  loginCT20() {
    // https://ct20.development.hispatecanalytics.com/user/authorization
    const url = 'https://back.hispatectrack.com/user/authorization';
    const username = 'ct1@hispatec.com';
    const password = 'Ct1.45648';

    const accessKey = { username: `${username}`, password: `${password}` };
    return RequestGeneric.async(url, { data: JSON.stringify(accessKey) }, 'POST', false, 'application/json')
      .then(this.handleAuthorizationCT20Result);
  },
  /**
   *
   * @param {*} result
   */
  async handleAuthorizationCT20Result(result) {
    if (result.response !== '') {
      EventBus.$emit('authorizationCT20', result.response);
    }
  },
  /**
   *
   * @param {*} result
   */
  async handleLoginResult(result) {
    if (result.response !== '') {
      EventBus.$emit('newLoggedUser', result.response);
    }
  },

  devices: {
    url: `${baseUrl}/v2/devices`,
    urlV3: `${baseUrl}/v3/devices`,
    payload: {
      data: null,
    },
    async getOne(id) {
      return Request.async(`${this.urlV3}/${id}`, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async getAll(params) {
      const { status, dates } = params;
      const dateBefore = dates.end.isValid() ? `&dateBefore=${dates.end.format('YYYY-MM-DDTHH:mm:ss')}` : '';
      const dateAfter = dates.init.isValid() ? `&dateAfter=${dates.init.format('YYYY-MM-DDTHH:mm:ss')}` : '';
      const customUrl = `${this.urlV3}?status=${status}${dateBefore}${dateAfter}`;
      return Request.async(customUrl, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async remove(id) {
      const customUrl = `${baseUrl}/v1/devices`;
      return Request.async(`${customUrl}/${id}`, this.payload, 'DELETE', false, 'application/json', headerAuth, null);
    },
    async getReports(id) {
      return Request.async(`${baseUrl}/v3/devices/${id}/reports`, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async activate(id, item) {
      return Request.async(`${this.urlV3}/${id}`, { data: JSON.stringify(item) }, 'POST', false, 'application/json', headerAuth, null);
    },
    async uploadDocument(id, item) {
      return Request.async(`${this.url}/${id}/files`, { data: JSON.stringify(item) }, 'POST', false, 'application/json', headerAuth, null);
    },
    async getDocuments(id) {
      return Request.async(`${this.url}/${id}/files`, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async downloadDocument(id, guid) {
      return Request.async(`${this.url}/${id}/files/${guid}`, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async removeDocument(id, guid) {
      return Request.async(`${this.url}/${id}/files/${guid}`, this.payload, 'DELETE', false, 'application/json', headerAuth, null);
    },
    async getFinalSummaryPDF(numeroDC) {
      const customUrl = `${baseUrl}/v1/devices`;
      return Request.async(`${customUrl}/${numeroDC}/pdf`, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async sendClaimedByCustomer(id, claimed, claimType) {
      const claim = `&claimed=${claimed}`;
      const type = `&claimType=${claimType}`;
      const customUrl = `${this.url}/${id}/claimed/?${claim}${type}`;
      return Request.async(customUrl, this.payload, 'PUT', false, 'application/json', headerAuth, null);
    },
  },

  notifications: {
    url: `${baseUrl}/v2/notification`,
    payload: {
      data: null,
    },
    async getAll(all) {
      const customUrl = `${this.url}${all ? '?onlyNoRead=false' : ''}`;
      return Request.async(customUrl, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async removeAll() {
      return Request.async(this.url, this.payload, 'DELETE', false, 'application/json', headerAuth, null);
    },
    async remove(id) {
      return Request.async(`${this.url}/${id}`, this.payload, 'DELETE', false, 'application/json', headerAuth, null);
    },
  },

  trips: {
    url: `${baseUrl}/v2/shipments`,
    payload: {
      data: null,
    },
    async post(item) {
      return Request.async(this.url, { data: JSON.stringify(item) }, 'POST', false, 'application/json', headerAuth, null);
    },
    async getAll(dates) {
      const { end, init } = dates;
      const dateBefore = end.isValid() ? `&dateToFilterLoad=${end.format('YYYY-MM-DDTHH:mm:ss')}` : '';
      const dateAfter = init.isValid() ? `&dateSinceFilterLoad=${init.format('YYYY-MM-DDTHH:mm:ss')}` : '';
      const customUrl = `${this.url}?${dateAfter}${dateBefore}`;
      return Request.async(customUrl, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
  },

  locations: {
    url: `${baseUrl}/v2/locations`,
    payload: {
      data: null,
    },
    async post(item) {
      return Request.async(this.url, { data: JSON.stringify(item) }, 'POST', false, 'application/json', headerAuth, null);
    },
    async getOne(id) {
      return Request.async(`${this.url}/${id}`, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async getAll() {
      return Request.async(this.url, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async remove(id) {
      return Request.async(`${this.url}/${id}`, this.payload, 'DELETE', false, 'application/json', headerAuth, null);
    },
  },

  merchandise: {
    url: `${baseUrl}/v2/merchandise`,
    payload: {
      data: null,
    },
    async post(item) {
      return Request.async(this.url, { data: JSON.stringify(item) }, 'POST', false, 'application/json', headerAuth, null);
    },
    async getOne(id) {
      return Request.async(`${this.url}/${id}`, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async getAll() {
      return Request.async(this.url, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async remove(id) {
      return Request.async(`${this.url}/${id}`, this.payload, 'DELETE', false, 'application/json', headerAuth, null);
    },
  },
  cargos: {
    url: `${baseUrl}/v2/cargos`,
    payload: {
      data: null,
    },
    async post(item) {
      return Request.async(this.url, { data: JSON.stringify(item) }, 'POST', false, 'application/json', headerAuth, null);
    },
    async getOne(id) {
      return Request.async(`${this.url}/${id}`, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async getAll() {
      return Request.async(this.url, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async remove(id) {
      return Request.async(`${this.url}/${id}`, this.payload, 'DELETE', false, 'application/json', headerAuth, null);
    },
  },
  complaints: {
    url: `${baseUrl}/v2/complaints`,
    payload: {
      data: null,
    },
    async post(item) {
      return Request.async(this.url, { data: JSON.stringify(item) }, 'POST', false, 'application/json', headerAuth, null);
    },
    async getOne(id) {
      return Request.async(`${this.url}/${id}`, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async getAll() {
      return Request.async(this.url, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async remove(id) {
      return Request.async(`${this.url}/${id}`, this.payload, 'DELETE', false, 'application/json', headerAuth, null);
    },
  },

  carriers: {
    url: `${baseUrl}/v2/carriers`,
    payload: {
      data: null,
    },
    async post(item) {
      return Request.async(this.url, { data: JSON.stringify(item) }, 'POST', false, 'application/json', headerAuth, null);
    },
    async getOne(id) {
      return Request.async(`${this.url}/${id}`, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async getAll() {
      return Request.async(this.url, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async remove(id) {
      return Request.async(`${this.url}/${id}`, this.payload, 'DELETE', false, 'application/json', headerAuth, null);
    },
  },

  protocols: {
    url: `${baseUrl}/v2/protocols`,
    payload: {
      data: null,
    },
    async post(item) {
      return Request.async(this.url, { data: JSON.stringify(item) }, 'POST', false, 'application/json', headerAuth, null);
    },
    async getOne(id) {
      return Request.async(`${this.url}/${id}`, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async getAll() {
      return Request.async(this.url, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async remove(id) {
      return Request.async(`${this.url}/${id}`, this.payload, 'DELETE', false, 'application/json', headerAuth, null);
    },
  },
  origins: {
    url: `${baseUrl}/v2/origins`,
    payload: {
      data: null,
    },
    async post(item) {
      return Request.async(this.url, { data: JSON.stringify(item) }, 'POST', false, 'application/json', headerAuth, null);
    },
    async getOne(id) {
      return Request.async(`${this.url}/${id}`, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async getAll() {
      return Request.async(this.url, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
    async remove(id) {
      return Request.async(`${this.url}/${id}`, this.payload, 'DELETE', false, 'application/json', headerAuth, null);
    },
  },

  async getCompleteList(nameList) {
    const url = `${baseUrl}/v2/${nameList}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, null);
  },

  async getCompleteListSync(nameList) {
    const url = `${baseUrl}/v2/${nameList}`;
    const payload = {
      data: null,
    };
    const xhr = await Request.async(url, payload, 'GET', false, 'application/json', headerAuth, null);
    return JSON.parse(xhr.response);
  },

  recipientInfo: {
    url: `${baseUrl}/v2/recipientinfo`,
    payload: {
      data: null,
    },
    async getAll() {
      return Request.async(this.url, this.payload, 'GET', false, 'application/json', headerAuth, null);
    },
  },
  customerService: {
    url: `${baseUrl}/v3/customerService`,
    payload: {
      data: null,
    },
    async sendConsult(item) {
      return Request.async(this.url, { data: JSON.stringify(item) }, 'POST', false, 'application/json', headerAuth, null);
    },
  },
};
