<template>
  <f7-page
    class="main-general"
  >
    <Navbar
      :elements="elements"
    />
    <f7-block class="no-padding no-margin">
      <div class="container">
        <iframe
          :src="urlIframe+getAccessKey"
          frameborder="0"
          allowfullscreen
          style="min-width: 100vw; border: 1px solid; min-height: calc(100vh - 65px);"
        />
      </div>
    </f7-block>
  </f7-page>
</template>

<script>
import {
  mapGetters,
} from 'vuex';
import Navbar from '../../components/NavBar.vue';
import {
  URL_PANEL_POWER_BI_GENERAL_GENERAL_CTPRO,
} from '../../js/constants';

export default {
  name: 'GeneralCTPROPage',
  components: {
    Navbar,
  },
  props: {
    type: { type: String, default: '' },
    fullName: { type: String, default: '' },
    date: { type: Number, default: Number },
  },
  data() {
    return {
      Navbar,
      elements: [
        {
          iconEnable: false,
          textEnable: false,
          bagdeEnable: false,
          info: null,
        },
      ],
      sheetOpened: false,
      wantsToChangeLocation: false,
      imgError: false,
      urlIframe: `${URL_PANEL_POWER_BI_GENERAL_GENERAL_CTPRO}?AccessKey=`,
    };
  },
  computed: {
    ...mapGetters('authentication', ['getAccessKey']),
  },
  methods: {
  },
};
</script>
  <style scoped>
  .container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  }
  </style>
