<template>
  <f7-page class="main-administrationColumnsDevices">
    <Navbar />
    <div class="wrapper">
      <div class="margin-vertical">
        <AdministrationColumnsDevicesTable />
      </div>
    </div>
  </f7-page>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import Navbar from '../../../components/NavBar.vue';
import eventBus from '../../../js/event-bus';
import AdministrationColumnsDevicesTable from '../../../components/administrationColumnsTable/devices/index.vue';

export default {
  name: 'AdministrationColumnsDevices',
  components: {
    Navbar,
    AdministrationColumnsDevicesTable,
  },
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss">
.main-administrationColumnsDevices{
  .dx-icon{
    display: flex!important;
    align-items: center;
    justify-content: center;
    width: 40px!important;
    height: 40px!important;
  }
}

</style>
