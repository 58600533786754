var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-card',{staticClass:"card-block",attrs:{"title":_vm.$t('common.blocks.carriers')}},[_c('f7-block',[_c('f7-row',[_c('DxDataGrid',_vm._b({staticClass:"margin-bottom",attrs:{"data-source":_vm.carriers,"columns":_vm.config,"on-toolbar-preparing":_vm.onToolbarPreparing,"editing":{
          allowAdding: true,
          allowDeleting: true,
          allowUpdating: true,
          mode: 'cell',
          editColumnName: '',
          useIcons: true,
        }},scopedSlots:_vm._u([{key:"editTooltip",fn:function(){return [_c('p',[_c('i',[_vm._v(_vm._s(_vm.$t('trips.messageEditCelds')))])])]},proxy:true}])},'DxDataGrid',_vm.commonProps.dataGrid,false))],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }