import moment from 'moment';
import $ from '../../../../js/i18n.config';

function formatDate(value) {
  return moment(value).format('DD/MM/YYYY');
}
function formatTime(value) {
  return moment(value).format('HH:mm');
}

export const loadColumns = [{
  dataField: 'name',
  caption: $.t('common.track.name'),
  dataType: 'string',
  lookup: {
    dataSource: [],
    displayExpr: 'name',
    valueExpr: 'id',
  },

}, {
  dataField: 'date',
  caption: $.t('common.time.hourName'),
  displayFormat: 'HH:mm',
  dataType: 'datetime',
  format: {
    formatter: formatTime,
  },
}, {
  dataField: 'date',
  caption: $.t('common.time.dayName'),
  dataType: 'date',
  format: {
    formatter: formatDate,
  },
},
{
  dataField: 'loaded',
  caption: $.t('common.track.goodsCollected'),
  dataType: 'boolean',
  trueText: 'Recogido',
  falseText: 'No recogido',
  value: false,
},
{
  dataField: 'merchandise',
  caption: $.t('common.blocks.cargo'),
  dataType: 'object',
  lookup: {
    dataSource: [],
    displayExpr: 'name',
    valueExpr: 'id',
  },
},
];

export const unloadColumns = [{
  dataField: 'name',
  caption: $.t('common.track.name'),
  dataType: 'string',
  lookup: {
    dataSource: [],
    displayExpr: 'name',
    valueExpr: 'id',
  },
}, {
  dataField: 'date',
  caption: $.t('common.time.hourName'),
  displayFormat: 'HH:mm',
  dataType: 'datetime',
  format: {
    formatter: formatTime,
  },
}, {
  dataField: 'date',
  caption: $.t('common.time.dayName'),
  dataType: 'date',
  format: {
    formatter: formatDate,
  },
}, {
  dataField: 'Delivered',
  caption: $.t('common.track.goodsDelivered'),
  dataType: 'boolean',
  trueText: 'Recogido',
  falseText: 'No recogido',
  value: false,
},
];

export const cargosColumns = [
  {
    dataField: 'product',
    caption: $.t('common.track.product'),
    lookup: {
      dataSource: [],
      displayExpr: 'name',
      valueExpr: 'id',
    },
  },
  {
    dataField: 'volume',
    caption: $.t('common.track.volume'),
    dataType: 'number',
  },
  {
    dataField: 'metric',
    caption: $.t('common.track.unit'),
    dataType: 'string',
  },
];
