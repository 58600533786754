import Api from '../../services/Api';
import Notification from '../../js/models/Notification';

export default {
  namespaced: true,
  state: {
    notifications: [],
    userData: null,
  },
  getters: {
    getNotifications(state) {
      return state?.notifications;
    },
  },
  mutations: {
    UPDATE_NOTIFICATIONS(state, payload) {
      state.notifications = payload;
    },
    UPDATE_USERDATA_LIST(state, payload) {
      state.userData = payload;
    },
  },
  actions: {
    async fetchNotifications({ commit }) {
      //  const data = await Notification.getDefault();
      // commit('UPDATE_NOTIFICATIONS', data);
      const data = await Api.notifications.getAll();
      commit('UPDATE_NOTIFICATIONS', JSON.parse(data.response));
    },
    // MARCA COMO LEÍDAS TODAS LA NOTIFICACIONES.
    async removeNotifications({ dispatch }) {
      await Api.notifications.removeAll();
      await dispatch('fetchNotifications');
    },
    // MARCA COMO LEÍDA UNA SOLA NOTIFICACION.
    async removeNotification({ dispatch }, guid) {
      await Api.notifications.remove(guid);
      await dispatch('fetchNotifications');
    },
  },
};
