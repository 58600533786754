<template>
  <div
    class="main-shipping content-devx"
  >
    <DeactivatePopup />
    <ClaimPopup />
    <TableCount :total-count="totalCount" />
    <DxDataGrid
      id="gridContainer"
      :ref="dataGridRefName"
      v-bind="dataGridProps"
      :data-source="dataSource"
      :columns="allColumns"
      :on-content-ready="handleContentReady"
      :on-editor-preparing="handleEditorPreparing"
      :focused-row-enabled="true"
      key-expr="device"
      :column-auto-width="true"
      :word-wrap-enabled="true"
      @cell-prepared="onCellPrepared"
      @toolbar-preparing="onToolbarPreparing"
      @exporting="onExporting"
    >
      <DxExport
        :enabled="true"
      />
      <template
        v-for="item in templates"
        #[item.name]="{data}"
      >
        <component
          :is="item.component"
          :key="item.name"
          :data="data"
        />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { DxDataGrid, DxExport } from 'devextreme-vue/data-grid';
import moment from 'moment';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { MILISECONDS_TO_REFRESH_AUTOMATICALLY } from '../../js/constants';

import TableCount from './tableCount/index.vue';
import DeactivatePopup from './deactivatePopup/index.vue';
import ClaimPopup from './claimPopup/index.vue';
import EventBus from '../../js/event-bus';

import columns, { dataGridProps, colMethods } from './shippmentColumnData';
import templates from './templates/templates';

export default {
  name: 'Shipping',
  components: {
    DeactivatePopup,
    ClaimPopup,
    TableCount,
    DxDataGrid,
    DxExport,
  },

  data() {
    return {
      templates,
      dataGridProps,
      columns,
      totalCount: 0,
      dataGridRefName: 'dataGrid',
    };
  },
  computed: {
    allColumns() {
      const genericColumns = this.getGenericAttributesNames.filter((generic) => generic !== '-').map((name) => ({
        dataField: name,
        caption: this.translate(name),
        allowHeaderFiltering: false,
      }));

      const preferedColumns = [];
      for (let i = 0; i < this.getColumnPreferences.length; i += 1) {
        const column = columns.find((element) => element.name === this.getColumnPreferences[i]);
        if (column !== null) {
          preferedColumns.push(column);
        }
      }

      this.columns[this.columns.length - 1].buttons.filter((colum) => colum.name !== 'claimedByCustomer');

      return [
        this.columns[0],
        this.columns[1],
        ...preferedColumns,
        ...genericColumns,
        // this.columns[this.columns.length - 4], // rango
        this.columns[this.columns.length - 1]];
    },
    dataSource() {
      const localData = [];
      const data = this.shipmentsList;
      if (data !== undefined) {
        data.forEach((device) => {
          let typeTransport = '';
          if (device.typeTransport === 134) typeTransport = `${this.$t('launchTrip.typeTransport.land')}`;
          else if (device.typeTransport === 135) typeTransport = `${this.$t('launchTrip.typeTransport.maritime')}`;
          else if (device.typeTransport === 136) typeTransport = `${this.$t('launchTrip.typeTransport.aerial')}`;

          const rangeTemperature = (device.lowerTemperature === -40 && device.higherTemperature === 40) ? ''
            : `${device.lowerTemperature} ${this.$t('shipping.to')} ${device.higherTemperature}ºC`;
          const genericAttributes = {};

          this.getGenericAttributesNames.forEach((attributeName, localIndex) => {
            if (attributeName !== '-') {
              genericAttributes[attributeName] = device[`genericAttribute${this.getNumber(localIndex)}`];
            }
          });
          localData.push({
            ...device,
            week: this.updateWeek(device.dateActivation),
            status: device.state,
            editPermissions: device.modificationPermissions,
            idDeviceReport: device.idDeviceReport,
            temperature: device.deviceTemp?.toFixed(2),
            lowerTemperature: device.lowerTemperature,
            higherTemperature: device.higherTemperature,
            range: rangeTemperature,
            location: device.lastLocation,
            origin: device.activationPlace,
            reference: device.customerRef,
            lastReport: this.diffDate(device.dateTimeAcquiredUtc),
            dateStart: this.updateDate(device.dateActivation),
            dateArrived: this.updateDate(device.dateDeliver),
            numberTransport: device.transportNum,
            carrier: device.carrier,
            destination: device.deliveryPlace,
            device: device.deviceId,
            cargo: device.cargo,
            alert: `${device.alertTemperature} ${device.alertIntrusion}`,
            dateTimeAcquiredUtc: device.dateTimeAcquiredUtc,
            typeTransport,
            humidity: device.humidity,
            etd: this.updateETDETA(device.etd),
            eta: this.updateETDETA(device.eta),
            ...genericAttributes,
          });
        });
      }

      return localData;
    },
    dataGrid() {
      return this.$refs.dataGrid.instance;
    },
    ...mapGetters('authentication', ['getGenericAttributesNames', 'getColumnPreferences', 'getPermissions']),
    ...mapState('Shipments', ['shipmentsList', 'shipmentsFilters', 'finalSummaryPDF', 'attachment']),
    ...mapState('authentication', ['user']),
  },
  async created() {
    this.refreshInterval = setInterval(this.refreshNow, MILISECONDS_TO_REFRESH_AUTOMATICALLY);
    setInterval(this.update, MILISECONDS_TO_REFRESH_AUTOMATICALLY);

    await this.fetchShipmentList();
  },
  async beforeMount() {
    try {
      await this.fetchShipmentListOff();
      await this.fetchCargosList();
      await this.fetchOriginsList();
      await this.fetchDestinationsList();
      await this.fetchRecipientInfo();
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
    }
  },
  mounted() {
    EventBus.$on('markerClick', (deviceId) => {
      const currentShip = this.dataSource.find((item) => item.device === deviceId);
      this.updateCurrentShip(currentShip);
    });
  },
  methods: {
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');

      exportDataGrid({
        component: e.component,
        worksheet,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${this.$t('common.track.device')}s.xlsx`);
          });
      });
      e.cancel = true;
    },
    handleContentReady() {
      const count = this.dataGrid.totalCount() < 0 ? 0 : this.dataGrid.totalCount();
      this.totalCount = count;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'undo',
          hint: this.$t('common.toolTips.clearAllFiltersHint'),
          onClick: this.limpiarFiltros.bind(this),
        },
      });
    },
    handleEditorPreparing(data) {
      if (data.parentType === 'filterRow') {
        data.editorOptions.showClearButton = true;
      }
    },
    translate(name) {
      return this.$t([`common.track.${name}`, name]);
    },
    getNumber(num) {
      if (num === 9) return 10;
      return `0${num + 1}`;
    },
    updateWeek(e) {
      if (moment(e, 'DD-MM-YYYY', true).isValid()) return moment(e, 'DD-MM-YYYY').week();
      return null;
    },
    updateDate(e) {
      if (moment(e, 'DD/MM/YYYY', true).isValid()) return moment(e, 'DD/MM/YYYY').toDate();
      if (moment(e, 'DD-MM-YYYY', true).isValid()) return moment(e, 'DD-MM-YYYY').toDate();
      return null;
    },
    updateETDETA(e) {
      if (moment(e, 'YYYY-MM-DD[T]HH:mm:ss', true).isValid()) {
        return moment(e, 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY');
      }
      return null;
    },
    diffDate(date) {
      const dateGived = new Date(date).getTime();

      let now = new Date().getTime();
      now += new Date().getTimezoneOffset() * 60000;
      let diff = now - dateGived;
      const days = Math.floor(diff / 86400000);
      diff -= days * 86400000;
      const hour = Math.floor(diff / 3600000);
      diff -= hour * 3600000;
      const min = Math.floor(diff / 60000);

      let result = '';

      if (days > 0) {
        if (days === 1) {
          result += `${days}`;
          result += this.$t('common.time.day');
        } else {
          result += `${days}`;
          result += this.$t('common.time.days');
        }
      }

      if (hour > 0) {
        if (hour === 1) {
          result += ` ${hour}`;
          result += this.$t('common.time.hour');
        } else {
          result += ` ${hour}`;
          result += this.$t('common.time.hours');
        }
      }

      if (min > 0 && days === 0) {
        result += ` ${min}`;
        result += this.$t('common.time.min');
      }

      return result;
    },

    onCellPrepared(row) {
      if (row.column.type === 'buttons') this.addButtonFuncs(row.column.buttons, this.$helpers.havePermission(this.getPermissions.visibility_claims_button));
      if (row.column.dataField === 'dateArrived' && row.rowType === 'data') EventBus.$emit(`dateArrived-${row.rowIndex}`, row);
    },
    limpiarFiltros() {
      this.dataGrid.clearFilter();
    },
    refreshNow() {
      if (this.loggedUser) {
        this.partialSyncDexie().catch((error) => { this.$f7.dialog.alert(error); });
      }
    },
    async update() {
      try {
        await this.fetchShipmentListOff();
      } catch (error) {
        this.$f7.dialog.alert(error.message);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    ...colMethods,
    ...mapActions('Shipments', ['updateCurrentShip', 'fetchShipmentList', 'fetchShipmentListOff', 'downloadFinalSummaryPDF', 'deleteDeactivateDevice', 'setDeviceId']),
    ...mapActions('Template', ['fetchCargosList', 'fetchOriginsList', 'fetchDestinationsList', 'fetchRecipientInfo', 'getDestinations']),
    ...mapActions('Notifications', ['fetchNotifications']),
    ...mapActions('DatabaseSync', ['partialSyncDexie']),
  },

};
</script>
<style lang="scss" scoped >
@import url('Shippment.styles.scss');
</style>
