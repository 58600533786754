<template>
  <div class="main-table">
    <f7-card class="card-block margin-bottom">
      <div class="card-header no-padding-vertical">
        {{ title }}
      </div>
      <div class="card-content padding-horizontal-half">
        <f7-row
          v-for="row in data"
          :key="row.name"
        >
          <f7-col class="label-text-data">
            {{ row.name }}
          </f7-col>
          <!--TODO Mejorar -->
          <f7-col v-if="row.value[0] === 'tempHighIconDefault' && row.value[1] === 'lightIconDefault' ">
            <img
              width="20"
              height="20"
              :class="`${'tempHighIconDefault'}`"
            >
            <img
              width="20"
              height="20"
              :class="`${'lightIconDefault'}`"
            >
          </f7-col>
          <f7-col v-if="row.value[0] === 'tempHighIconDefault' && row.value[1] === 'lightIcon' ">
            <img
              width="20"
              height="20"
              :class="`${'tempHighIconDefault'}`"
            >
            <img
              width="20"
              height="20"
              :class="`${'lightIcon'}`"
            >
          </f7-col>
          <f7-col v-if="row.value[0] === 'tempHighIcon' && row.value[1] === 'lightIcon' ">
            <img
              width="20"
              height="20"
              :class="`${'tempHighIcon'}`"
            >
            <img
              width="20"
              height="20"
              :class="`${'lightIcon'}`"
            >
          </f7-col>
          <f7-col v-if="row.value[0] === 'tempHighIcon' && row.value[1] === 'lightIconDefault' ">
            <img
              width="20"
              height="20"
              :class="`${'tempHighIcon'}`"
            >
            <img
              width="20"
              height="20"
              :class="`${'lightIconDefault'}`"
            >
          </f7-col>
          <f7-col
            v-if="row.value[0] !== 'tempHighIconDefault' && row.value[0] !== 'tempHighIcon'"
            class="content-info-data"
          >
            <b>{{ isEmpty(row.value) ? " " : row.value }}</b>
          </f7-col>
          <!--
            <b class="color-his-primary-shade">{{ isEmpty(row.value) ? " " : row.value }}</b>
          -->
        </f7-row>
      </div>
    </f7-card>
  </div>
</template>

<script>
export default {
  name: 'Table',
  props: {
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isEmpty(value) {
      if (value === '' || value === 0 || value === '0' || value === 'EMPTY' || value === 'empty' || value === undefined || value === null) return true;
      return false;
    },
    isNumeric(value) {
      return !Number.isNaN(parseFloat(value)) && Number.isFinite(value);
    },
  },
};
</script>

<style lang="scss">
  .tempHighIcon{
    content: url("../../static/icons/ic_high_level.svg");
  }
  .tempHighIconDefault{
    content: url("../../static/icons/ic_level.svg");
  }
  .lightIcon{
    content: url("../../static/icons/ic_high_rute.svg");
  }
  .lightIconDefault{
    content: url("../../static/icons/ic_light_off.svg");
  }
  .content-info-data{
    b{
      overflow-wrap: break-word;
    }
  }
</style>
