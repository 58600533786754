import $ from '../../../../js/i18n.config';

export default [
  {
    dataField: 'Extension',
    caption: $.t('deviceInfo.documents.fileTable.extension.title'),
    dataType: 'string',
    alignment: 'center',
    cellTemplate: 'fileType',
  },
  {
    dataField: 'DisplayName',
    caption: $.t('deviceInfo.documents.fileTable.name'),
    dataType: 'string',
    alignment: 'center',
  },
  {
    dataField: 'DateModified',
    caption: $.t('deviceInfo.documents.fileTable.date'),
    dataType: 'datetime',
    alignment: 'center',
  },
  {
    dataField: 'Description',
    caption: $.t('deviceInfo.documents.fileTable.description'),
    dataType: 'boolean',
    alignment: 'center',
  },
  {
    dataField: 'Owner',
    caption: $.t('deviceInfo.documents.fileTable.owner'),
    dataType: 'string',
    alignment: 'center',
  },
  // {
  //   dataField: 'Extension',
  //   caption: $.t('deviceInfo.documents.fileTable.extension.title'),
  //   dataType: 'string',
  //   alignment: 'center',
  // },
  {
    dataField: '',
    caption: $.t('*Descarga'),
    dataType: 'string',
    alignment: 'center',
    cellTemplate: 'download',
  },
];
