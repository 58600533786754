<template>
  <DxDataGrid
    id="documents-data-grid"
    v-bind="commonProps.dataGrid"
    :data-source="complatins"
    :focused-row-enabled="true"
    key-expr="id"
    :columns="documentsColumns"
  >
    <template #fileType="{data}">
      <f7-icon
        :md="getFileTypeIcon(data)"
      />
    </template>
    <template #download="{ data }">
      <DxButton
        class="dx-btn-pdf"
        size-mode="SizeMode.Small"
        raised
        type="default"
        styling-mode="outlined"
        @click="handleDownloadFile(data.data.Id)"
      >
        <f7-icon
          md="material:file_download"
        />
      </DxButton>
    </template>
  </dxdatagrid>
</template>

<script>
import { DxDataGrid } from 'devextreme-vue/data-grid';
import documentsColumns from './documentsTabColumnsData';
import { commonProps } from '../../tripsColumnData';

export default {
  name: 'DocumentsTab',
  components: {
    DxDataGrid,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      commonProps,
      documentsColumns,
      complatins: this.data.data.files,
    };
  },
};
</script>

<style>

</style>
