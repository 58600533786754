<template>
  <f7-block class="main-trip">
    <f7-row>
      <TableCount :total-count="totalCount" />
      <DxDataGrid
        id="gridContainer"
        :ref="dataGridRefName"
        v-bind="commonProps"
        :data-source="filteredTrips"
        :columns="columns"

        :on-content-ready="handleContentReady"
        :on-cell-prepared="onCellPrepared"
        :on-row-expanding="onRowExpanding"
        @toolbar-preparing="onToolbarPreparing"
      >
        <DxMasterDetail
          :enabled="true"
          template="master-template"
        />
        <template #statusCell="{ data }">
          <div>
            <i :class="setClassStatus(data)" />
          </div>
        </template>

        <template #master-template="{data}">
          <DetailTemplate :template-data="data" />
        </template>
      </DxDataGrid>
    </f7-row>
  </f7-block>
</template>
<script>
import {
  DxDataGrid,
  DxMasterDetail,
} from 'devextreme-vue/data-grid';
import { mapActions, mapGetters } from 'vuex';
import DetailTemplate from './detailTemplate/index.vue';
import columns, { colMethods, commonProps } from './tripsColumnData';
import TableCount from './tableCount/index.vue';

export default {
  name: 'Trips',
  components: {
    DetailTemplate,
    DxDataGrid,
    DxMasterDetail,
    TableCount,
  },
  props: {
  },
  data() {
    return {
      tripsData: [],
      commonProps,
      columns,
      totalCount: 0,
      dataGridRefName: 'dataGrid',
    };
  },
  computed: {
    dataGrid() {
      return this.$refs.dataGrid.instance;
    },
    ...mapGetters('Trips', ['filteredTrips']),
  },
  methods: {
    handleContentReady() {
      const count = this.dataGrid?.totalCount() < 0 ? 0 : this.dataGrid?.totalCount();
      this.totalCount = count;
    },
    onRowExpanding(e) {
      e.component.collapseAll(-1);
    },
    onCellPrepared(row) {
      if (row.column.dataField === 'dateDelivery' && row.data) this.handleDeliveryDate(row);
      if (row.column.type === 'buttons') this.addButtonFuncs(row.column.buttons);
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'plus',
          text: this.$t('shipping.launchTrip'),
          hint: this.$t('common.toolTips.launchTrip'),
          onClick: this.goToRegisterTrip,
        },
      });
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'undo',
          hint: this.$t('common.toolTips.clearAllFiltersHint'),
          onClick: this.limpiarFiltros.bind(this),
        },
      });
    },
    goToEditTrip(e) {
      this.$f7.views.main.router.navigate('/trips/register', {
        props: {
          isEditing: true,
          data: e.row.data,
        },
      });
    },
    goToRegisterTrip() {
      this.$f7.views.main.router.navigate('/trips/register');
    },
    limpiarFiltros() {
      this.updateTripsFilters({ type: 'remove', filter: null });    
      this.dataGrid.clearFilter();
    },
    
    ...mapActions('Trips', ['updateTripsFilters', 'setAllFilter']),
    ...colMethods,
    ...mapActions('Trips', ['fetchTrips']),
  },

};
</script>
<style scoped lang="scss">
</style>
