<template>
  <f7-page
    class="main-loginPage"
    login-screen
    style="background: url('../../static/img/login.jpg') no-repeat center center; background-size: cover;"
  >
    <Login
      :has-restore-pass="true"
      :has-remember-me="true"
      :has-need-help="true"
    />
  </f7-page>
</template>

<script>
import Login from '../../components/login/index.vue';
import slider from '../../static/img/login.jpg';

export default {
  name: 'LoginPage',
  components: {
    Login,
  },
  data() {
    return {
      Login,
      slider,
    };
  },
};
</script>
