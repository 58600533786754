<script>
import {
  POINT_MARKER_ICON_CONFIG_GREEN,
  POINT_MARKER_ICON_CONFIG_RED,
  POINT_MARKER_ICON_CONFIG_BLUE,
  POINT_MARKER_CIRCLE_RED,
  POINT_MARKER_CIRCLE_GREEN,
  POINT_MARKER_CIRCLE_BLUE,
  POINT_MARKER_CIRCLE_YELLOW,
  ICON_ALERT_LIGHT,
  POINT_MARKER_BLACK_FLAG,
} from '../../../js/constants';
import EventBus from '../../../js/event-bus';

export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    marker: {
      type: Object,
      required: true,
    },
    infoWindow: {
      type: Object,
      required: true,
    },
    infoWindowInstance: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newMarker: {},
    };
  },
  mounted() {
    const { Marker } = this.google.maps;
    this.newMarker = new Marker({
      id: this.marker.id,
      position: this.marker.position,
      marker: this.marker,
      map: this.map,
      icon: {
        url: this.getIcon(this.marker.color),
        scaledSize: this.MarkerSize(this.marker.color),
        labelOrigin: { x: 16, y: -10 },
      },
      zIndex: this.getIcon(this.marker.color) === 'markerBlackFlag' ? 99999999 : 99999998 - this.marker.id,
    });

    this.newMarker.addListener('click', () => {
      EventBus.$emit('markerClick', this.marker.deviceId);
      EventBus.$emit('markerClickReport', this.marker.id);
      this.infoWindow.setContent(this.infoWindowInstance.$el);
      this.infoWindow.open({
        anchor: this.newMarker,
        map: this.map,
        shouldFocus: false,
        id: this.marker.id,
      });
    });
  },
  methods: {
    MarkerSize(color) {
      const scaledSize = { width: 20, height: 20 };

      switch (color) {
        case '906':
          return { width: 28, height: 28 };
        default:
          break;
      }
      return scaledSize;
    },
    getIcon(color) {
      switch (color) {
        case 'red':
          return POINT_MARKER_ICON_CONFIG_RED;
        case 'green':
          return POINT_MARKER_ICON_CONFIG_GREEN;
        case 'blue':
          return POINT_MARKER_ICON_CONFIG_BLUE;
        case 'markerRed':
          return POINT_MARKER_ICON_CONFIG_RED;
        case 'markerGreen':
          return POINT_MARKER_ICON_CONFIG_GREEN;
        case 'markerBlue':
          return POINT_MARKER_ICON_CONFIG_BLUE;
        case 'circleRed':
          return POINT_MARKER_CIRCLE_RED;
        case 'circleGreen':
          return POINT_MARKER_CIRCLE_GREEN;
        case 'circleBlue':
          return POINT_MARKER_CIRCLE_BLUE;
        case 'circleYellow':
          return POINT_MARKER_CIRCLE_YELLOW;
        case '906':
          return ICON_ALERT_LIGHT;
        case 'markerBlackFlag':
          return POINT_MARKER_BLACK_FLAG;
        default:
          break;
      }
      return POINT_MARKER_ICON_CONFIG_RED;
    },
  },
  render() {
    return this.newMarker;
  },
};
</script>
