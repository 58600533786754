export default {
  name: 'Puntos de Carga',
  formData: {},
  fields: [{
    dataField: 'name',
    editorType: 'dxSelectBox',
    editorOptions: { disabled: false, displayExpr: 'name' },
    label: {
      text: 'name',
    },
  },
  {
    dataField: 'date',
    editorType: 'dxDateBox',
    editorOptions: { disabled: true, displayFormat: 'dd/MM/yyyy' },
    label: {
      text: 'date',
    },
  },
  {
    dataField: 'time',
    editorType: 'dxDateBox',
    editorOptions: {
      type: 'time',
      pickerType: 'list',
      interval: 20,
      disabled: false,
      displayFormat: 'HH:mm',
    },
    label: {
      text: 'time',
    },
  },
  {
    dataField: 'goodsCollected',
    editorOptions: { disabled: true },
    editorType: 'dxCheckBox',
    label: {
      text: 'goodsCollected',
    },
  },
  ],
};
