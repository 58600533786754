<template>
  <DxPopup
    :visible.sync="show"
    :on-hidden="onHidden"
    :title="$t('protocols.popup.titlePopup')"
    :close-on-outside-click="false"
    :drag-enabled="true"
    position="center"
    height="auto"
    :width="620"
  >
    <DxScrollView>
      <DxForm ref="form">
        <DxGroupItem :col-count="1">
          <DxSimpleItem
            :data-field="$t('protocols.popup.title')"
            :caption="$t('protocols.popup.title')"
            editor-type="dxTextBox"
            :editor-options="{
              onValueChanged: onChangedTitle,
              value: title,
              placeholder: $t('protocols.popup.title'),
            }"
          >
            <DxRequiredRule :message="$t('protocols.popup.titleRequired')" />
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="$t('protocols.popup.description')"
            editor-type="dxTextBox"
            :editor-options="{
              value: description,
              onValueChanged: onChangedDescription,
              placeholder: $t('protocols.popup.description'),
            }"
          >
            <DxRequiredRule :message="$t('protocols.popup.descriptionRequired')" />
          </DxSimpleItem>
        </DxGroupItem>
        <DxGroupItem :col-count="3" />
        <DxItem title="TitleDocs">
          <template #default>
            <p> {{ $t('protocols.popup.documents') }}</p>
          </template>
        </DxItem>
        <DxItem title="Documents">
          <template #default>
            <DxDataGrid
              :v-bind="dataGrid"
              :data-source="protocolDocs"
              :focused-row-enabled="true"
              key-expr="Id"
              :columns="docsColumns"
              :on-toolbar-preparing="onToolbarPreparing"
              :show-borders="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :editing="{
                allowAdding: true,
                allowDeleting: true,
                allowUpdating: true,
                mode: 'cell',
                editColumnName: '',
                useIcons: true,
              }"
            >
              <template #editTooltip>
                <p><i>{{ $t('protocols.popup.editTooltip') }}</i></p>
              </template>
            </DxDataGrid>
          </template>
        </DxItem>
      </DxForm>
      <div class="content-buttons margin-top">
        <DxButton
          class="dx-btn-cancel margin-right padding-half"
          type="normal"
          styling-mode="contained"
          @click="cancelForm"
        >
          {{ $t("common.options.cancel") }}
        </DxButton>
        <DxButton
          class="dx-btn-success padding-half"
          type="success"
          styling-mode="contained"
          @click="validateForm"
        >
          {{ $t("common.options.save") }}
        </DxButton>
      </div>
    </DxScrollView>
  </DxPopup>
</template>

<style lang="scss">
::v-deep .dx-datagrid-revert-tooltip {
  display: none;
}
.content-protocols-popup{
  .content{
    width: 420px;
    margin: 15px auto;
  }
}

</style>

<script>
import {
  DxDataGrid,
} from 'devextreme-vue/data-grid';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
  DxItem,
} from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import DxButton from 'devextreme-vue/button';
import 'devextreme-vue/radio-group';
import 'devextreme/ui/date_box';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { mapActions, mapGetters, mapState } from 'vuex';
import eventBus from '../../../js/event-bus';
import { commonProps } from '../../trips/tripsColumnData';
import helpers from '../../../js/helpers';
import docsColumns from './protocolDocsTableColumnData';

export default {
  name: 'ProtocolsPopup',
  components: {
    DxScrollView,
    DxPopup,
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxRequiredRule,
    DxButton,
    DxDataGrid,
    DxItem,
  },
  props: {
  },
  data() {
    return {
      commonProps,
      show: false,
      loaded: false,
      docsColumns,
      dataGrid: {
        columnHidingEnabled: true,
        columnMinWidth: 70,
        columnAutoWidth: true,
        allowColumnReordering: true,
        allowColumnResizing: true,
        showBorders: true,
        showColumnLines: true,
        showRowLines: true,
        rowAlternationEnabled: true,
      },
    };
  },

  computed: {
    form() {
      return this.$refs.form.instance;
    },
    ...mapState('Protocols', ['protocols', 'title', 'id', 'description', 'protocolDocs', 'creatorUser']),
    ...mapGetters('authentication', ['getThemeData']),

  },
  mounted() {
    eventBus.$on('openProtocolsPopup', (protocols) => {
      if (protocols) {
        this.setId(protocols.Id);
        this.setTitle(protocols.Title);
        this.setDescription(protocols.Description);
        this.setProtocolDocs(protocols.ProtocolDocs);
        this.setCreatorUser(protocols.CreatorUser);
      } else {
        this.initStore();
      }
      this.loaded = true;
      this.show = true;
    });
  },
  methods: {
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        template: 'editTooltip',
      });
    },
    onHidden() {
      this.show = false;
      this.form.resetValues();
      this.initStore();
    },
    validateForm() {
      if (this.title === '' || typeof this.title === 'undefined') {
        this.$f7.dialog.alert(this.$t('protocols.popup.titleRequired'));
      } else if (this.description === '' || typeof this.description === 'undefined') {
        this.$f7.dialog.alert(this.$t('protocols.popup.descriptionDCRequired'));
      } else {
        this.setProtocolDocs(this.protocolDocs);
        this.saveProtocols();
      }
    },
    async saveProtocols() {
      try {
        this.$f7.preloader.show();
        await this.postProtocols();
      } catch (error) {
        const msg = helpers.getFilteredErrorMessage(error);
        this.$f7.dialog.alert(msg);
      } finally {
        this.$f7.preloader.hide();
        this.show = false;
      }
    },
    async cancelForm() {
      this.initStore();
      this.show = false;
      this.form.resetValues();
    },
    onChangedTitle(e) {
      this.setTitle(e.value);
    },
    onChangedDescription(e) {
      this.setDescription(e.value);
    },
    ...mapActions('Protocols', ['postProtocols', 'initStore',
      'setId', 'setTitle', 'setDescription', 'setProtocolDocs', 'setCreatorUser']),
  },
};
</script>
