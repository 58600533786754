<template>
  <f7-page class="main-carriers">
    <ConfigPopup />
    <CarriersPopup />
    <Navbar />
    <div class="wrapper">
      <div class="margin-vertical">
        <f7-row class="justify-content-flex-end">
          <DxButton
            :disabled="false"
            icon="preferences"
            @click="showConfigPopup"
          />
          <DxButton
            class="margin-left-half"
            :disabled="false"
            icon="plus"
            @click="showPopup"
          />
        </f7-row>
      </div>
      <div>
        <CarriersTable />
      </div>
    </div>
  </f7-page>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import Navbar from '../../components/NavBar.vue';
import CarriersTable from '../../components/carriersTable/index.vue';
import CarriersPopup from '../../components/carriersTable/carriersPopup/index.vue';
import ConfigPopup from '../../components/carriersTable/configPopup/index.vue';
import eventBus from '../../js/event-bus';

export default {
  name: 'CarriersPage',
  components: {
    Navbar,
    CarriersTable,
    CarriersPopup,
    ConfigPopup,
    DxButton,
  },
  methods: {
    showPopup() {
      eventBus.$emit('showCarriersPopup');
    },
    showConfigPopup() {
      eventBus.$emit('showCarriersConfigPopup');
    },
  },
};
</script>

<style lang="scss">
.main-carriers{
  .dx-icon{
    display: flex!important;
    align-items: center;
    justify-content: center;
    width: 40px!important;
    height: 40px!important;
  }
}

</style>
